import { DropTargetHookSpec, useDrop } from 'react-dnd'

interface IOptions extends DropTargetHookSpec<any, any, any> {
  enabled?: boolean
}

const useTableRowDrop = (options: IOptions) => {
  if (options.enabled === false) {
    return [
      {
        canDrop: false,
        isOver: false,
      },
      () => {
        // console.log('node', node)
      },
    ] as any
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const instance = useDrop(options)

  return instance
}

export default useTableRowDrop
