import { useState } from 'react'

import type { IMapDirectionProps } from './type'
import type { IGetDirectionOnMapResponse } from '~/utils/getDirectionOnMap'
import getDirectionOnMap from '~/utils/getDirectionOnMap'
import { useDeepCompareEffect } from 'react-use'

const useMapDirection = (props: IMapDirectionProps) => {
  const { from, to, isHidden, color, waypoints, onChange, setLoading } = props

  const [directions, setDirections] =
    useState<null | IGetDirectionOnMapResponse>()

  useDeepCompareEffect(() => {
    setLoading && setLoading(true)
    getDirectionOnMap(
      {
        startingPoint: from,
        endingPoint: to,
        waypoints,
      },
      { polylineOptions: { strokeColor: color } },
    )
      .then(result => {
        setDirections(result)
        onChange && onChange(result)
        setLoading && setLoading(false)
      })
      .catch(error => {
        console.log('error', error)
        setLoading && setLoading(false)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [color, from, to, waypoints])

  return { directions, isHidden }
}

export default useMapDirection
