import { useCallback, useMemo, useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import {
  useQueryCheckAccesses,
  useQueryCheckAppliedForIds,
  useQueryCheckUsageOptions,
  useQueryCheckUsageRecords,
  useQueryCheckUsageSummaries,
  useQueryTerminals,
  useQueryUsers,
} from '~/hooks/useQueryData'

import {
  CheckSignatoryForm,
  CommonTab,
  ICheckSignatoryFormValues,
  ToolTipOverlay,
  EnrollmentProfileForm,
  RTRow,
  ReusableTable,
} from '~/components/shared'
import {
  ToggleSection,
  ICheckComponentProps,
  ICheckTaxFormValues,
  CheckCompanyInfoSection,
  useCheckCompanyInfoSection,
  CheckComponent,
  IToggleSectionIconProps,
} from '~/containers/PayrollsContainer/components'
import WorkersContainerTable from '~/containers/WorkersContainer/WorkersContainerTable'
import { Unless } from 'react-if'
import { Alert, Badge } from 'react-bootstrap'
import { DialogCreateCheckCompany } from '../DialogCreateCheckCompany'
import PaySchedules from '~/containers/CompanySubTabs/PaySchedules'

import { apiClient } from '~/api/ApiClient'
import { selectMyCurrentCompany } from '~/redux/selectors'
import { sessionService } from 'redux-react-session'
import {
  brush,
  brushOutline,
  checkmarkCircle,
  warningOutline,
} from 'ionicons/icons'

import type { IWorker } from '~/types/models/IWorker'
import type { ICompany } from '~/types/models/ICompany'
import type { IUser } from '~/types/models/IUser'

import renderCheckSections from '~/containers/PayrollsContainer/utils/renderCheckSections'
import { CheckComponentsForWorker } from '../CheckComponentsForWorker'
import getCheckSectionIconProps from '~/containers/PayrollsContainer/utils/getCheckSectionIconProps'
import { produce } from 'immer'
import { CompanyTerminalSubTab } from '~/containers/CompanySubTabs'
import Slider from 'rc-slider'

import { IonCol, IonGrid, IonRow } from '@ionic/react'
import NotFoundPage from '~/containers/NotFoundPage'
import { useConfirmationProvider } from '~/contexts'
import { EWorkerTaxType } from '~/types/enums/EWorker'
import { ECheckStatus } from '~/types/enums/ECheck'
import { toast } from 'react-toastify'
import { ReportsSection } from '../Reports'

import './styles.scss'
import 'rc-tooltip/assets/bootstrap.css'
import 'rc-slider/assets/index.css'
import { ColumnFilter, RowSelectionState } from '@tanstack/react-table'
import { IUserData } from '~/hooks/useQueryData/useQueryUsers/useQueryUsers'
import _ from 'lodash'
import { EFieldType } from '~/types/enums/ECommonEnum'
import moment from 'moment'

function CompanyPayrolls() {
  const [signatoryUid, setSignatoryUid] = useState('')
  const [taxValues, setTaxValues] = useState<ICheckTaxFormValues>({
    jurisdiction: '',
    federalOnly: false,
  })
  const [sectionOpenValues, setSectionOpenValues] = useState<string[]>([])
  const [currentTab, setCurrentTab] = useState('onboarding')
  const [isFetchingCompany, setIsFetchingCompany] = useState(false)
  const [isOpenCheckCompany, setIsOpenCheckCompany] = useState(false)
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({})
  const [isOpenPartnerSection, setIsOpenPartnerSection] = useState(false)
  const [isOpenUsageSummaries, setIsOpenUsageSummaries] = useState(false)
  const [isOpenUsageRecords, setIsOpenUsageRecords] = useState(false)
  const [usageSummariesColumnFilters, setUsageSummariesColumnFilters] =
    useState<ColumnFilter[]>([])

  const usageSummaryFilterData = useMemo(() => {
    const filter: Record<string, any> = {}
    usageSummariesColumnFilters.forEach(({ id, value }) => {
      filter[id] = value
    })

    return filter
  }, [usageSummariesColumnFilters])

  const currentCompany: ICompany = useSelector(selectMyCurrentCompany)
  const { confirmation } = useConfirmationProvider()

  const { workerUsers } = useQueryUsers()

  const { companyTerminalsHavingCheckUid, companyTerminalsWithoutCheckUid } =
    useQueryTerminals()

  const {
    usageCategoryOptions,
    usageResourceTypeOptions,
    isCheckUsageOptionsLoading,
  } = useQueryCheckUsageOptions({
    enabled: isOpenUsageSummaries || isOpenUsageRecords,
  })

  const { usageSummaries, isCheckUsageSummariesLoading } =
    useQueryCheckUsageSummaries(
      _.omit(usageSummaryFilterData, ['resourceType']),
      {
        enabled: isOpenUsageSummaries,
      },
    )

  const { usageRecords, isCheckUsageRecordsLoading } =
    useQueryCheckUsageRecords(usageSummaryFilterData, {
      enabled: isOpenUsageRecords,
    })

  const { refetchCheckAppliedForIds } = useQueryCheckAppliedForIds()

  const { checkAccessesData, isCheckAccessesLoading } = useQueryCheckAccesses({
    enabled: isOpenPartnerSection,
  })

  const selectedEmployeeUid = useMemo(() => {
    const rowIdSelected = Object.keys(rowSelection)[0]
    if (rowIdSelected) {
      return workerUsers.find(({ id }) => id === Number(rowIdSelected))?.worker
        ?.checkUid
    }
    return undefined
  }, [rowSelection, workerUsers])

  const workerEmployees = useMemo(
    () =>
      workerUsers.filter(
        ({ worker }) => worker?.taxType === EWorkerTaxType.employee,
      ),
    [workerUsers],
  )

  const notSetWorkerTaxType = useMemo(
    () => workerUsers.filter(({ worker }) => !worker?.taxType),
    [workerUsers],
  )

  const workerContractors = useMemo(
    () =>
      workerUsers.filter(
        ({ worker }) => worker?.taxType === EWorkerTaxType.contractor,
      ),
    [workerUsers],
  )

  const checkEmployees = useMemo(
    () => workerEmployees.filter(({ worker }) => worker?.checkUid),
    [workerEmployees],
  )

  const checkContractors = useMemo(
    () => workerContractors.filter(({ worker }) => worker?.checkUid),
    [workerContractors],
  )

  const completedEmployeeOnboarding = useMemo(
    () =>
      checkEmployees.filter(
        ({ worker }) => worker?.checkStatus === ECheckStatus.completed,
      ),
    [checkEmployees],
  )

  const completedWorkerOnboarding = useMemo(
    () =>
      checkContractors.filter(
        ({ worker }) => worker?.checkStatus === ECheckStatus.completed,
      ),
    [checkContractors],
  )

  const numberOfEmployees = useMemo(
    () => workerEmployees.length,
    [workerEmployees.length],
  )
  const numberOfContractors = useMemo(
    () => workerContractors.length,
    [workerContractors.length],
  )

  const onSelectStep = useCallback(
    async (key: string) => {
      const exceptKeys = ['workplaces', 'employeeAndContractors']
      const isOnGoingTab = exceptKeys.includes(key)
      if (isOnGoingTab) {
        setCurrentTab('ongoing')
        const el = document.getElementById(key)

        setSectionOpenValues(prev =>
          produce(prev, draft => {
            const index = draft.indexOf(key)
            if (!draft[index]) {
              draft.push(key)
            }
          }),
        )
        if (el) {
          el.scrollIntoView({ behavior: 'smooth' })
        }
      } else {
        if (!signatoryUid) {
          await confirmation({
            message: 'You need to select a signatory first',
            header: 'Warning!',
            buttons: [
              {
                text: 'Ok',
                action: 'OK',
              },
            ],
          })
        } else {
          const el = document.getElementById(key)

          setSectionOpenValues(prev =>
            produce(prev, draft => {
              const index = draft.indexOf(key)
              if (!draft[index]) {
                draft.push(key)
              }
            }),
          )
          if (el) {
            el.scrollIntoView({ behavior: 'smooth' })
          }
        }
      }
    },
    [confirmation, signatoryUid],
  )

  const {
    renderCheckOnboardStatus,
    checkCompanyDetails,
    refetchCheckCompanyDetails,
    renderCompanyPaymentTooltip,
    countRemainingSteps,
    isOnboardStatusComplete,
    renderCompanyExtraTooltip,
  } = useCheckCompanyInfoSection({
    onSelectStep,
  })

  const remainingStepsSlideValue = useMemo(() => {
    try {
      if (checkCompanyDetails?.onboard.remainingSteps) {
        const totalStep = 3
        const result =
          (totalStep - checkCompanyDetails?.onboard.remainingSteps.length) *
          33.33
        return result
      }
      return 0
      // 3 steps => 0
      // 2 steps => 33%
      // 1 step => 66%
      // 0 step => 100%
    } catch (error) {
      console.log('error', error)
      return 0
    }
  }, [checkCompanyDetails])

  const isOnboardStepBlocking = useMemo(
    () => checkCompanyDetails?.onboard?.status === 'blocking',
    [checkCompanyDetails?.onboard?.status],
  )

  const companyCheckUid = useMemo(
    () => currentCompany.checkUid,
    [currentCompany.checkUid],
  )

  const afterCreateCheckCompany = useCallback(async () => {
    setIsFetchingCompany(true)
    try {
      const response = await apiClient.companies.getById(currentCompany.id)
      const sessionData: IUser = await sessionService.loadUser()
      sessionService.saveUser({
        ...sessionData,
        company: response,
      })
    } catch (error) {
      console.log('error', error)
    } finally {
      setIsFetchingCompany(false)
    }
  }, [currentCompany.id])

  const onSubmitSignatoryForm = useCallback(
    (formValues: ICheckSignatoryFormValues) => {
      if (formValues.checkUid) {
        setSignatoryUid(formValues.checkUid)
      }
    },
    [],
  )

  const onChangeTaxValues = useCallback((newTaxValues: ICheckTaxFormValues) => {
    setTaxValues(newTaxValues)
  }, [])

  const renderSignatoryForm = useMemo(
    () => (
      <>
        <Unless condition={Boolean(signatoryUid)}>
          <Alert variant='danger'>
            <Alert.Heading>Select one signatory and submit</Alert.Heading>
          </Alert>
        </Unless>
        <CheckSignatoryForm
          selectedCheckUid={signatoryUid}
          onSubmit={onSubmitSignatoryForm}
          onChange={signatory => {
            setSignatoryUid(signatory?.checkUid || '')
          }}
        />
      </>
    ),
    [onSubmitSignatoryForm, signatoryUid],
  )

  const brushIcon = useMemo<IToggleSectionIconProps>(
    () => ({
      icon: signatoryUid ? brush : brushOutline,
      color: signatoryUid ? 'success' : 'danger',
      tooltipProps: {
        placement: 'top',
        content: signatoryUid
          ? 'Signatory submitted successfully'
          : 'Signatory not submitted',
      },
    }),
    [signatoryUid],
  )

  const onboardingComponents = useMemo<ICheckComponentProps[]>(
    () => [
      {
        name: 'onboard',
        label: 'Onboard',
        isOpenComponentOnMounted: true,
        checkUid: signatoryUid,
        apiMethod: apiClient.check.createCompanyLink,
        isOpen: sectionOpenValues.includes('onboard'),
        icons: [
          {
            icon: isOnboardStepBlocking ? warningOutline : checkmarkCircle,
            color: isOnboardStepBlocking ? 'danger' : 'success',
            tooltipProps: {
              content: isOnboardStepBlocking ? renderCheckOnboardStatus : '',
              placement: 'top',
            },
          },
          brushIcon,
        ],
        onEvent(event: string) {
          if (event === 'check-onboard-app-completed') {
            refetchCheckCompanyDetails()
            refetchCheckAppliedForIds()
          }
        },
        getApiPayload() {
          return {
            signatory: {
              signatory: signatoryUid,
            },
          }
        },
      },
      {
        name: 'bankAccount',
        label: 'Payment Setup',
        checkUid: signatoryUid,
        isOpen: sectionOpenValues.includes('bankAccount'),
        icons: [
          getCheckSectionIconProps(
            checkCompanyDetails,
            'bank_account',
            renderCompanyPaymentTooltip,
          ),
          brushIcon,
        ],
        onEvent(event: string) {
          if (event === 'check-component-company-payment-setup-complete') {
            refetchCheckCompanyDetails()
          }
        },
        isOpenComponentOnMounted: true,
        apiMethod: apiClient.check.postCompanyPaymentSetupComponent,
        getApiPayload() {
          return {
            signatory: {
              signatory: signatoryUid,
            },
          }
        },
      },
      {
        name: 'setupParameters',
        label: 'Tax Setup',
        isOpenComponentOnMounted: true,
        isShowFieldsForTax: true,
        isOpen: sectionOpenValues.includes('setupParameters'),
        taxValues,
        checkUid: signatoryUid,
        icons: [
          getCheckSectionIconProps(
            checkCompanyDetails,
            'setup_parameters',
            renderCompanyExtraTooltip('setupParameters'),
          ),
          brushIcon,
        ],
        onEvent(event: string) {
          if (event === 'check-component-company-tax-setup-complete') {
            refetchCheckCompanyDetails()
            refetchCheckAppliedForIds()
          }
        },
        onChangeTaxValues,
        apiMethod: apiClient.check.postCompanyTaxSetupComponent,
        getApiPayload(formValues) {
          return {
            signatory: {
              signatory: signatoryUid,
              jurisdiction: formValues?.jurisdiction,
              federalOnly: formValues?.federalOnly,
            },
          }
        },
      },
      {
        name: 'filingAuthorization',
        label: 'Filing Authorization',
        isOpen: sectionOpenValues.includes('filingAuthorization'),
        icons: [
          getCheckSectionIconProps(
            checkCompanyDetails,
            'filing_authorization',
            renderCompanyExtraTooltip('filingAuthorization'),
          ),
          brushIcon,
        ],
        onEvent(event: string) {
          if (
            event === 'check-component-company-filing-authorization-complete'
          ) {
            refetchCheckCompanyDetails()
            refetchCheckAppliedForIds()
          }
        },
        isOpenComponentOnMounted: true,
        isShowFieldsForTax: true,
        checkUid: signatoryUid,
        taxValues,
        onChangeTaxValues,
        apiMethod: apiClient.check.postCompanyFilingAuthorization,
        getApiPayload(formValues) {
          return {
            signatory: {
              signatory: signatoryUid,
              jurisdiction: formValues?.jurisdiction,
              federalOnly: formValues?.federalOnly,
            },
          }
        },
      },
      {
        name: 'authorizationDocuments',
        label: 'Authorization Documents',
        isOpenComponentOnMounted: true,
        unNeededUid: true,
        apiMethod: apiClient.check.postCompanyAuthorizationDocumentsComponent,
        getApiPayload() {
          return {}
        },
      },
      {
        name: 'termsOfService',
        label: 'Terms of Service',
        isOpenComponentOnMounted: true,
        checkUid: signatoryUid,
        icons: [brushIcon],
        apiMethod: apiClient.check.postCompanyTermsOfServiceComponent,
        getApiPayload() {
          return {
            signatory: {
              signatory: signatoryUid,
            },
          }
        },
      },
      {
        name: 'accountingIntegration',
        label: 'Accounting Integration',
        isOpenComponentOnMounted: true,
        unNeededUid: true,
        apiMethod: apiClient.check.postCompanyAccountingIntegrationComponent,
        getApiPayload() {
          return {
            signatory: {
              signatory: signatoryUid,
            },
          }
        },
      },
      {
        name: 'previousPayrollProviderAccess',
        label: 'Previous Payroll Provider Access',
        isOpen: sectionOpenValues.includes('previousPayrollProviderAccess'),
        isOpenComponentOnMounted: true,
        checkUid: signatoryUid,
        icons: [brushIcon],
        apiMethod: apiClient.check.postCompanyPreviousPayrollProviderComponent,
        getApiPayload() {
          return {
            signatory: {
              signatory: signatoryUid,
            },
          }
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      signatoryUid,
      sectionOpenValues,
      isOnboardStepBlocking,
      renderCheckOnboardStatus,
      brushIcon,
      checkCompanyDetails,
      renderCompanyPaymentTooltip,
      taxValues,
      renderCompanyExtraTooltip,
      onChangeTaxValues,
    ],
  )

  const ongoingCompanyComponents = useMemo<ICheckComponentProps[]>(
    () => [
      {
        name: 'companyDetails',
        label: 'Company Details',
        isOpenComponentOnMounted: true,
        checkUid: signatoryUid,
        icons: [brushIcon],
        apiMethod: apiClient.check.postCompanyDetailsComponent,
        getApiPayload() {
          return {
            signatory: {
              signatory: signatoryUid,
            },
          }
        },
      },
      {
        name: 'companyDefinedEmployeeSetup',
        label: 'Company-defined Employee Setup',
        isOpenComponentOnMounted: true,
        unNeededUid: true,
        apiMethod: apiClient.check.postCompanyDefinedEmployeeSetupComponent,
        getApiPayload() {
          return {
            signatory: {
              signatory: signatoryUid,
            },
          }
        },
      },
      {
        name: 'companyTaxDocuments',
        label: 'Company Tax Documents',
        isOpenComponentOnMounted: true,
        unNeededUid: true,
        apiMethod: apiClient.check.postCompanyTaxDocumentsComponent,
        getApiPayload() {
          return {
            signatory: {
              signatory: signatoryUid,
            },
          }
        },
      },
      {
        name: 'earlyEnrollment',
        label: 'Early Enrollment',
        isOpenComponentOnMounted: true,
        checkUid: signatoryUid,
        icons: [brushIcon],
        apiMethod: apiClient.check.postCompanyEarlyEnrollmentComponent,
        getApiPayload() {
          return {
            signatory: {
              signatory: signatoryUid,
            },
          }
        },
      },
      {
        name: 'companyVerificationDocuments',
        label: 'Verification Documents',
        isOpenComponentOnMounted: true,
        unNeededUid: true,
        apiMethod: apiClient.check.postCompanyVerificationDocuments,
        getApiPayload() {
          return {
            signatory: {
              signatory: signatoryUid,
            },
          }
        },
      },
    ],
    [brushIcon, signatoryUid],
  )

  const checkPartnerComponents = [
    'simply_insured',
    'clair',
    'guideline',
    'next_insurance',
  ].map(field => ({
    name: field,
    label: _.startCase(field),
    unNeededUid: true,
    isOpenComponentOnMounted: true,
    apiMethod: apiClient.check.postPartner,
    getApiPayload() {
      return {
        partnerName: field,
      }
    },
  }))

  const afterCreateCheckEmployee = useCallback(
    async ({ worker }: { worker: IWorker }) => {
      if (worker.id) {
        const user = workerUsers.find(u => u.worker?.id === worker.id)
        if (user) {
          setRowSelection({ [user.id]: true })
        }
      } else {
        toast.error('Worker is not found')
      }
    },
    [workerUsers],
  )

  const renderCompanyDetails = useMemo(
    () => (
      <IonGrid style={{ padding: '0' }}>
        <IonRow>
          <IonCol
            size='6'
            sizeMd='12'
            sizeLg='6'
            sizeSm='12'
            sizeXs='12'
            style={{ padding: '4px 0' }}
          >
            <CheckCompanyInfoSection onSelectStep={onSelectStep} />
          </IonCol>
          <IonCol
            size='6'
            sizeMd='12'
            sizeLg='6'
            sizeSm='12'
            sizeXs='12'
            className='CompanyPayrolls__signatoryForm'
          >
            {renderSignatoryForm}
          </IonCol>
        </IonRow>
      </IonGrid>
    ),
    [onSelectStep, renderSignatoryForm],
  )

  useEffect(() => {
    if (checkCompanyDetails?.onboard?.status === ECheckStatus.completed) {
      setCurrentTab('ongoing')
    }
  }, [checkCompanyDetails?.onboard?.status])

  if (companyCheckUid) {
    return (
      <>
        <div className='PayrollsContainer__container'>
          <CommonTab
            currentTab={currentTab}
            onChangeTab={tab => {
              setCurrentTab(tab as string)
            }}
            tabs={[
              {
                name: 'onboarding',
                label: 'Onboarding',
                render() {
                  return (
                    <div>
                      {renderCompanyDetails}
                      <Unless condition={isOnboardStatusComplete}>
                        <div className='PayrollsContainer__sliderContainer'>
                          <Slider
                            value={remainingStepsSlideValue}
                            marks={{
                              0: 'Company Setup',
                              33.33: 'Payment Setup',
                              66.66: 'Tax Setup',
                              100: 'Filing Authorization',
                            }}
                          />
                        </div>
                      </Unless>
                      {renderCheckSections(onboardingComponents)}

                      <ToggleSection
                        title='Partners'
                        isOpen={isOpenPartnerSection}
                        onToggle={setIsOpenPartnerSection}
                      >
                        <ReusableTable
                          columns={[
                            {
                              header: 'Access Type',
                              size: 100,
                              accessorKey: 'accessType',
                            },
                            {
                              header: 'Scopes',
                              accessorKey: 'scope',
                              Cell({ cell }) {
                                const cellValue = cell.getValue<string[]>()
                                return (
                                  <div>
                                    {cellValue.map((scope, index) => (
                                      <Badge
                                        style={{
                                          fontSize: 13,
                                          marginRight: 4,
                                          marginTop: 4,
                                        }}
                                        key={index}
                                      >
                                        {scope}
                                      </Badge>
                                    ))}
                                  </div>
                                )
                              },
                            },
                            {
                              header: 'Status',
                              size: 100,
                              accessorKey: 'status',
                            },
                          ]}
                          data={checkAccessesData}
                          tableHeight={300}
                          state={{
                            isLoading: isCheckAccessesLoading,
                          }}
                        />
                        {renderCheckSections(checkPartnerComponents)}
                      </ToggleSection>

                      <ToggleSection
                        title='Pay Schedules'
                        controlIsOpen
                        name='PaySchedules'
                        isOpen={sectionOpenValues.includes('PaySchedules')}
                      >
                        <PaySchedules className='CompanyPayrolls__paySchedules' />
                      </ToggleSection>

                      <ToggleSection
                        title='Enrollment Profile'
                        controlIsOpen
                        name='EnrollmentProfile'
                        isOpen={sectionOpenValues.includes('EnrollmentProfile')}
                      >
                        <EnrollmentProfileForm />
                      </ToggleSection>
                    </div>
                  )
                },
              },
              {
                name: 'ongoing',
                label: 'Ongoing',
                render() {
                  return (
                    <div className='PayrollsContainer__ongoingContainer'>
                      <ToggleSection
                        title='Company'
                        className='toggleSection'
                        renderCount={() => (
                          <div
                            style={{
                              display: 'inline-block',
                              verticalAlign: 'middle',
                              marginLeft: 4,
                            }}
                          >
                            {countRemainingSteps > 0 && (
                              <ToolTipOverlay
                                content={renderCheckOnboardStatus}
                                placement='bottom'
                                className='CompanyPayrolls__tooltip'
                              >
                                <Badge
                                  className='CompanyPayrolls__badgeCounter'
                                  bg='danger'
                                >
                                  <span>Remaining steps: </span>
                                  <span>{countRemainingSteps}</span>
                                </Badge>
                              </ToolTipOverlay>
                            )}
                          </div>
                        )}
                      >
                        <div>
                          {renderCompanyDetails}
                          {renderCheckSections(ongoingCompanyComponents)}

                          <ToggleSection
                            title='Usage Summaries'
                            isOpen={isOpenUsageSummaries}
                            onToggle={setIsOpenUsageSummaries}
                          >
                            <ReusableTable
                              columns={[
                                {
                                  header: 'Category',
                                  accessorKey: 'category',
                                  size: 140,
                                },
                                {
                                  header: 'Count',
                                  accessorKey: 'count',
                                  size: 50,
                                  align: 'right',
                                },
                                {
                                  header: 'Period Start',
                                  accessorKey: 'periodStart',
                                  size: 100,
                                  align: 'center',
                                },
                                {
                                  header: 'Period End',
                                  accessorKey: 'periodEnd',
                                  size: 100,
                                  align: 'center',
                                },
                              ]}
                              data={usageSummaries}
                              tableHeight={250}
                              manualFiltering
                              enableTopToolbar
                              toolbarProps={{
                                isHiddenSearchBar: true,
                              }}
                              state={{
                                isLoading:
                                  isCheckUsageOptionsLoading ||
                                  isCheckUsageSummariesLoading,
                                columnFilters: usageSummariesColumnFilters,
                              }}
                              filterOptions={[
                                {
                                  label: 'Period Start',
                                  field: 'periodStart',
                                  type: EFieldType.date,
                                  maxDate: usageSummaryFilterData.periodEnd,
                                },
                                {
                                  label: 'Period End',
                                  field: 'periodEnd',
                                  type: EFieldType.date,
                                  minDate: usageSummaryFilterData.periodStart,
                                },
                                {
                                  label: 'Category',
                                  field: 'category',
                                  type: EFieldType.singleSelect,
                                  options: usageCategoryOptions,
                                },
                              ]}
                              onColumnFiltersChange={
                                setUsageSummariesColumnFilters
                              }
                            />
                          </ToggleSection>

                          <ToggleSection
                            title='Usage Records'
                            isOpen={isOpenUsageRecords}
                            onToggle={setIsOpenUsageRecords}
                          >
                            <ReusableTable
                              columns={[
                                {
                                  header: 'Category',
                                  accessorKey: 'category',
                                  size: 140,
                                },
                                {
                                  header: 'Resource Type',
                                  accessorKey: 'resourceType',
                                  size: 140,
                                },
                                {
                                  header: 'Effective At',
                                  accessorKey: 'effectiveAt',
                                  size: 100,
                                  align: 'right',
                                  Cell({ cell }) {
                                    const cellData =
                                      cell.getValue<string | null>()
                                    if (cellData) {
                                      return moment(cellData).format(
                                        'YYYY-MM-DD',
                                      )
                                    }
                                    return ''
                                  },
                                },
                              ]}
                              data={usageRecords}
                              tableHeight={250}
                              manualFiltering
                              enableTopToolbar
                              toolbarProps={{
                                isHiddenSearchBar: true,
                              }}
                              state={{
                                isLoading:
                                  isCheckUsageOptionsLoading ||
                                  isCheckUsageRecordsLoading,
                                columnFilters: usageSummariesColumnFilters,
                              }}
                              filterOptions={[
                                {
                                  label: 'Period Start',
                                  field: 'periodStart',
                                  type: EFieldType.date,
                                  maxDate: usageSummaryFilterData.periodEnd,
                                },
                                {
                                  label: 'Period End',
                                  field: 'periodEnd',
                                  type: EFieldType.date,
                                  minDate: usageSummaryFilterData.periodStart,
                                },
                                {
                                  label: 'Category',
                                  field: 'category',
                                  type: EFieldType.singleSelect,
                                  options: usageCategoryOptions,
                                },
                                {
                                  label: 'Resource Type',
                                  field: 'resourceType',
                                  type: EFieldType.singleSelect,
                                  options: usageResourceTypeOptions,
                                },
                              ]}
                              onColumnFiltersChange={
                                setUsageSummariesColumnFilters
                              }
                            />
                          </ToggleSection>
                        </div>
                      </ToggleSection>

                      <ToggleSection
                        title='Workplaces'
                        className='toggleSection'
                        isOpen={sectionOpenValues.includes('workplaces')}
                        name='workplaces'
                        controlIsOpen
                        renderCount={() => (
                          <div
                            style={{
                              display: 'inline-block',
                              verticalAlign: 'middle',
                              marginLeft: 4,
                            }}
                          >
                            <Badge className='CompanyPayrolls__badgeCounter'>
                              <span>Connected to Payroll: </span>
                              <span>
                                {companyTerminalsHavingCheckUid.length}
                              </span>
                            </Badge>
                            <Badge
                              className='CompanyPayrolls__badgeCounter'
                              bg='danger'
                            >
                              <span>Not Connected to Payroll: </span>
                              <span>
                                {companyTerminalsWithoutCheckUid.length}
                              </span>
                            </Badge>
                          </div>
                        )}
                      >
                        <div className='CompanyPayrolls__workplacesContainer'>
                          <CompanyTerminalSubTab />
                        </div>
                      </ToggleSection>

                      <ToggleSection
                        title={
                          <>
                            <span>Employees: </span>
                            <span className='CompanyPayrolls__workerBadges'>
                              Total: {numberOfEmployees}
                            </span>
                            <span className='CompanyPayrolls__workerBadges'>
                              Connected to Payroll: {checkEmployees.length}
                            </span>
                            <span className='CompanyPayrolls__workerBadges'>
                              Onboarding Completed:{' '}
                              {completedEmployeeOnboarding.length}
                            </span>

                            <span>Contractors: </span>
                            <span className='CompanyPayrolls__workerBadges'>
                              Total: {numberOfContractors}
                            </span>
                            <span className='CompanyPayrolls__workerBadges'>
                              Connected to Payroll: {checkContractors.length}
                            </span>
                            <span className='CompanyPayrolls__workerBadges'>
                              Onboarding Completed:{' '}
                              {completedWorkerOnboarding.length}
                            </span>

                            <span className='error'>Not set: </span>
                            <span className='CompanyPayrolls__workerBadges'>
                              {notSetWorkerTaxType.length}
                            </span>
                          </>
                        }
                        className='toggleSection'
                        isOpen={sectionOpenValues.includes(
                          'employeeAndContractors',
                        )}
                        name='employeeAndContractors'
                        controlIsOpen
                      >
                        <div className='PayrollsContainer__workerTable'>
                          <Unless condition={Boolean(selectedEmployeeUid)}>
                            <Alert variant='danger'>
                              <Alert.Heading>Select one</Alert.Heading>
                            </Alert>
                          </Unless>
                          <WorkersContainerTable
                            tableHeight={400}
                            style={{ margin: 0 }}
                            enableRowSelection={(row: RTRow<IUserData>) => {
                              const rowData = row.original
                              return Boolean(rowData.worker?.checkUid)
                            }}
                            enableMultiRowSelection={false}
                            onRowSelectionChange={setRowSelection}
                            state={{
                              rowSelection,
                            }}
                            afterCreateCheckEmployee={afterCreateCheckEmployee}
                          />
                        </div>

                        {selectedEmployeeUid && (
                          <CheckComponentsForWorker
                            workerUid={selectedEmployeeUid}
                          />
                        )}
                      </ToggleSection>

                      <CheckComponent
                        label='Run payroll'
                        apiMethod={apiClient.check.runPayroll}
                        getApiPayload={() => ({})}
                        name='runPayroll'
                        isOpenComponentOnMounted
                        unNeededUid
                      />

                      <ToggleSection title='Reports'>
                        <ReportsSection />
                      </ToggleSection>
                    </div>
                  )
                },
              },
            ]}
          />
        </div>
      </>
    )
  }

  return (
    <>
      <NotFoundPage
        message={
          <div>
            Get started using {"Concord's"} integrated payroll solution, powered
            by Check
          </div>
        }
        onGoBack={() => {
          setIsOpenCheckCompany(true)
        }}
        isHiddenIcon
        buttonText='Integrate'
      />
      <DialogCreateCheckCompany
        isOpen={isOpenCheckCompany}
        isLoading={isFetchingCompany}
        afterCreateCheckCompany={afterCreateCheckCompany}
        onClose={() => {
          setIsOpenCheckCompany(false)
        }}
      />
    </>
  )
}
export default CompanyPayrolls
