import { RTEditActionButtons } from './RTEditActionButtons'
import {
  RTCell,
  RTRow,
  IReusableTableRowData,
  ReusableTableInstance,
} from '../../types'
import { useMemo } from 'react'
import { ButtonGroup } from 'react-bootstrap'
import { ToolTipOverlay, ReusableButton } from '~/components/shared'
import { Link } from 'react-router-dom'

export interface IRTToggleRowActionMenuButtonProps<
  TData extends IReusableTableRowData,
> {
  cell: RTCell<TData>
  row: RTRow<TData>
  staticRowIndex?: number
  table: ReusableTableInstance<TData>
}

export const RTToggleRowActionMenuButton = <
  TData extends IReusableTableRowData,
>({
  cell,
  row,
  staticRowIndex,
  table,
}: IRTToggleRowActionMenuButtonProps<TData>) => {
  const {
    getState,
    options: { createDisplayMode, editDisplayMode, renderRowActions },
  } = table

  const { creatingRow, editingRow } = getState()

  const isCreating = creatingRow?.id === row.id
  const isEditing = editingRow?.id === row.id

  const showEditActionButtons =
    (isCreating && createDisplayMode === 'row') ||
    (isEditing && editDisplayMode === 'row')

  const rowActions = useMemo(() => {
    if (typeof renderRowActions === 'function') {
      const buttonProps = renderRowActions({ cell, row, staticRowIndex, table })

      const buttonElements = buttonProps.map((btn, index) => {
        if (btn.isHidden) {
          return null
        }
        return typeof btn.render === 'function' ? (
          <ToolTipOverlay key={index} {...btn.tooltipProps}>
            {btn.render()}
          </ToolTipOverlay>
        ) : (
          <ReusableButton
            tooltipContent={btn.tooltipProps?.content}
            tooltipPlacement={btn.tooltipProps?.placement}
            tooltipContentWhenDisabled={btn.tooltipContentWhenDisabled}
            tooltipProps={btn.tooltipProps}
            variant={btn.color || 'secondary'}
            style={{ fontSize: 13 }}
            onClick={event => {
              btn.onClick && btn.onClick(event)
            }}
            isDisabled={btn.isDisabled}
            className={btn.className}
            as={btn.href ? (Link as any) : undefined}
            to={btn.href}
          >
            {btn.label && <span>{btn.label}</span>}
            {btn.icon}
          </ReusableButton>
        )
      })

      return <ButtonGroup>{buttonElements}</ButtonGroup>
    }

    return null
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cell, renderRowActions, row, staticRowIndex, table])

  return (
    <>
      {renderRowActions && !showEditActionButtons ? (
        rowActions
      ) : showEditActionButtons ? (
        <RTEditActionButtons row={row} table={table} />
      ) : null}
    </>
  )
}
