import { ReactNode, useEffect, useState } from 'react'
import { Button, Collapse } from 'react-bootstrap'

interface Props {
  show?: boolean
  btnName: string
  field?: string
  orderField?: string
  children: ReactNode
}

export const ScheduleLoadsOverrideRenderInput = ({
  show,
  field,
  orderField,
  children,
  btnName,
}: Props) => {
  const [showBtn, setShowBtn] = useState(true)

  useEffect(() => {
    if (typeof show == 'boolean') setShowBtn(show)
  }, [show])

  if (field && field !== orderField) return <div>{children}</div>

  if (showBtn)
    return (
      <Collapse in={show}>
        <div>
          <Button
            variant='outline-primary'
            size='lg'
            onClick={() => setShowBtn(!show)}
          >
            {btnName}
          </Button>
        </div>
      </Collapse>
    )

  return (
    <Collapse in={show}>
      <div>{children}</div>
    </Collapse>
  )
}
