import { Badge } from 'react-bootstrap'
import { Control, useController } from 'react-hook-form'
import { useState } from 'react'
import { TextToggleInput } from './TextToggleInput'

interface HookFormTextToggleInputProps {
  name: string
  control: Control
  label?: string
  text?: string
  onChange?: (name: string, value: any) => void
}

export const HookFormTextToggleInput = ({
  name,
  control,
  label,
  text,
  onChange,
  ...inputProps
}: HookFormTextToggleInputProps) => {
  const [showInput, setShowInput] = useState(false)

  const { field } = useController({
    name,
    control,
  })

  return (
    <Badge onClick={() => setShowInput(!showInput)} pill>
      <TextToggleInput
        label={label}
        handleSubmit={value => {
          field.onChange(value)
          onChange && onChange(name, value)
        }}
        value={field.value}
        {...inputProps}
      ></TextToggleInput>
    </Badge>
  )
}
