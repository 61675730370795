import {
  cubeOutline,
  prismOutline,
  arrowDown,
  arrowUp,
  gitBranchOutline,
} from 'ionicons/icons'

import { COMPANY_SUB_TAB_VALUES, PROFILE_SETTINGS } from '~/utils/constants'

export const icons = {
  material: prismOutline,
  logistics: cubeOutline,
  asc: arrowUp,
  desc: arrowDown,
  delivery: gitBranchOutline,
  'Delivered Material': prismOutline,
  'Multi-line Delivered Material': prismOutline,
}

export const types = [
  { label: 'Material', value: 0 },
  { label: 'Delivery', value: 1 },
  { label: 'Delivered Material', value: 2 },
  { label: 'Multi-line Delivered Material', value: 3 },
  { label: 'Not Set', value: 'null' },
]

export const typesColor = {
  Delivery: 'dark',
}

export const statusSegments = ['Progress', 'Uploaded', 'Processed', '']
export const inCompleteStatusSegments = ['Created', 'Upload', 'Processing', '']

export const statuses = [
  { label: 'Progress', value: 'Progress' },
  { label: 'Uploaded', value: 'Uploaded' },
  { label: 'Processed', value: 'Processed' },
  { label: 'Approved', value: 'Approved' },
  { label: 'Rejected', value: 'Rejected' },
  { label: 'Exported', value: 'Exported' },
]

export const parserStatusValue = [
  { label: 'Not Sent', value: '0' },
  { label: 'Waiting', value: '1' },
  { label: 'Done', value: '2' },
  { label: 'Updated', value: '3' },
  { label: 'No Collection', value: '4' },
]

export const hitlStatusValue = [
  { label: 'Not Sent', value: '0' },
  { label: 'Waiting', value: '1' },
  { label: 'Done', value: '2' },
]

export const invoiceSource = [
  { label: 'From Loads', value: 'Load' },
  { label: 'From Invoices', value: 'Invoice' },
]

export const statusClassNames = {
  Approved: '--success',
  Progress: '--brand',
  Rejected: '--failure',
  Uploaded: '--inactive',
  Processed: '--process',
  Exported: '--process',
  NoCollection: '--noCollection',
  Locked: '--locked',
}

export const billLineOrderedKeys = [
  'lineDate',
  'ticketNum',
  'buyerSellerProduct',
  'qty',
  'price',
  'discount',
  'netPrice',
  'ext',
]

export const applyAllTables = {
  checked: true,
  value: 'Apply To Other Tables',
}

export const SEARCH_BILL_LINES_COLUMNS = [
  {
    label: '#',
    header: '#',
    field: 'index',
    dataClass: 'index-col --align-center',
    hidable: false,
    hidden: false,
    size: '1',
  },
  {
    label: 'Date',
    header: 'Date',
    field: 'lineDate',
    dataClass: 'date-col --align-center',
    editable: true,
    shouldCompare: true,
    isDate: true,
    hidable: true,
    hidden: false,
    enum: 0,
  },
  {
    label: 'Ticket #',
    header: 'Ticket #',
    field: 'ticketNum',
    dataClass: 'ticket-col --align-left',
    editable: true,
    addButton: true,
    isSorted: true,
    isSortedDesc: false,
    shouldCompare: true,
    hidable: true,
    hidden: false,
    enum: 1,
    size: '1',
  },
  {
    label: 'Product',
    header: 'Product',
    field: 'buyerSellerProductId',
    dataClass: 'product-col --align-center',
    editable: true,
    addButton: true,
    shouldCompare: true,
    hidable: true,
    hidden: false,
    enum: 2,
    size: '3',
    isBuyerSellerProduct: true,
  },
  {
    label: 'Quantity',
    header: 'Qty',
    field: 'qty',
    dataClass: 'qty-col --align-right',
    editable: true,
    addButton: true,
    shouldCompare: true,
    hidable: true,
    hidden: false,
    enum: 3,
    size: '1',
  },
  {
    label: 'Price',
    header: 'Price',
    field: 'price',
    dataClass: 'price-col --align-right',
    editable: true,
    addButton: true,
    shouldCompare: true,
    hidable: true,
    hidden: false,
    enum: 4,
    size: '1',
  },
  {
    label: 'Buyer Terminal',
    header: 'Buyer Terminal',
    field: 'buyerTerminalId',
    dataClass: 'terminal-col --align-center',
    editable: true,
    isTerminal: true,
    shouldCompare: false,
    hidable: true,
    hidden: false,
    enum: 9,
    size: '2',
  },
  {
    label: 'Total',
    header: 'Total',
    field: 'total',
    dataClass: 'ext-col --align-center',
    editable: false,
    shouldCompare: false,
    hidable: true,
    hidden: false,
    enum: 12,
    size: '1',
  },
  {
    header: '',
    field: 'selectBillLine',
    dataClass: 'actions-col --align-center',
    hidable: false,
    hidden: false,
    size: '1',
  },
]

export const ASSOCIATE_BILL_LINE_COLUMN = {
  header: '',
  field: 'associateBillLine',
  dataClass: 'actions-col --align-center',
  hidable: false,
  isBlank: false,
  size: '1',
}

export const BILL_LINES_COLUMNS = [
  {
    label: '#',
    header: '#',
    field: 'index',
    dataClass: 'index-col --align-left',
    hidable: false,
    hidden: false,
    size: '1',
    enum: 0,
    sorted: false,
  },
  {
    label: 'Match',
    header: 'Match',
    field: 'matchIcon',
    dataClass: 'index-col --align-left',
    hidable: false,
    hidden: false,
    size: '1',
    enum: 1,
    sorted: false,
  },
  {
    label: 'Flags',
    header: 'Flags',
    field: 'flags',
    dataClass: 'index-col --align-left',
    hidable: false,
    hidden: false,
    size: '1',
    enum: 2,
    sorted: false,
  },
  {
    label: 'Date',
    header: 'Date',
    field: 'lineDate',
    dataClass: 'date-col --align-center',
    editable: true,
    shouldCompare: true,
    isDate: true,
    hidable: true,
    hidden: false,
    enum: 3,
    sorted: true,
    sortIndex: 1,
    isAsc: true,
    sortField: 'lineDate',
  },
  {
    label: 'Ticket #',
    header: 'Ticket #',
    field: 'ticketNum',
    dataClass: 'ticket-col --align-left',
    editable: true,
    addButton: true,
    isSorted: true,
    isSortedDesc: false,
    shouldCompare: true,
    hidable: true,
    hidden: false,
    enum: 4,
    size: '1',
    sorted: true,
    isAsc: true,
    sortIndex: 0,
    sortField: 'ticketNum',
  },
  {
    label: 'Product',
    header: 'Product',
    field: 'buyerSellerProductId',
    dataClass: 'product-col --align-center',
    editable: true,
    addButton: true,
    shouldCompare: true,
    hidable: true,
    hidden: false,
    enum: 5,
    // size: '2',
    isBuyerSellerProduct: true,
    sorted: false,
  },
  {
    label: 'Quantity',
    header: 'Qty',
    field: 'qty',
    dataClass: 'qty-col --align-right',
    editable: true,
    addButton: true,
    shouldCompare: true,
    hidable: true,
    hidden: false,
    enum: 6,
    size: '1',
    sorted: false,
  },
  {
    label: 'Price',
    header: 'Price',
    field: 'price',
    dataClass: 'price-col --align-right',
    editable: true,
    addButton: true,
    shouldCompare: true,
    hidable: true,
    hidden: true,
    enum: 7,
    sorted: false,
  },
  {
    label: 'Discount',
    header: 'Discount',
    field: 'discount',
    dataClass: 'discount-col --align-right',
    addButton: true,
    editable: true,
    hidable: true,
    shouldCompare: true,
    hidden: true,
    enum: 8,
    sorted: false,
  },
  {
    label: 'Net Price',
    header: 'Net Price',
    field: 'netPrice',
    dataClass: 'net-col --align-right',
    hidable: true,
    shouldCompare: true,
    hidden: true,
    enum: 9,
    sorted: false,
  },
  {
    label: 'Subtotal Ext. Price',
    header: 'Subtotal Ext. Price',
    field: 'subExt',
    dataClass: 'ext-col --align-right',
    hidable: true,
    shouldCompare: true,
    hidden: true,
    enum: 10,
    sorted: false,
  },
  {
    label: 'Cost Code',
    header: 'Cost Code',
    field: 'costCodeId',
    dataClass: 'costcode-col --align-center',
    editable: true,
    isCostCode: true,
    shouldCompare: false,
    hidable: true,
    hidden: true,
    enum: 11,
    sorted: false,
  },
  {
    label: 'Buyer Terminal',
    header: 'Buyer Terminal',
    field: 'buyerTerminalId',
    dataClass: 'terminal-col --align-center',
    editable: true,
    isTerminal: true,
    shouldCompare: false,
    hidable: true,
    hidden: false,
    enum: 12,
    size: '1',
    sorted: false,
  },
  {
    label: 'Material Ext. Price',
    header: 'Material Ext. Price',
    field: 'materialExtPrice',
    dataClass: 'ext-col --align-center',
    editable: true,
    shouldCompare: false,
    hidable: true,
    hidden: true,
    enum: 13,
    sorted: false,
  },
  {
    label: 'Tax',
    header: 'Tax',
    field: 'tax',
    dataClass: 'ext-col --align-center',
    editable: false,
    shouldCompare: false,
    hidable: true,
    hidden: true,
    enum: 14,
    sorted: false,
  },
  {
    label: 'Total',
    header: 'Total',
    field: 'total',
    dataClass: 'ext-col --align-center',
    editable: false,
    shouldCompare: false,
    hidable: true,
    hidden: false,
    enum: 15,
    sorted: false,
  },
  {
    label: 'Other Charges',
    header: 'Other Charges',
    field: 'otherCharges',
    dataClass: 'qty-col --align-right',
    editable: true,
    addButton: true,
    shouldCompare: true,
    hidable: true,
    hidden: true,
    enum: 16,
    sorted: false,
  },
  {
    header: 'Actions',
    field: 'actions',
    label: 'Actions',
    dataClass: 'actions-col --align-right',
    hidable: false,
    hidden: false,
    size: '1',
    enum: 17,
    //size: 'auto'
    sorted: false,
  },
]

export const UPDATE_BILL_LINES_COLUMNS = [
  {
    label: '#',
    header: '#',
    field: 'index',
    dataClass: 'index-col --align-left',
    hidable: false,
    isBlank: true,
  },
  {
    label: 'Date',
    header: 'Date',
    field: 'lineDate',
    dataClass: 'date-col --align-center',
    editable: true,
    shouldCompare: true,
    isDate: true,
    hidable: false,
    isBlank: false,
  },
  {
    label: 'Ticket #',
    header: 'Ticket #',
    field: 'ticketNum',
    dataClass: 'ticket-col --align-left',
    editable: true,
    addButton: false,
    isSorted: true,
    isSortedDesc: false,
    shouldCompare: true,
    hidable: false,
    isBlank: false,
  },
  {
    label: 'Product',
    header: 'Product',
    field: 'buyerSellerProductId',
    dataClass: 'product-col --align-left',
    editable: true,
    addButton: false,
    shouldCompare: true,
    hidable: false,
    isBlank: false,
  },
  {
    label: 'Quantity',
    header: 'Quantity',
    field: 'qty',
    dataClass: 'qty-col --align-right',
    editable: true,
    addButton: false,
    shouldCompare: true,
    hidable: false,
    isBlank: false,
  },
  {
    label: 'Price',
    header: 'Price',
    field: 'price',
    dataClass: 'price-col --align-right',
    editable: true,
    addButton: false,
    shouldCompare: true,
    hidable: true,
    isBlank: false,
  },
  {
    label: 'Discount',
    header: 'Discount',
    field: 'discount',
    dataClass: 'discount-col --align-right',
    addButton: false,
    editable: true,
    hidable: true,
    isBlank: false,
    shouldCompare: true,
  },
  {
    label: 'Net Price',
    header: 'Net Price',
    field: 'netPrice',
    dataClass: 'net-col --align-right',
    hidable: false,
    isBlank: true,
    shouldCompare: true,
  },
  {
    label: 'Ext. Price',
    header: 'Ext. Price',
    field: 'ext',
    dataClass: 'ext-col --align-right',
    hidable: false,
    isBlank: true,
    shouldCompare: true,
  },
  {
    label: 'Cost Code',
    header: 'Cost Code',
    field: 'costCode',
    dataClass: 'costcode-col --align-left',
    editable: true,
    addButton: true,
    shouldCompare: true,
    hidable: false,
  },
  {
    header: 'Actions',
    label: 'Actions',
    field: 'actions',
    dataClass: 'actions-col --align-right',
    hidable: false,
    isBlank: false,
  },
]

export const BILL_LINE_FILTER = { flag: 'DEFAULT', matching: 'DEFAULT' }

export const BILL_LINES_SUMMARY_COLUMNS = [
  {
    label: '#',
    header: '#',
    field: 'index',
    size: 1,
    dataClass: '--align-left',
  },
  {
    label: 'Product',
    header: 'Product',
    field: 'product',
    size: 5,
    dataClass: '--align-left',
  },
  {
    label: 'Qty',
    header: 'Qty',
    field: 'qty',
    size: 1,
    dataClass: '--align-right',
  },
  {
    label: 'LDs',
    header: 'LDs',
    field: 'lds',
    size: 1,
    dataClass: '--align-right',
  },
  {
    label: 'Ext Price',
    header: 'Ext Price',
    field: 'price',
    size: 2,
    dataClass: '--align-right',
  },
  {
    label: 'ASP',
    header: 'ASP',
    field: 'asp',
    size: 2,
    dataClass: '--align-right',
  },
]

export const MINI_INVOICES_COLUMNS = [
  { header: 'Seller', field: 'seller', dataClass: '--align-center' },
  { header: 'Buyer', field: 'buyer', dataClass: '--align-center' },
  { header: 'Total', field: 'total', dataClass: 'total-col --align-center' },
  { header: 'Qty', field: 'qty', dataClass: 'qty-col --align-center' },
  { header: 'LDs', field: 'loads', dataClass: 'loads-col --align-center' },
  { header: 'Date', field: 'date', dataClass: 'date-col --align-center' },
  { header: 'Status', field: 'status', dataClass: 'status-col --align-center' },
]

export const INVOICES_SORT_FIELDS = {
  col1: 'seller_id',
  col3: 'total',
  col4: 'qty',
  col5: 'load_count',
  col6: 'end_date',
  col7: 'status',
  col8: 'category',
  col9: 'num',
  col10: 'due_date',
  col11: 'buyer_terminal',
}

export const MINI_BILL_LINES_COLUMNS = [
  {
    header: 'Date',
    field: 'date',
    dataClass: 'discount-col --align-center',
    editable: true,
    size: 2,
  },
  {
    header: 'Product',
    field: 'productName',
    dataClass: 'product-col --align-center',
    editable: true,
    addButton: false,
    size: 4,
  },
  {
    header: 'Price',
    field: 'price',
    dataClass: 'price-col --align-center',
    editable: true,
    addButton: true,
    size: 4,
  },
  {
    header: 'Discount',
    field: 'discount',
    dataClass: 'discount-col --align-center',
    editable: true,
    size: 2,
  },
]

export const FLAGABLE_TYPE = {
  Invoice: 'Invoice',
  BillLine: 'BillLine',
}

export const NUMBER_FILTER_TYPE = [
  { id: 'total', field: 'total', label: 'Total' },
  { id: 'qty', field: 'qty', label: 'Quantity' },
  { id: 'asp', field: 'asp', label: 'ASP' },
  { id: 'loadCount', field: 'loadCount', label: 'Load count' },
]

export const DEFAULT_INVOICES_FILTER = {
  total: undefined,
  qty: undefined,
  asp: undefined,
  loadCount: undefined,
  status: [],
  types: [],
  perPage: 12,
  sellers: [],
  parserStatus: [],
  hitlStatus: [],
  date: { startDate: undefined, endDate: undefined },
  createdAt: { startDate: undefined, endDate: undefined },
  buyerTerminals: [],
}

export const INVOICE_SORT_OPTIONS = [
  {
    label: 'Invoice #',
    value: 'num',
    type: 'number',
    sorted: false,
    isAsc: true,
    sortField: 'num',
    isHidden: false,
  },
  {
    label: 'Seller',
    value: 'seller_id',
    type: 'number',
    sorted: false,
    isAsc: true,
    sortField: 'seller_id',
    isHidden: false,
  },
  {
    label: 'Buyer',
    value: 'buyer_id',
    type: 'number',
    sorted: false,
    isAsc: true,
    sortField: 'buyer_id',
    isHidden: false,
  },
  {
    label: 'Buyer Terminal',
    value: 'buyer_terminal_id',
    type: 'number',
    sorted: false,
    isAsc: true,
    sortField: 'buyer_terminal_id',
    isHidden: false,
  },
  {
    label: 'Quantity',
    value: 'qty',
    type: 'number',
    sorted: false,
    isAsc: true,
    sortField: 'qty',
    isHidden: false,
  },
  {
    label: 'Loads',
    value: 'load_count',
    type: 'number',
    sorted: false,
    isAsc: true,
    sortField: 'load_count',
    isHidden: false,
  },
  {
    label: 'Invoice Period',
    value: 'end_date',
    type: 'number',
    sorted: false,
    isAsc: false,
    sortField: 'end_date',
    isHidden: false,
  },
  {
    label: 'Due Date',
    value: 'due_date',
    type: 'number',
    sorted: false,
    isAsc: true,
    sortField: 'due_date',
    isHidden: false,
  },
  {
    label: 'Status',
    value: 'status',
    type: 'number',
    sorted: false,
    isAsc: true,
    sortField: 'status',
    isHidden: false,
  },
  {
    label: 'Type',
    value: 'category',
    type: 'number',
    sorted: false,
    isAsc: true,
    sortField: 'category',
    isHidden: false,
  },
  {
    label: 'Created At',
    value: 'created_at',
    type: 'number',
    sorted: true,
    isAsc: false,
    sortField: 'created_at',
    isHidden: false,
  },
  {
    label: 'Start Date',
    value: 'start_date',
    type: 'number',
    sorted: false,
    isAsc: true,
    sortField: 'start_date',
    isHidden: false,
  },
]

export const INVOICE_COUNTER = {
  flags: [],
  nonflags: [],
  invoiceFlags: [],
  nonInvoiceFlags: [],
  unmatchedTickers: [],
  matcheds: [],
  noDifferences: [],
  withDifferences: [],
  unmatchedInvoice: [],
  all: [],
}

export const INVOICE_BAR_HEADER_COLUMNS = [
  {
    header: 'Total',
    field: 'total',
    dataClass: 'total-col --align-center',
  },
  {
    header: 'Qty',
    field: 'qty',
    dataClass: 'qty-col --align-center',
  },
  {
    header: 'LDs',
    field: 'lds-count',
    dataClass: 'lds-col --align-center',
  },
  {
    header: 'Start',
    field: 'start-date',
    dataClass: 'start-col --align-center',
  },
  { header: 'End', field: 'end-date', dataClass: 'end-col --align-center' },
  {
    header: 'Due',
    field: 'due-date',
    dataClass: 'due-col --align-center',
  },
  {
    header: 'Flags',
    field: 'flags',
    dataClass: 'flags-col --align-center',
  },
  {
    header: 'Bill Lines',
    field: 'matching',
    dataClass: 'bill-lines-col --align-center',
  },
]

export const CALENDAR_COLUMN = ['start-date', 'end-date', 'due-date']

export const INVOICE_PANELS = {
  TICKETS: 'Tickets',
  INVOICE: 'Invoice',
  COMPARISON: 'Comparison',
}

export const getCSVBillLineHeader = () => {
  return BILL_LINES_COLUMNS.filter(col => col.label).map(
    ({ label, field }) => label && { label, key: field },
  )
}

const defaultDate = new Date().toISOString()

export const BILL_LINES_SORT_OPTIONS = [
  {
    label: 'id',
    sortField: 'id',
    sorted: false,
    hidable: true,
    isAsc: true,
    updatedAt: defaultDate,
  },
  {
    label: 'Ticket #',
    sortField: 'ticketNum',
    sorted: true,
    isAsc: true,
    updatedAt: defaultDate,
  },
  {
    label: 'Date',
    sortField: 'lineDate',
    sorted: true,
    isAsc: true,
    updatedAt: defaultDate,
  },
  {
    label: 'Product',
    sortField: 'buyerSellerProductName',
    sorted: false,
    isAsc: true,
    updatedAt: defaultDate,
  },
  {
    label: 'Quantity',
    sortField: 'qty',
    sorted: false,
    isAsc: true,
    updatedAt: defaultDate,
  },
  {
    label: 'Price',
    sortField: 'price',
    sorted: false,
    isAsc: true,
    updatedAt: defaultDate,
  },
  {
    label: 'Discount',
    sortField: 'discount',
    sorted: false,
    isAsc: true,
    updatedAt: defaultDate,
  },
  {
    label: 'Net Price',
    sortField: 'netPrice',
    sorted: false,
    isAsc: true,
    updatedAt: defaultDate,
  },
  {
    label: 'Ext. Price',
    sortField: 'ext',
    sorted: false,
    isAsc: true,
    updatedAt: defaultDate,
  },
  {
    label: 'Flag Status',
    sortField: 'flagsNum',
    sorted: false,
    isAsc: true,
    updatedAt: defaultDate,
    type: 'array',
  },
  {
    label: 'Matched Status',
    sortField: 'billLineId',
    sorted: false,
    isAsc: true,
    updatedAt: defaultDate,
  },
]

export const MATCH_BILL_LINES_OPTIONS = [
  {
    label: 'Similarity',
    sortField: 'matchingScore',
    sorted: true,
    isAsc: false,
    updatedAt: defaultDate,
  },
  {
    label: 'Proximity',
    sortField: 'proximityScore',
    sorted: true,
    isAsc: false,
    updatedAt: defaultDate,
  },
  {
    label: 'Date',
    sortField: 'lineDate',
    sorted: false,
    isAsc: true,
    updatedAt: defaultDate,
  },
  {
    label: 'Ticket #',
    sortField: 'ticketNum',
    sorted: false,
    isAsc: true,
    updatedAt: defaultDate,
  },
  {
    label: 'Product',
    sortField: 'buyerSellerProductName',
    sorted: false,
    isAsc: true,
    updatedAt: defaultDate,
  },
  {
    label: 'Quantity',
    sortField: 'qty',
    sorted: false,
    isAsc: true,
    updatedAt: defaultDate,
  },
  {
    label: 'Price',
    sortField: 'price',
    sorted: false,
    isAsc: true,
    updatedAt: defaultDate,
  },
  {
    label: 'Discount',
    sortField: 'discount',
    sorted: false,
    isAsc: true,
    updatedAt: defaultDate,
  },
  {
    label: 'Net Price',
    sortField: 'netPrice',
    sorted: false,
    isAsc: true,
    updatedAt: defaultDate,
  },
  {
    label: 'Ext. Price',
    sortField: 'ext',
    sorted: false,
    isAsc: true,
    updatedAt: defaultDate,
  },
]

export const SORT_OPTIONS_FOR_COMPARE_INVOICE = [...BILL_LINES_SORT_OPTIONS]

export const SUB_TABS_PROFILE = [
  {
    label: 'General',
    heading: 'Profile',
    color: 'lightGreen',
    name: 'general',
    showSubmit: true,
  },
  {
    label: 'Terminals',
    heading: 'Terminals',
    color: 'lightGray',
    name: 'terminals',
    showSubmit: false,
  },
  {
    label: 'Password',
    heading: 'Change your password',
    color: 'red',
    name: 'password',
    showSubmit: true,
  },
  {
    label: 'Payment Methods',
    heading: 'Manage your payment methods',
    color: 'red',
    name: 'payment_methods',
    showSubmit: false,
  },
  {
    label: 'My Default Preferences',
    heading: 'My Default Preferences',
    color: 'red',
    showSubmit: false,
    name: PROFILE_SETTINGS.storedData,
  },
  {
    label: 'Delete Account',
    heading: 'Delete Account',
    showSubmit: false,
    name: PROFILE_SETTINGS.deleteAccount,
  },
]

export const SUB_TABS_EDITCOMPANY = [
  {
    label: 'Company Details',
    heading: 'Company Details',
    color: 'lightGreen',
    showSubmit: true,
    value: 'company_details',
  },
  {
    label: 'Settings & Automations',
    heading: 'Settings & Automations',
    color: 'lightGray',
    showSubmit: true,
    value: 'settings_automations',
  },
  {
    label: 'Document Processing',
    heading: 'Document Processing',
    color: 'lightBlue',
    showSubmit: false,
    value: 'document_processing',
  },
  {
    label: 'Expected Fields',
    heading: 'Set Expected Fields',
    color: 'lightGray',
    showSubmit: false,
    value: 'expected_fields',
  },
  {
    label: 'Line Item Data Carry-Down',
    heading:
      'Set values to apply from previous row to bill lines with missing data in that column (e.g. if ticket number is shown on the first bill line for a load but not for lines 2 and 3 related to the same load)',
    color: 'lightGray',
    showSubmit: false,
    value: 'line_item_data',
  },
  {
    label: 'Address',
    heading: 'Address',
    color: 'green',
    showSubmit: false,
    value: COMPANY_SUB_TAB_VALUES.address,
  },
  {
    label: 'Emails',
    heading: 'Emails',
    color: 'green',
    showSubmit: false,
    value: COMPANY_SUB_TAB_VALUES.email,
  },
  {
    label: 'Phone numbers',
    heading: 'Phone numbers',
    color: 'green',
    showSubmit: false,
    value: COMPANY_SUB_TAB_VALUES.phoneNumbers,
  },
  {
    label: 'Bank Accounts',
    heading: 'Bank Accounts',
    color: 'green',
    showSubmit: false,
    value: COMPANY_SUB_TAB_VALUES.bankAccounts,
  },
  {
    label: 'Financial Application Data',
    heading: 'Financial Application Data',
    color: 'green',
    showSubmit: false,
    value: COMPANY_SUB_TAB_VALUES.financialApplicationData,
  },
]

export const FIXED_PRICES_COLUMNS = [
  {
    label: '#',
    header: '#',
    field: 'index',
    subField: '',
    size: 1,
    dataClass: '--align-left',
  },
  {
    label: 'Buyer Seller Product',
    header: 'Buyer Seller Product',
    field: 'buyerSellerProduct',
    subField: '',
    size: 2,
    dataClass: '--align-left',
  },
  {
    label: 'Start Date',
    header: 'Start Date',
    field: 'startDate',
    subField: '',
    size: 1,
    dataClass: '--align-left',
  },
  {
    label: 'End Date',
    header: 'End Date',
    field: 'endDate',
    subField: '',
    size: 1,
    dataClass: '--align-left',
  },
  {
    label: 'Price',
    header: 'Price',
    field: 'price',
    subField: '',
    size: 1,
    dataClass: '--align-left',
  },
  {
    label: 'Net Price',
    header: 'Net Price',
    field: 'netPrice',
    subField: '',
    size: 1,
    dataClass: '--align-left',
  },
  {
    label: 'Flat Rate',
    header: 'Flat Rate',
    field: 'flatRate',
    subField: '',
    size: 1,
    dataClass: '--align-left',
  },
  {
    label: 'Discount',
    header: 'Discount',
    field: 'discount',
    size: 1,
    dataClass: '--align-left',
  },
  {
    label: 'Buyer Terminal',
    header: 'Buyer Terminal',
    field: 'buyerTerminal',
    subField: 'name',
    size: 1,
    dataClass: '--align-left',
  },
  {
    label: 'Seller Terminal',
    header: 'Seller Terminal',
    field: 'sellerTerminal',
    subField: 'name',
    size: 1,
    dataClass: '--align-left',
  },
]

export const UPDATE_FIXED_PRICES_COLUMNS = [
  {
    label: 'Buyer Seller Product',
    header: 'Buyer Seller Product',
    field: 'buyerSellerProduct',
    dataClass: 'ticket-col --align-left',
    editable: true,
    isSelect: true,
    addButton: false,
    isSorted: true,
    isSortedDesc: false,
    shouldCompare: true,
    hidable: false,
    isBlank: false,
  },
  {
    label: 'Start Date',
    header: 'Start Date',
    field: 'startDate',
    dataClass: 'date-col --align-left',
    editable: true,
    shouldCompare: true,
    isDate: true,
    hidable: false,
    isBlank: false,
  },
  {
    label: 'End Date',
    header: 'End Date',
    field: 'endDate',
    dataClass: 'date-col --align-left',
    editable: true,
    shouldCompare: true,
    isDate: true,
    hidable: false,
    isBlank: false,
  },
  {
    label: 'Price',
    header: 'Price',
    field: 'price',
    dataClass: 'price-col --align-left',
    editable: true,
    addButton: false,
    isSorted: true,
    isSortedDesc: false,
    shouldCompare: true,
    hidable: false,
    isBlank: false,
  },
  {
    label: 'Discount',
    header: 'Discount',
    field: 'discount',
    dataClass: 'discount-col --align-left',
    editable: true,
    addButton: false,
    isSorted: true,
    isSortedDesc: false,
    shouldCompare: true,
    hidable: false,
    isBlank: false,
  },
  {
    label: 'Flat Rate',
    header: 'Flat Rate',
    field: 'flatRate',
    dataClass: 'ticket-col --align-left',
    editable: true,
    addButton: false,
    isSorted: true,
    isSortedDesc: false,
    shouldCompare: true,
    hidable: false,
    isBlank: false,
  },
  {
    label: 'Buyer Terminal',
    header: 'Buyer Terminal',
    field: 'buyerTerminal',
    dataClass: 'ticket-col --align-left',
    editable: true,
    isSelect: true,
    addButton: false,
    isSorted: true,
    isSortedDesc: false,
    shouldCompare: true,
    hidable: false,
    isBlank: false,
  },
  {
    label: 'Seller Terminal',
    header: 'Seller Terminal',
    field: 'sellerTerminal',
    dataClass: 'ticket-col --align-left',
    editable: true,
    isSelect: true,
    addButton: false,
    isSorted: true,
    isSortedDesc: false,
    shouldCompare: true,
    hidable: false,
    isBlank: false,
  },
]

export const EXAMPLE_STYLE_GUIDE_OPTIONS = [
  { label: 'A', value: 'A' },
  { label: 'B', value: 'B' },
  { label: 'C', value: 'C' },
  { label: 'D', value: 'D' },
  { label: 'E', value: 'E' },
  { label: 'F', value: 'F' },
  { label: 'G', value: 'G' },
]

export const sourceValues = [
  {
    name: 'Actual',
    value: 'Invoice',
    placement: 'left',
    description: 'Actual Invoices from Seller',
  },
  {
    name: 'Projected',
    value: 'Load',
    placement: 'right',
    description: 'Projected Invoices from Tickets / Loads',
  },
]

export const COMPARISON_TABLE_COLUMNS = [
  {
    Header: '#',
    accessor: 'index',
    dataClass: 'index-col --align-left',
    hide: false,
    hideable: false,
  },
  {
    Header: 'Match',
    accessor: 'matchIcon',
    dataClass: 'index-col --align-left',
    hide: false,
  },
  {
    Header: 'Flags',
    accessor: 'flags',
    dataClass: 'index-col --align-left',
    hide: false,
  },
  {
    Header: 'Date',
    accessor: 'lineDate',
    dataClass: 'date-col --align-center',
    hide: false,
  },
  {
    Header: 'Ticket #',
    accessor: 'ticketNum',
    dataClass: 'ticket-col --align-left',
    hide: false,
  },
  {
    Header: 'Product',
    accessor: 'buyerSellerProductId',
    dataClass: 'product-col --align-center',
    hide: false,
  },
  {
    Header: 'Qty',
    accessor: 'qty',
    dataClass: 'qty-col --align-right',
    hide: false,
  },
  {
    Header: 'Price',
    accessor: 'price',
    dataClass: 'price-col --align-right',
    hide: false,
  },
  {
    Header: 'Discount',
    accessor: 'discount',
    dataClass: 'discount-col --align-right',
    hide: true,
  },
  {
    Header: 'Net Price',
    accessor: 'netPrice',
    dataClass: 'net-col --align-right',
    hide: true,
  },
  {
    Header: 'Subtotal Ext. Price',
    accessor: 'subExt',
    dataClass: 'ext-col --align-right',
    hide: true,
  },
  {
    Header: 'Cost Code',
    accessor: 'costCodeId',
    dataClass: 'costcode-col --align-center',
    hide: true,
  },
  {
    Header: 'Buyer Terminal',
    accessor: 'buyerTerminalId',
    dataClass: 'terminal-col --align-center',
    hide: false,
  },
  {
    Header: 'Material Ext. Price',
    accessor: 'materialExtPrice',
    dataClass: 'ext-col --align-center',
    hide: true,
  },
  {
    Header: 'Tax',
    accessor: 'tax',
    dataClass: 'ext-col --align-center',
    hide: true,
  },
  {
    Header: 'Total',
    accessor: 'total',
    dataClass: 'ext-col --align-center',
    hide: false,
  },
  {
    Header: 'Other Charges',
    accessor: 'otherCharges',
    dataClass: 'qty-col --align-right',
    hide: true,
  },
  {
    Header: 'Actions',
    accessor: 'actions',
    dataClass: 'actions-col --align-right',
    hide: false,
    hideable: false,
  },
]

export const editable = [
  'lineDate',
  'ticketNum',
  'buyerSellerProductId',
  'qty',
  'price',
  'discount',
  'costCodeId',
  'buyerTerminalId',
  'materialExtPrice',
  'otherCharges',
]

export const shouldCompare = [
  'lineDate',
  'ticketNum',
  'buyerSellerProductId',
  'buyerTerminalId',
  'qty',
  'price',
  'discount',
  'netPrice',
  'subExt',
  'otherCharges',
]

export const INVOICE_SOURCE = {
  load: 'Load',
  invoice: 'Invoice',
}

export const INVOICE_STATUS = {
  progress: 'Progress',
  uploaded: 'Uploaded',
  processed: 'Processed',
  approved: 'Approved',
  rejected: 'Rejected',
  exported: 'Exported',
}
