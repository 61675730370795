import { useMemo, useCallback } from 'react'
import { toast } from 'react-toastify'

import usePusher from './usePusher'
import { useCustomQueryDataOfDriver } from '../useQueryData'
import { ELoadStatus } from '~/types/enums/ELoadStatus'

const usePusherDriver = () => {
  const {
    userInfo,
    refetchUserInfo,
    updateDriverFleet,
    loadData,
    updateLoad,
    isLoadsDataFetched,
    isUserInfoFetched,
  } = useCustomQueryDataOfDriver()

  const channelName = useMemo(
    () =>
      userInfo?.driverFleet?.id ? `driver_${userInfo?.driverFleet?.id}` : null,
    [userInfo?.driverFleet?.id],
  )

  const handleTruckEvent = useCallback(() => {
    refetchUserInfo()
    toast.success('New truck info')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleLoadEvent = useCallback(
    data => {
      if (
        data?.load?.status === ELoadStatus.deliveryComplete &&
        loadData.id === data?.load?.id
      ) {
        updateDriverFleetLoads(null)
        return
      }
      if (loadData) {
        if (loadData.id === data?.load?.id) {
          if (data?.load?.status === ELoadStatus.deliveryComplete) {
            updateDriverFleetLoads(null)
          } else {
            updateLoad(data.load.id, data.load)
          }
        }
      } else if (data?.load?.id) {
        updateDriverFleetLoads(data?.load?.id)
      }
    },
    [loadData, updateDriverFleetLoads, updateLoad],
  )

  const updateDriverFleetLoads = useCallback(
    loadId => {
      updateDriverFleet({ loadId })
    },
    [updateDriverFleet],
  )

  const events = useMemo(
    () => [
      {
        name: 'truck_change',
        handler: handleTruckEvent,
      },
      {
        name: 'load',
        handler: handleLoadEvent,
      },
    ],
    [handleTruckEvent, handleLoadEvent],
  )

  usePusher({
    channelName,
    events,
    canRegisterEvents: isLoadsDataFetched || isUserInfoFetched,
  })

  return {}
}

export default usePusherDriver
