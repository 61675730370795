import React, { useEffect, useState } from 'react'
import ReactNumberFormat, { NumberFormatValues } from 'react-number-format'
import type {
  RTCell,
  IReusableTableRowData,
  ReusableTableInstance,
} from '../../../types'

import './styles.scss'
import CustomInput from './CustomInput'
import clsx from 'clsx'
import _ from 'lodash'
import isNumber from '~/utils/isNumber'

export interface IRTEditCellNumberFieldProps<
  TData extends IReusableTableRowData,
> {
  cell: RTCell<TData>
  table: ReusableTableInstance<TData>
  prefix?: string
  className?: string
}

const RTEditCellNumberField = <TData extends IReusableTableRowData>(
  props: IRTEditCellNumberFieldProps<TData>,
) => {
  const { cell, table, prefix, className } = props

  const {
    getState,
    options: { onCellEditEnd },
    refs: { editInputRefs },
    setCreatingRow,
    setEditingCell,
    setEditingRow,
  } = table

  const { column, row } = cell
  const { creatingRow, editingRow } = getState()

  const isCreating = creatingRow?.id === row.id
  const isEditing = editingRow?.id === row.id

  const cellValue = cell.getValue<any>()

  const [value, setValue] = useState(() => cell.getValue<string>())

  const numberFieldProps =
    typeof column.columnDef.editNumberFieldProps === 'function'
      ? column.columnDef.editNumberFieldProps({ row, cell, table, column })
      : {}

  const saveInputValueToRowCache = (newValue: string) => {
    //@ts-ignore
    row._valuesCache[column.id] = newValue
    if (isCreating) {
      setCreatingRow(row)
    } else if (isEditing) {
      setEditingRow(row)
    }
  }

  const handleChange = ({ value }: NumberFormatValues) => {
    setValue(value)
  }

  const handleBlur = () => {
    if (value !== cell.getValue<string>()) {
      saveInputValueToRowCache(value)
      setEditingCell(null)
      onCellEditEnd && onCellEditEnd(value, cell)
    }
  }

  const handleEnterKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      ;(editInputRefs as any).current[column.id]?.onBlur()
    }
  }

  useEffect(() => {
    setValue(cellValue)
  }, [cellValue])

  return (
    <ReactNumberFormat
      prefix={prefix}
      {..._.omit(numberFieldProps, ['error'])}
      className={clsx(
        'RTEditCellNumberField__container',
        column.columnDef.align,
        className,
      )}
      ref={inputRef => {
        if (inputRef) {
          ;(editInputRefs as any).current[column.id] = inputRef
        }
      }}
      value={isNumber(value) ? Number(value) : ''}
      customInput={CustomInput}
      onValueChange={handleChange}
      onBlur={handleBlur}
      onKeyDown={handleEnterKeyDown}
    />
  )
}

export default RTEditCellNumberField
