import { createSelector } from 'reselect'

const selectBillLineState = state => state.data.billLines
const invoiceIndex = state => state.data.invoices
const selectedInvoiceId = state => state.ui.invoice.currentInvoiceId
const compareInvoiceId = state => state.ui.invoice.compareTableInvoiceId
const selectBillLineUi = state => state.ui.billLines

export const selectBillLinesColumns = createSelector(
  selectBillLineUi,
  selectBillLineUi => selectBillLineUi.columns,
)

export const selectBillLines = createSelector(
  [selectBillLineState, invoiceIndex, selectedInvoiceId],

  (billLines, invoices, id) => {
    if (
      Object.keys(billLines).length === 0 ||
      Object.keys(invoices).length === 0 ||
      !id
    ) {
      return []
    }
    const invoice = invoices[id]

    return Object.entries(billLines)
      .map(([, value]) => value)
      .filter(line => line.invoiceId == invoice.id)
  },
)

export const selectCompareTableBillLines = createSelector(
  [selectBillLineState, invoiceIndex, compareInvoiceId],

  (billLines, invoices, id) => {
    if (Object.keys(billLines).length === 0 || invoices.length === 0 || !id) {
      return []
    }
    const invoice = invoices[id]

    return Object.entries(billLines)
      .map(([, value]) => value)
      .filter(line => line.invoiceId == invoice.id)
  },
)

export const selectBillLinesFilters = createSelector(
  selectBillLineUi,
  state => state.filters,
)
