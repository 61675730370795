import { useState, useMemo, useCallback, useEffect } from 'react'
import { useRouter } from '~/hooks/useRouter'
import useTableCompanyEmailParserSubTab from './useTableCompanyEmailParserSubTab'
import { useWindowSize } from 'react-use'
import { useConfirmationProvider } from '~/contexts'

import {
  DeleteIcon,
  DialogEmailParserForm,
  EditIcon,
  ReusableTable,
} from '~/components/shared'

import _ from 'lodash'
import { produce } from 'immer'
import { moduleName } from '~/utils/constants'
import { toast } from 'react-toastify'
import { toastMessages } from '~/constants/toast-status-text'
import { apiClient } from '~/api/ApiClient'
import {
  EFieldType,
  ERTDisplayColumnId,
  EScope,
  EYesNo,
} from '~/types/enums/ECommonEnum'
import { documentTypes } from '~/hooks/useFilterOptions'
import { useQueryCompanies } from '~/hooks/useQueryData'

function CompanyEmailParserSubTab() {
  const windowSize = useWindowSize()

  const [emailParserData, setEmailParserData] = useState([])
  const [loading, setLoading] = useState(false)
  const [dialogProps, setDialogProps] = useState({
    isOpen: false,
    formData: null,
  })
  const [globalFilter, setGlobalFilter] = useState('')

  const [columnFilters, setColumnFilters] = useState([
    { id: 'type', value: EScope.buyer },
  ])

  const filterState = useMemo(() => {
    const filter = {}
    columnFilters.forEach(({ id, value }) => {
      filter[id] = value
    })
    return filter
  }, [columnFilters])

  const router = useRouter()
  const { confirmation } = useConfirmationProvider()

  const { findCompanyById, sellerCompanyOptions } = useQueryCompanies({})

  const mapEmailParsers = useMemo(() => {
    const mapSellerBuyerEmailParsers = _.map(
      emailParserData,
      ({ sellerId, buyerId, ...emailParser }) => {
        const seller = findCompanyById(sellerId)
        const buyer = findCompanyById(buyerId)

        return {
          ...emailParser,
          sellerId,
          buyerId,
          seller,
          buyer,
        }
      },
    )

    return mapSellerBuyerEmailParsers
  }, [emailParserData, findCompanyById])

  const filterOptions = [
    {
      field: 'type',
      label: 'Type',
      type: EFieldType.singleSelect,
      options: [
        {
          label: 'Buyer',
          value: EScope.buyer,
        },
        {
          label: 'Seller',
          value: EScope.seller,
        },
      ],
    },
    {
      field: 'sellerId',
      label: 'Seller',
      type: EFieldType.multipleSelect,
      options: sellerCompanyOptions,
    },
    {
      field: 'docTypeValue',
      label: 'Doc Type',
      type: EFieldType.multipleSelect,
      options: documentTypes,
    },
  ]

  const getApiCall = useCallback(() => {
    const type = filterState.type === EScope.seller ? 'sellerId' : 'buyerId'

    return apiClient.emailParsers.get({
      filters: {
        [type]: router.params.id,
        sellerId: filterState.sellerId,
        docTypeValue: filterState.docTypeValue,
        searchWord: globalFilter,
      },
    })
  }, [
    filterState.docTypeValue,
    filterState.sellerId,
    filterState.type,
    router.params.id,
    globalFilter,
  ])

  const getEmailParsers = useCallback(async () => {
    setLoading(true)
    try {
      const response = await getApiCall()
      setEmailParserData(response.emailParsers)
    } catch (error) {
      console.log('error', error)
      toast.error(toastMessages.serverError)
    } finally {
      setLoading(false)
    }
  }, [getApiCall])

  const handleDeleteRow = useCallback(
    async rowData => {
      const result = await confirmation({
        message: `Are you sure you want to delete email parser #${rowData.id}`,
      })

      if (result === EYesNo.Yes) {
        setLoading(true)
        try {
          const { errors } = await apiClient.emailParsers.delete(rowData.id)
          if (_.size(errors) > 0) {
            toast.error(toastMessages.deleteError)
          } else {
            setEmailParserData(prev =>
              produce(prev, draft => {
                const index = _.findIndex(draft, { id: rowData.id })
                if (index > -1) {
                  draft.splice(index, 1)
                  toast.success(toastMessages.deleteSuccess)
                } else {
                  toast.error(toastMessages.deleteError)
                }
              }),
            )
          }
        } catch (error) {
          console.log('error', error)
          toast.error(toastMessages.deleteError)
        } finally {
          setLoading(false)
        }
      }
    },
    [confirmation],
  )

  const handleOpenUpdateForm = useCallback(rowData => {
    setDialogProps({
      isOpen: true,
      formData: rowData,
    })
  }, [])

  const onCloseDialog = useCallback(() => {
    setDialogProps({
      isOpen: false,
      formData: null,
    })
  }, [])

  const afterCreateEmailParser = useCallback(
    async formValues => {
      setEmailParserData(prev => [...prev, formValues])
      onCloseDialog()
    },
    [onCloseDialog],
  )

  const afterUpdateEmailParser = useCallback(
    async updatedItem => {
      setEmailParserData(prev =>
        produce(prev, draft => {
          const index = _.findIndex(draft, { id: updatedItem.id })
          if (index > -1) {
            draft[index] = {
              ...draft[index],
              ...updatedItem,
            }
          }
        }),
      )
      onCloseDialog()
    },
    [onCloseDialog],
  )
  const handleEditCell = useCallback(async (value, cell) => {
    try {
      const { column, row } = cell
      const formValues = { [column.id]: value }
      const { errors, ...response } = await apiClient.emailParsers.update(
        row.original.id,
        formValues,
      )
      if (_.size(errors) > 0) {
        toast.error(toastMessages.updateError)
      } else {
        setEmailParserData(prev =>
          produce(prev, draft => {
            const index = _.findIndex(draft, { id: row.original.id })
            if (index > -1) {
              draft[index] = {
                ...draft[index],
                ...response,
              }
            }
          }),
        )
        toast.success(toastMessages.updateSuccess)
      }
    } catch (error) {
      console.log('error', error)
      toast.error(toastMessages.updateError)
    }
  }, [])

  const onToggleForm = useCallback(() => {
    setDialogProps(prev => ({
      isOpen: !prev.isOpen,
      formData: null,
    }))
  }, [])

  const { columns } = useTableCompanyEmailParserSubTab()

  useEffect(() => {
    getEmailParsers()
  }, [getEmailParsers])

  return (
    <>
      <div style={{ margin: 12 }}>
        <ReusableTable
          enableTopToolbar
          enableCompanyView
          manualFiltering
          filterOptions={filterOptions}
          enableRowActions
          companyViewProps={{
            name: moduleName.company.emailParsers,
            description:
              'Manage the rules we use to extract data from document emails.',
            onClickTopButton: onToggleForm,
          }}
          columns={columns}
          data={mapEmailParsers}
          tableHeight={windowSize.height - 220}
          onColumnFiltersChange={setColumnFilters}
          state={{
            isLoading: loading,
            columnFilters,
            globalFilter,
          }}
          initialState={{
            columnPinning: {
              left: [ERTDisplayColumnId.actions],
            },
          }}
          displayColumnDefOptions={{
            [ERTDisplayColumnId.actions]: {
              size: 80,
            },
          }}
          onCellEditEnd={handleEditCell}
          onGlobalFilterChange={setGlobalFilter}
          renderRowActions={({ row }) => [
            {
              icon: <EditIcon color='white' />,
              tooltipProps: {
                placement: 'top',
                content: 'Edit',
              },
              onClick() {
                handleOpenUpdateForm(row.original)
              },
            },
            {
              icon: <DeleteIcon color='white' />,
              color: 'danger',
              tooltipProps: {
                placement: 'top',
                content: 'Delete',
              },
              onClick() {
                handleDeleteRow(row.original)
              },
            },
          ]}
        />
      </div>
      <DialogEmailParserForm
        isOpen={dialogProps.isOpen}
        formData={dialogProps.formData}
        onClose={onCloseDialog}
        afterCreate={afterCreateEmailParser}
        afterUpdate={afterUpdateEmailParser}
      />
    </>
  )
}

CompanyEmailParserSubTab.propTypes = {}

export default CompanyEmailParserSubTab
