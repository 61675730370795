import { useMemo, useCallback, useState } from 'react'
import { useSelector } from 'react-redux'

import { ListGroup } from 'react-bootstrap'
import { IonIcon } from '@ionic/react'

import { apiClient } from '~/api/ApiClient'
import { toast } from 'react-toastify'
import { toastMessages } from '~/constants/toast-status-text'
import { selectUsersDriverFleet } from '~/redux/selectors'

import type { ILoadStatusModalProps } from './type'
import type { IDriverFleet } from '~/types/models/IDriverFleet'
import useGeolocation from '~/hooks/useGeolocation'
import { ILoadStatus } from '~/types/models/ILoad'
import useQueryLoadStatuses from '~/hooks/useQueryData/useQueryLoadStatuses/useQueryLoadStatuses'
import { useCustomQueryDataOfDriver } from '~/hooks/useQueryData'
import { ELoadStatus } from '~/types/enums/ELoadStatus'
import * as IonIcons from 'ionicons/icons'

const useLoadStatusModal = (props: ILoadStatusModalProps) => {
  const { isOpen, onClose, loadData, onChangeLoadStatus } = props

  const [isUpdating, setIsUpdating] = useState(false)
  const [backendError, setBackendError] = useState('')

  const driverFleet: IDriverFleet = useSelector(selectUsersDriverFleet)

  const { locationAttributes } = useGeolocation()
  const { getLoadStatus, getNextLoadSection } = useQueryLoadStatuses()

  const { userInfo, updateDriverFleet } = useCustomQueryDataOfDriver()

  const onClickStatusItem = useCallback(
    (status: string) => async () => {
      setBackendError('')
      try {
        setIsUpdating(true)
        const response = await apiClient.loadTimes.create({
          loadStatusCode: getLoadStatus(status)?.code,
          loadTime: {
            loadId: loadData.id,
            loadStatusCode: getLoadStatus(status)?.code,
            time: new Date().toISOString(),
            breadcrumbLoadAttributes: {
              eventType: status,
              loadId: loadData.id,
              driverFleetId: driverFleet.id,
              truckFleetId:
                loadData.truckFleetId ||
                (driverFleet.currentTruckFleetId as number),
              locationAttributes: {
                eventType: status,
                source: 'Concord',
                lat: locationAttributes?.lat as number,
                lng: locationAttributes?.lng as number,
                time: locationAttributes?.time as string,
              },
            },
          },
        } as any)

        if (response?.errors?.length) {
          setBackendError(response?.errors[0])
        } else {
          apiClient.driverFleets.update(
            userInfo?.driverFleet?.id as number,
            {
              lastStatusTime: new Date().toISOString(),
            } as any,
          )

          if (status === ELoadStatus.deliveryComplete) {
            updateDriverFleet({
              loadId: null,
              lastStatusTime: new Date().toISOString(),
            })
          }
          onClose && onClose()
          onChangeLoadStatus &&
            onChangeLoadStatus(
              { ...loadData, status: status as ILoadStatus },
              status,
            )
          toast.success(toastMessages.updateSuccess)
        }
      } catch (error) {
        console.log('error', error)
        toast.error(toastMessages.updateError)
      } finally {
        setIsUpdating(false)
      }
    },
    [
      driverFleet?.currentTruckFleetId,
      driverFleet?.id,
      getLoadStatus,
      loadData,
      locationAttributes?.lat,
      locationAttributes?.lng,
      locationAttributes?.time,
      onChangeLoadStatus,
      onClose,
      userInfo?.driverFleet?.id,
      updateDriverFleet,
    ],
  )

  const renderStatuses = useMemo(() => {
    const nextSection = getNextLoadSection(loadData.status)
    if (nextSection) {
      return [nextSection].map((status, index) => (
        <ListGroup.Item
          key={index}
          action
          className='LoadStatusModal__statusItem'
          active={loadData.status === status.name}
          onClick={onClickStatusItem(status.name)}
        >
          <span className='icon'>
            {status.icon && <IonIcon icon={(IonIcons as any)[status.icon]} />}
          </span>
          <span>
            {status.code} {status.name}
          </span>
        </ListGroup.Item>
      ))
    }
    return null
  }, [getNextLoadSection, loadData.status, onClickStatusItem])

  return {
    isOpen,
    onClose,
    renderStatuses,
    isUpdating,
    backendError,
  }
}

export default useLoadStatusModal
