import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { CheckComponentsForWorker } from '../CheckComponentsForWorker'
import NotFoundPage from '~/containers/NotFoundPage'

import { selectMyCurrentCompany, selectWorkerDetails } from '~/redux/selectors'

import { IWorker } from '~/types/models/IWorker'
import { ICompany } from '~/types/models/ICompany'

function DriverPayrolls() {
  const workerDetails: IWorker = useSelector(selectWorkerDetails)
  const currentCompany: ICompany = useSelector(selectMyCurrentCompany)

  const checkUid = useMemo(
    () => workerDetails.checkUid,
    [workerDetails.checkUid],
  )

  if (checkUid && currentCompany?.checkUid) {
    return <CheckComponentsForWorker workerUid={checkUid} />
  }

  return (
    <NotFoundPage
      message={
        <div>
          <div>Your company has not set up data for payrolls yet.</div>
          <div>
            <span>Please contact your company for more details</span>
          </div>
        </div>
      }
      onGoBack={() => {
        window.location.reload()
      }}
      buttonText='Reload Page'
    />
  )
}

export default DriverPayrolls
