import { createSelector } from 'reselect'
import { columnHashToColumnsArray } from '~/utils/userTableConfigurationUtils'

export const selectBuyerSellerProducts = state => state.data.buyerSellerProducts

export const selectBuyerSellerProductOptions = createSelector(
  selectBuyerSellerProducts,
  buyerSellerProducts => {
    const bspArray = columnHashToColumnsArray(buyerSellerProducts)

    const newArray = bspArray
      .map(bsp => {
        return {
          value: bsp.id,
          label: bsp.name,
          code: bsp.code,
          product_group_id: bsp.productGroupId,
          buyerSellerId: bsp.buyerSellerId,
          type: 'buyerSellerProductId',
        }
      })
      .sort((a, b) => (a.label || '').localeCompare(b.label))

    return newArray
  },
)

export const selectBuyerSellerProductsInfo = createSelector(
  selectBuyerSellerProducts,
  buyerSellerProducts => {
    const bspArray = columnHashToColumnsArray(buyerSellerProducts)
    const newArray = bspArray
      .map(bsp => bsp)
      .sort((a, b) => a?.name?.localeCompare(b?.name))

    return newArray
  },
)

export const selectCommonBuyerSellerProducts = createSelector(
  selectBuyerSellerProducts,
  buyerSellerProducts => columnHashToColumnsArray(buyerSellerProducts),
)
