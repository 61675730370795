import { createSelector } from 'reselect'
import { flagHashToFlagableIds, deepCopy, addFlags } from '~/utils/utils'

export const selectInvoicesDataState = state => state.data.invoices
const selectInvoicesUIState = state => state.ui.invoices
const selectFlags = state => state.data.flags

export const selectInvoicesFilters = createSelector(
  selectInvoicesUIState,
  invoices => invoices.control.filters,
)

export const selectInvoicesSearchKey = createSelector(
  selectInvoicesUIState,
  invoices => invoices.control.searchKey,
)

export const selectInvoicesSortOptions = createSelector(
  selectInvoicesUIState,
  invoices => invoices.control.sortOptions,
)

export const selectInvoicesUI = createSelector(
  selectInvoicesUIState,
  invoicesUI => invoicesUI,
)

export const selectInvoicesControl = createSelector(
  selectInvoicesUIState,
  invoices => invoices.control,
)

export const selectInvoicesColumns = createSelector(
  selectInvoicesUIState,
  invoicesUiState =>
    invoicesUiState.control.columns
      .filter(c => c.hideable)
      .sort((a, b) => a.displayIndex - b.displayIndex),
)

export const selectInvoicesSortables = createSelector(
  selectInvoicesUIState,
  invoices =>
    invoices.control.columns
      .filter(c => c.sortable)
      .sort((a, b) => a.sortIndex - b.sortIndex),
)

export const selectInvoicesHash = createSelector(
  selectInvoicesDataState,
  selectFlags,

  (invoices, flags) => {
    const newArray = Object.values(invoices)
    const flagableIds = flagHashToFlagableIds(flags)

    const invoicesWithFlags = addFlags(deepCopy(newArray), flagableIds)

    return invoicesWithFlags
  },
)

export const selectInvoicesLoading = createSelector(
  selectInvoicesUIState,
  state => state.loading,
)

export const selectCompareInvoicesControl = createSelector(
  selectInvoicesUIState,

  uiState => uiState.compareInvoiceControl,
)

export const selectInvoicesFiltersInitialLoad = createSelector(
  selectInvoicesUIState,

  uiState => uiState.filtersInitialLoad,
)

export const selectInvoicesIsFiltersConfigured = createSelector(
  selectInvoicesUIState,

  uiState => uiState.isFiltersConfigured,
)

export const selectInvoicesIsFiltersInitialized = createSelector(
  selectInvoicesUIState,

  uiState => uiState.isFiltersInitialized,
)
