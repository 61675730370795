import { EColor } from '~/types/enums/ECommonEnum'

const COLORS = ['#e5e5e5', EColor.yellow, EColor.green, EColor.orange]

function getGroupingColor(level: number) {
  if (level === 0) {
    return null
  }
  const index = (level - 1) % COLORS.length
  return COLORS[index]
}
export default getGroupingColor
