import { PropsWithChildren, useCallback, useMemo, useState } from 'react'

import {
  CommonDialogV2,
  CompanyAvatar,
  TruckNumberSection,
} from '~/components/shared'
import LoadFormContext from './LoadFormContext'
import LoadForm from '~/components/order/LoadForm'
import { ILoadFormProviderSettingProps, ILoadFormProviderValues } from './type'
import { When } from 'react-if'
import {
  useQueryTerminals,
  useQueryTruckFleets,
  useQueryUsers,
} from '~/hooks/useQueryData'
import { IonChip } from '@ionic/react'
import { colord } from 'colord'

function LoadFormProvider(props: PropsWithChildren) {
  const { children } = props

  const [isOpenForm, setIsOpenForm] = useState(false)
  const [header, setHeader] = useState('New Load')
  const [truckFleetId, setTruckFleetId] = useState<number | null>(null)
  const [settingProps, setSettingProps] =
    useState<ILoadFormProviderSettingProps>({
      driverFleetId: null,
      defaultSchedule: null,
    })

  const { findUserByDriverFleetId } = useQueryUsers()
  const { findTruckFleetById } = useQueryTruckFleets(
    {},
    {
      enabled: isOpenForm,
    },
  )
  const { findTerminalById } = useQueryTerminals()

  const truckFleet = findTruckFleetById(truckFleetId)
  const truckFleetTerminal = findTerminalById(truckFleet?.terminalId)

  const driverFleet = useMemo(() => {
    if (settingProps.driverFleetId) {
      return findUserByDriverFleetId(settingProps.driverFleetId)?.driverFleet
    }

    return null
  }, [findUserByDriverFleetId, settingProps.driverFleetId])

  const onOpenLoadFormDialog = useCallback(
    (settingProps: ILoadFormProviderSettingProps) => {
      console.log('settingProps', settingProps)
      setSettingProps(settingProps)
      setIsOpenForm(true)
    },
    [],
  )

  const onCloseLoadFormDialog = useCallback(() => {
    setSettingProps({
      driverFleetId: null,
      defaultSchedule: null,
    })
    setIsOpenForm(false)
  }, [])

  const providerValue = useMemo<ILoadFormProviderValues>(
    () => ({
      onOpenLoadFormDialog,
      onCloseLoadFormDialog,
    }),
    [onCloseLoadFormDialog, onOpenLoadFormDialog],
  )

  return (
    <>
      <LoadFormContext.Provider value={providerValue}>
        {children}
      </LoadFormContext.Provider>
      <CommonDialogV2
        isOpen={isOpenForm}
        onClose={onCloseLoadFormDialog}
        isHiddenOkButton
        title={
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ marginRight: 4 }}>{header}</div>
            {truckFleet && (
              <IonChip
                style={{
                  background: truckFleet?.color || 'var(--ion-color-fleet)',
                }}
              >
                {truckFleetTerminal && (
                  <div style={{ marginRight: 4 }}>
                    <CompanyAvatar
                      company={{
                        value: truckFleetTerminal.id as number,
                        label: truckFleetTerminal.name || '-',
                      }}
                      color={truckFleetTerminal.color || 'black'}
                      height={18}
                      width={18}
                      tooltipMessage={
                        'Next pickup terminal: ' + truckFleetTerminal?.name
                      }
                    />
                  </div>
                )}
                <span
                  style={{
                    color: truckFleet?.color
                      ? colord(truckFleet.color).isDark()
                        ? 'white'
                        : 'black'
                      : '#e5e5e5',
                  }}
                >
                  <TruckNumberSection truckId={truckFleet?.truckId} />
                </span>
              </IonChip>
            )}
          </div>
        }
        size='lg'
      >
        <When condition={Boolean(driverFleet)}>
          <LoadForm
            defaultSchedule={settingProps.defaultSchedule}
            driverFleet={driverFleet}
            setShow={() => {
              onCloseLoadFormDialog()
            }}
            onTogglePrintLoad={setHeader}
            isHiddenHeader
            onChangeTruckFleetId={(truckFleetId: number | null) => {
              setTruckFleetId(truckFleetId)
            }}
            canEditLoad={settingProps.canEditLoad}
            afterUpdateLoad={settingProps.afterUpdateLoad}
          />
        </When>
      </CommonDialogV2>
    </>
  )
}

export default LoadFormProvider
