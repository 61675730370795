import { createSlice } from '@reduxjs/toolkit'
import moment from 'moment'
import { ORDER_TRACKING_SORT_OPTIONS } from '~/containers/OrderTracking/Utils/constants'
import { EScheduleView } from '~/types/enums/ESchedule'

export const orderTrackingSlice = createSlice({
  name: 'orderTracking',
  initialState: {
    columnsDisplayed: [
      {
        label: 'Order Detail',
        field: 'id',
        show: true,
      },
      {
        label: 'To Seller',
        field: 'to_seller',
        show: true,
      },
      {
        label: 'At Seller',
        field: 'at_seller',
        show: true,
      },
      {
        label: 'To Buyer',
        field: 'to_buyer',
        show: true,
      },
      {
        label: 'At Buyer',
        field: 'at_buyer',
        show: true,
      },
      {
        label: 'Returning',
        field: 'returning',
        show: true,
      },
    ],
    filters: {
      startTime: {
        startDate: moment().startOf('day').toDate(),
        endDate: moment().endOf('day').toDate(),
      },
      buyerTerminalId: [],
      sellerTerminalId: [],
      fleetId: [],
      buyerId: [],
      sellerId: [],
      status: [],
      qty: {},
      plus: [],
      sellerProductId: [],
      scheduleNumber: {},
      color: [],
      spacing: {},
      view: EScheduleView.leftOrderRightDeliveries,
    },
    searchWord: '',
    sortOptions: ORDER_TRACKING_SORT_OPTIONS,
    showOnlyOrdersWithLoads: false,
    isMilitaryTime: true,
    filtersLite: {
      startTime: {
        startDate: moment().startOf('day').toDate(),
        endDate: moment().endOf('day').toDate(),
      },
      buyerTerminalId: [],
      sellerTerminalId: [],
      fleetId: [],
      buyerId: [],
      sellerId: [],
      status: [],
      qty: {},
      plus: [],
      sellerProductId: [],
      scheduleNumber: {},
      color: [],
      spacing: {},
      view: EScheduleView.leftOrderRightDeliveries,
    },
  },
  reducers: {
    updateUi: (state, action) => {
      for (const [key, value] of Object.entries(action.payload)) {
        state[`${key}`] = value
      }
    },
    updateUiFilter: (state, action) => {
      for (const [key, value] of Object.entries(action.payload)) {
        state['filters'][`${key}`] = value
      }
    },
    updateUiSortOptions: (state, action) => {
      for (const [key, value] of Object.entries(action.payload)) {
        state.sortOptions[key] = value
      }
    },
    updateUiFiltersLite: (state, { payload }) => {
      for (const [key, value] of Object.entries(payload)) {
        state.filtersLite[key] = value
      }
    },
  },
})

export default orderTrackingSlice
