import React, { useCallback, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { IonCol, IonGrid, IonIcon, IonRow } from '@ionic/react'
import { yupResolver } from '@hookform/resolvers/yup'
import { Alert } from 'react-bootstrap'
import { toast } from 'react-toastify'

import {
  useQueryCompanies,
  useQueryProductGroups,
  useQueryUoms,
} from '~/hooks/useQueryData'

import {
  CommonTab,
  ConcordTextFieldWithFormControl,
  ConcordDropdownV2WithFormControl,
  ConcordCheckboxWithFormControl,
  ConcordRadioWithFormControl,
  Button,
  BundleFormWithBadges,
  MixDetailForm,
  DialogProductGroupForm,
} from '~/components/shared'

import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

import * as Yup from 'yup'
import { EFieldType, EStatus } from '~/types/enums/ECommonEnum'
import {
  PRODUCT_MATERIAL_TYPE_OPTIONS,
  PRODUCT_ORDER_TYPE_OPTIONS,
  PRODUCT_QTY_TYPE_OPTIONS,
  PRODUCT_USAGE_OPTIONS,
  STATUS_LOWER_OPTIONS,
} from '~/utils/constants'
import { ISellerProductFormValues } from '~/types/models/ISellerProduct'
import {
  EQtyType,
  ESellerProductMaterialType,
} from '~/types/enums/ESellerProduct'

import type { ISellerProductFormProps } from './type'
import { apiClient } from '~/api/ApiClient'
import { toastMessages } from '~/constants/toast-status-text'
import { KeyValuePairItem } from './KeyValuePairItem'
import { IMixDetail } from '~/types/models/IMixDetail'
import { addCircleOutline, closeCircle } from 'ionicons/icons'
import { useDeepCompareEffect } from 'react-use'
import { ICommonOption } from '~/types/models/ICommonModel'
import { TestResultsList } from './TestResultsList/TestResultsList'

import './styles.scss'

const SellerProductForm = React.forwardRef<any, ISellerProductFormProps>(
  props => {
    const {
      afterCreate,
      afterUpdate,
      afterCreateMixDetail,
      afterUpdateMixDetail,
      formData,
      isShowFormFields = false,
      mixDetailData,
    } = props

    const [isLoading, setIsLoading] = useState(false)
    const [mixDetailForm, setMixDetailForm] = useState({
      isOpen: false,
      formData: undefined as IMixDetail | undefined,
    })
    const [backendError, setBackendError] = useState('')

    const { sellerCompanyOptions, isLoadingCompaniesData } = useQueryCompanies(
      {},
    )
    const { uomOptions, findUomById } = useQueryUoms()

    const schema = useMemo(
      () =>
        Yup.object({
          code: Yup.string().required('Code is required'),
          name: Yup.string().required('Name is required!'),
          sellerId: Yup.number()
            .required('Seller is required!')
            .typeError('Seller is required!'),
          qtyType: Yup.string()
            .required('Qty type is required!')
            .typeError('Qty type is required!'),
          orderType: Yup.string()
            .required('Order type is required!')
            .typeError('Order type is required!'),
          materialType: Yup.string()
            .required('Material type is required!')
            .typeError('Material type is required!'),
        }),
      [],
    )

    const { control, reset, handleSubmit, watch, setValue } =
      useForm<ISellerProductFormValues>({
        defaultValues: {
          rank: null,
          buyerId: null,
          code: '',
          name: '',
          status: EStatus.Active.toLowerCase() as any,
          sellerId: null,
          productGroupId: null,
          productUsage: null,
          standardProduct: false,
          orderType: null,
          materialType: null,
          qtyType: EQtyType.per_qty,
          allowFromAllTerminals: false,
          uomId: null,
        },
        resolver: yupResolver(schema),
      })

    const watchId = watch('id', undefined)
    const watchMaterialType = watch('materialType', null)
    const watchProductGroupId = watch('productGroupId', null)
    const watchUomId = watch('uomId', null)

    const currentUomSelected = findUomById(watchUomId)

    const isUpdating = useMemo(() => Boolean(watchId), [watchId])
    const isBundleMaterial =
      watchMaterialType === ESellerProductMaterialType.bundled_material

    const qtyTypeOptions = [
      {
        label: currentUomSelected
          ? `Per qty ${`(${currentUomSelected.name})`}`
          : 'Per qty',
        value: EQtyType.per_qty,
      },
      {
        label: 'Flat fee',
        value: EQtyType.per_order,
      },
      {
        label: 'Per load',
        value: EQtyType.per_load,
      },
    ]

    const {
      productGroupOptions,
      addProductGroup,
      updateProductGroup,
      deleteProductGroup,
      findProductGroupById,
    } = useQueryProductGroups()

    const handleSubmitForm = handleSubmit(async formValues => {
      setIsLoading(true)
      setBackendError('')
      try {
        if (isUpdating) {
          await onUpdate(formValues)
        } else {
          await onCreate(formValues)
        }
      } catch (error: any) {
        console.log('error', error)
        getErrorFromBackend(error.errors)
      } finally {
        setIsLoading(false)
      }
    })

    const renderFormFields = (
      <IonRow>
        <IonCol size='6'>
          <ConcordTextFieldWithFormControl
            name='code'
            label='Code'
            isRequired
            control={control}
          />
        </IonCol>
        <IonCol size='6'>
          <ConcordTextFieldWithFormControl
            name='name'
            label='Name'
            isRequired
            control={control}
          />
        </IonCol>

        <IonCol size='6'>
          <ConcordDropdownV2WithFormControl
            name='sellerId'
            label='Seller'
            isLoading={isLoadingCompaniesData}
            isRequired
            control={control}
            options={sellerCompanyOptions}
          />
        </IonCol>

        <IonCol size='6'>
          <ConcordDropdownV2WithFormControl
            name='materialType'
            label='Material type'
            control={control}
            options={PRODUCT_MATERIAL_TYPE_OPTIONS}
            isRequired
          />
        </IonCol>

        <IonCol size='6'>
          <ConcordDropdownV2WithFormControl
            name='uomId'
            label='Uom'
            control={control}
            options={uomOptions}
            isRequired
          />
        </IonCol>

        <IonCol size='6'>
          <ConcordDropdownV2WithFormControl
            name='qtyType'
            label='Qty type'
            control={control}
            options={qtyTypeOptions}
            isRequired
          />
        </IonCol>

        <IonCol size='4'>
          <ConcordDropdownV2WithFormControl
            name='orderType'
            label='Order type'
            control={control}
            options={PRODUCT_ORDER_TYPE_OPTIONS}
            isRequired
          />
        </IonCol>

        <IonCol size='4'>
          <ConcordDropdownV2WithFormControl
            name='productUsage'
            label='Product usage'
            control={control}
            options={PRODUCT_USAGE_OPTIONS}
          />
        </IonCol>

        <IonCol size='4'>
          <ConcordDropdownV2WithFormControl
            name='productGroupId'
            label='Group'
            control={control}
            options={productGroupOptions}
            showCreateButton
            showUpdateButtons
            showDeleteButtons
            onDeleteOption={(option: ICommonOption) => {
              apiClient.productGroups.delete(option.value)
              deleteProductGroup(option.value)
              if (watchProductGroupId === option.value) {
                setValue('productGroupId', null)
              }
            }}
            renderForm={({ isOpen, setFormState, optionSelected }: any) => {
              const formData = findProductGroupById(optionSelected?.value)

              return (
                <DialogProductGroupForm
                  isOpen={isOpen}
                  formData={formData}
                  onClose={() => {
                    setFormState({ isOpen: false, optionSelected: null })
                  }}
                  afterCreate={item => {
                    addProductGroup(item)
                    setFormState({ isOpen: false, optionSelected: null })
                    setValue('productGroupId', item.id)
                  }}
                  afterUpdate={item => {
                    updateProductGroup(item)
                    setFormState({ isOpen: false, optionSelected: null })
                  }}
                />
              )
            }}
          />
        </IonCol>

        <IonCol size='6'>
          <ConcordCheckboxWithFormControl
            name='standardProduct'
            label='Standard product'
            control={control}
          />
        </IonCol>

        <IonCol size='6'>
          <ConcordRadioWithFormControl
            name='status'
            label='Status'
            control={control}
            options={STATUS_LOWER_OPTIONS}
          />
        </IonCol>

        <IonCol size='12'>
          <ConcordCheckboxWithFormControl
            name='allowFromAllTerminals'
            label='Allow From All Terminals'
            control={control}
          />
        </IonCol>
        <IonCol>
          <Button
            label={isUpdating ? 'Update' : 'Create'}
            expand='full'
            onClick={handleSubmitForm}
            loading={isLoading}
          />
        </IonCol>
      </IonRow>
    )

    const onUpdateSellerProduct = useCallback(
      async (name: string, newValue: any) => {
        const { errors, ...response } = await apiClient.sellerProducts.update(
          formData?.id as number,
          {
            [name]: newValue,
          } as any,
        )
        afterUpdate && afterUpdate(response as any, true)
        toast.success(toastMessages.updateSuccess)
      },
      [afterUpdate, formData?.id],
    )

    const onUpdateMixDetail = useCallback(
      async (name: string, newValue: any) => {
        const { errors, ...response } = await apiClient.mixDetails.update(
          mixDetailData?.id as number,
          {
            mixDetail: {
              [name]: newValue,
            },
          },
        )
        afterUpdateMixDetail && afterUpdateMixDetail(response)
        toast.success(toastMessages.updateSuccess)
      },
      [afterUpdateMixDetail, mixDetailData?.id],
    )

    const renderKeyValuePairs = useCallback(() => {
      const keyValuePairs = [
        {
          label: 'Material Type',
          name: 'materialType',
          options: PRODUCT_MATERIAL_TYPE_OPTIONS,
          type: EFieldType.singleSelect,
        },
        {
          label: 'Qty Type',
          name: 'qtyType',
          options: PRODUCT_QTY_TYPE_OPTIONS,
          type: EFieldType.singleSelect,
        },
        {
          label: 'Product Usage',
          name: 'productUsage',
          options: PRODUCT_USAGE_OPTIONS,
          type: EFieldType.singleSelect,
        },
        {
          label: 'Product Group',
          name: 'productGroupId',
          options: productGroupOptions,
          type: EFieldType.singleSelect,
        },
        {
          label: 'Standard Product',
          name: 'standardProduct',
          type: EFieldType.checkbox,
        },
        {
          label: 'Allow From All Terminals',
          name: 'allowFromAllTerminals',
          type: EFieldType.checkbox,
        },
        {
          label: 'Uom',
          name: 'uomId',
          options: uomOptions,
          type: EFieldType.singleSelect,
        },
        {
          label: 'Status',
          name: 'status',
          options: STATUS_LOWER_OPTIONS,
          type: EFieldType.singleSelect,
        },
      ]

      return (
        <IonGrid className='SellerProductForm__keyValuePair'>
          <IonRow>
            {keyValuePairs.map(({ label, name, type, options }) => (
              <IonCol size='6' key={name}>
                <KeyValuePairItem
                  label={label}
                  type={type}
                  value={(formData as any)?.[name]}
                  options={options}
                  name={name}
                  onUpdate={onUpdateSellerProduct}
                />
              </IonCol>
            ))}
          </IonRow>
        </IonGrid>
      )
    }, [productGroupOptions, uomOptions, formData, onUpdateSellerProduct])

    const onToggleMixDetailForm = () => {
      setMixDetailForm(prev => ({
        isOpen: !prev.isOpen,
        formData: undefined,
      }))
    }

    const mixDetailsKeyValuePair = [
      {
        label: 'Strength',
        name: 'strength',
        type: EFieldType.number,
      },
      {
        label: 'Slump',
        name: 'slump',
        type: EFieldType.number,
      },
      {
        label: 'Slump Variance',
        name: 'slumpVariance',
        type: EFieldType.number,
      },
      {
        label: 'Specific Gravity',
        name: 'specificGravity',
        type: EFieldType.number,
      },
      {
        label: 'Air',
        name: 'air',
        type: EFieldType.number,
      },
      {
        label: 'Air Variance',
        name: 'airVariance',
        type: EFieldType.number,
      },
      {
        label: 'Load Temp',
        name: 'loadTemp',
        type: EFieldType.number,
      },
      {
        label: 'Min Temp',
        name: 'minTemp',
        type: EFieldType.number,
      },
      {
        label: 'Max Temp',
        name: 'maxTemp',
        type: EFieldType.number,
      },
    ]

    const renderMixDetailsSection = () => {
      if (formData?.id) {
        return (
          <div className='SellerProductForm__mixDetailSection'>
            <div className='headerContainer'>
              <span className='titleHeading'>Mix Details</span>
              {!mixDetailData && (
                <span className='icon' onClick={onToggleMixDetailForm}>
                  <IonIcon
                    icon={mixDetailForm.isOpen ? closeCircle : addCircleOutline}
                    color={mixDetailForm.isOpen ? 'danger' : 'concord'}
                  />
                </span>
              )}
            </div>

            {mixDetailForm.isOpen && (
              <MixDetailForm
                className='mixDetailFormContainer'
                isHiddenCancelButton={false}
                cancelText='Close'
                onCancel={onToggleMixDetailForm}
                sellerProductId={formData.id}
                formData={mixDetailForm.formData}
                afterCreate={newItem => {
                  onToggleMixDetailForm()
                  afterCreateMixDetail && afterCreateMixDetail(newItem)
                }}
                afterUpdate={newItem => {
                  onToggleMixDetailForm()
                  afterUpdateMixDetail && afterUpdateMixDetail(newItem)
                }}
              />
            )}

            {mixDetailData && (
              <IonGrid className='mixDetailFormContainer'>
                <IonRow>
                  {mixDetailsKeyValuePair.map(({ name, label }) => (
                    <IonCol size='4' key={name}>
                      <KeyValuePairItem
                        label={label}
                        value={(mixDetailData as any)?.[name]}
                        name={name}
                        onUpdate={onUpdateMixDetail}
                        width={45}
                      />
                    </IonCol>
                  ))}
                </IonRow>
              </IonGrid>
            )}
          </div>
        )
      }
    }

    const getErrorFromBackend = (
      errorObject: Record<string, any> | undefined,
    ) => {
      let message = ''
      if (errorObject && Object.keys(errorObject).length > 0) {
        const [firstField, firstErrors] = Object.entries(errorObject)[0]
        message = `${firstField}: ${firstErrors.join(', ')}`
      }
      setBackendError(message)
    }

    const onUpdate = useCallback(
      async (formData: ISellerProductFormValues) => {
        const payload: ISellerProductFormValues = {
          code: formData.code,
          name: formData.name,
          status: formData.status,
          sellerId: formData.sellerId,
          productGroupId: formData.productGroupId,
          productUsage: formData.productUsage,
          standardProduct: formData.standardProduct,
          orderType: formData.orderType,
          qtyType: formData.qtyType,
          materialType: formData.materialType,
          rank: null,
          buyerId: formData.buyerId,
          allowFromAllTerminals: formData.allowFromAllTerminals,
          uomId: formData.uomId,
        }
        const { errors, ...response } = await apiClient.sellerProducts.update(
          formData.id as number,
          payload,
        )
        afterUpdate && afterUpdate(response as any)
        toast.success(toastMessages.createSuccess)
      },
      [afterUpdate],
    )

    const onCreate = useCallback(
      async (formData: ISellerProductFormValues) => {
        const payload: ISellerProductFormValues = {
          code: formData.code,
          name: formData.name,
          status: formData.status,
          sellerId: formData.sellerId,
          productGroupId: formData.productGroupId,
          productUsage: formData.productUsage,
          standardProduct: formData.standardProduct,
          orderType: formData.orderType,
          qtyType: formData.qtyType,
          materialType: formData.materialType,
          rank: null,
          buyerId: formData.buyerId,
          allowFromAllTerminals: formData.allowFromAllTerminals,
          uomId: formData.uomId,
        }

        const { errors, ...response } = await apiClient.sellerProducts.create(
          payload,
        )

        if (errors.length > 0) {
          toast.error(errors[0])
        } else {
          afterCreate && afterCreate(response as any)
          toast.success(toastMessages.createSuccess)
        }
      },
      [afterCreate],
    )

    useDeepCompareEffect(() => {
      if (formData) {
        reset({
          ...formData,
          qtyType: formData?.qtyType || EQtyType.per_qty,
          status: formData?.status || 'active', //EStatus.Active.toLowerCase()
        })
      } else {
        reset()
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formData])

    return (
      <DndProvider backend={HTML5Backend}>
        <div className='SellerProductForm__container'>
          <CommonTab
            tabs={[
              {
                name: 'formValues',
                label: 'General',
                render() {
                  return (
                    <IonGrid style={{ padding: 0 }}>
                      {backendError && (
                        <IonRow>
                          <IonCol>
                            <Alert style={{ fontSize: 13 }} variant='danger'>
                              {backendError}
                            </Alert>
                          </IonCol>
                        </IonRow>
                      )}

                      {isShowFormFields ? (
                        renderFormFields
                      ) : (
                        <>
                          {renderKeyValuePairs()}
                          {renderMixDetailsSection()}
                        </>
                      )}
                    </IonGrid>
                  )
                },
              },
              {
                name: 'bundle',
                label: 'Bundles',
                isHidden: !isBundleMaterial,
                render() {
                  if (isUpdating) {
                    return (
                      <BundleFormWithBadges
                        sellerProductId={watchId as number}
                      />
                    )
                  }
                  return (
                    <Alert variant='danger' style={{ fontSize: 13 }}>
                      You need to create seller product first
                    </Alert>
                  )
                },
              },
              {
                name: 'testResult',
                label: 'Test Results',
                render: () => (
                  <TestResultsList sellerProductId={watchId as number} />
                ),
              },
            ]}
          />
        </div>
      </DndProvider>
    )
  },
)
export default SellerProductForm
