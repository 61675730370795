import { Badge } from 'react-bootstrap'
import { Control, useController } from 'react-hook-form'
import { useMemo, useState } from 'react'
import { TextToggleSelect } from './TextToggleSelect'

interface HookFormTextToggleSelectProps {
  name: string
  control: Control
  label?: string
  text?: string
  options?: { value: string | number; label: string; code?: string }[]
}

export const HookFormTextToggleSelect = ({
  name,
  control,
  label,
  text,
  options,
  ...inputProps
}: HookFormTextToggleSelectProps) => {
  const [showInput, setShowInput] = useState<boolean>(false)

  const { field } = useController({
    name,
    control,
  })

  const selectedValue = useMemo(() => {
    return options?.find(option => option.value === field.value)
  }, [field.value, options])

  return (
    <Badge onClick={() => setShowInput(!showInput)} pill>
      <TextToggleSelect
        label={label}
        text={selectedValue?.label || text}
        toggleShow={showInput}
        options={options}
        onChange={e => {
          field.onChange(e?.value)
        }}
        value={selectedValue}
        name={field.name}
        styles={{
          menu: base => ({
            ...base,
            color: 'black',
            borderWidth: '0px',
          }),
          valueContainer: base => ({
            ...base,
            padding: '0px',
          }),
          dropdownIndicator: base => ({
            ...base,
            padding: '0px',
          }),
          indicatorSeparator: base => ({
            ...base,
            display: 'none',
          }),
        }}
        {...inputProps}
      />
    </Badge>
  )
}
