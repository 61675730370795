import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import {
  ConcordDropdownV2WithFormControl,
  ConcordFormLayout,
  ConcordFormStructure,
  IConcordFormField,
  ToggleSection,
} from '~/components/shared'
import { When } from 'react-if'
import { Alert, Badge, Col, Container, Row } from 'react-bootstrap'

import _ from 'lodash'
import * as Yup from 'yup'
import { apiClient } from '~/api/ApiClient'
import { toast } from 'react-toastify'
import { toastMessages } from '~/constants/toast-status-text'
import { components } from 'react-select'

import type { IHierarchyRowFormValues } from '~/types/models/IHierarchyRow'
import type { IHierarchyRowFormProps } from './type'
import { EFieldType, EScope } from '~/types/enums/ECommonEnum'
import {
  useQueryAllowedHierarchies,
  useQueryAllowedHierarchiesNew,
  useQueryBuyerSellerProducts,
  useQueryCompanies,
  useQueryLoads,
  useQueryTerminals,
  useQueryUoms,
} from '~/hooks/useQueryData'
import { useSelector } from 'react-redux'
import { selectCurrentScope } from '~/redux/selectors'
import { IAllowedHierarchy } from '~/types/models/IAllowedHierarchy'
import { format } from 'date-fns'
import isDateInRange from '~/utils/isDateInRange'
import moment from 'moment'
import { EPricingType } from '~/types/enums/EPricing'
import buildObjectName from '~/utils/buildObjectName'
import { EQtyType } from '~/types/enums/ESellerProduct'

const HierarchyRowForm = (props: IHierarchyRowFormProps) => {
  const {
    afterCreate,
    afterUpdate,
    formData,
    hierarchyData,
    billLineData,
    invoiceData,
    ...formProps
  } = props
  const isUpdating = useMemo(() => Boolean(formData?.id), [formData?.id])

  const [error, setError] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const formRef = useRef<any>()

  const currentScope: EScope = useSelector(selectCurrentScope)
  const { allowedHierarchyOptions, isLoadingAllowedHierarchies } =
    useQueryAllowedHierarchies()

  const { ahSellerSideOptions, ahBuyerSideOptions } =
    useQueryAllowedHierarchiesNew()

  const { sellerTerminalOptions, buyerTerminalOptions, findTerminalById } =
    useQueryTerminals()
  const { sellerCompanyOptions, buyerCompanyOptions, findCompanyById } =
    useQueryCompanies({})

  const { findUomById } = useQueryUoms()

  const allowedHierarchyOpts = useMemo(() => {
    if (billLineData) {
      return _.orderBy(allowedHierarchyOptions, ['item.rank'], ['asc']).map(
        data => {
          const { sellerSideType, buyerSideType, value } = data
          let sellerLabel = ''
          let buyerLabel = ''
          if (sellerSideType === 'Seller') {
            const obj = findCompanyById(billLineData.sellerId)
            sellerLabel = `${obj?.code} (Seller)`
          } else if (sellerSideType === 'SellerTerminal') {
            const obj = findTerminalById(billLineData.sellerTerminalId)
            const company = findCompanyById(obj?.companyId)
            sellerLabel = `${company?.code} - ${obj?.code} (Seller Terminal)`
          }

          if (buyerSideType === 'Buyer') {
            const obj = findCompanyById(billLineData.buyerId)
            buyerLabel = `${obj?.code} (Buyer)`
          } else if (buyerSideType === 'BuyerTerminal') {
            const obj = findTerminalById(billLineData.buyerTerminalId)
            const company = findCompanyById(obj?.companyId)
            buyerLabel = `${company?.code} - ${obj?.code} (Buyer Terminal)`
          }

          //pricingType

          return {
            value,
            label: `${sellerLabel} → ${buyerLabel}`,
            buyerSideType,
            sellerSideType,
            item: data.item,
          }
        },
      )
    }
    return allowedHierarchyOptions
  }, [allowedHierarchyOptions, billLineData, findCompanyById, findTerminalById])

  const {
    loadsData: [loadData],
  } = useQueryLoads(
    {
      id: billLineData?.loadId,
    },
    { enabled: Boolean(billLineData?.loadId) },
  )

  const invoiceOrLoad = _.pick(invoiceData || loadData, [
    'buyerId',
    'buyerTerminalId',
    'sellerId',
    'sellerTerminalId',
  ])

  const {
    buyerSellerProductOptions,
    sellerProductOptions,
    isLoadingBuyerSellerProducts,
    isLoadingSellerProducts,
    sellerProducts,
    findSellerProductById,
  } = useQueryBuyerSellerProducts({
    filters: {
      joinsSellerProduct: true,
    },
  })

  const sellerProduct = findSellerProductById(billLineData?.sellerProductId)

  const setSellerSideId = (item: IAllowedHierarchy) => {
    switch (item.sellerSideType) {
      case 'Seller':
        formRef.current?.setValue(
          'hierarchyAttributes.sellerSideId',
          invoiceOrLoad?.sellerId,
        )
        break

      case 'SellerTerminal':
        formRef.current?.setValue(
          'hierarchyAttributes.sellerSideId',
          invoiceOrLoad.sellerTerminalId,
        )
        break

      default:
        formRef.current?.setValue('hierarchyAttributes.sellerSideId', null)
        break
    }
  }

  const setBuyerSideId = (item: IAllowedHierarchy) => {
    switch (item.buyerSideType) {
      case 'Buyer':
        formRef.current?.setValue(
          'hierarchyAttributes.buyerSideId',
          invoiceOrLoad?.buyerId,
        )
        break

      case 'BuyerTerminal':
        formRef.current?.setValue(
          'hierarchyAttributes.buyerSideId',
          invoiceOrLoad.buyerTerminalId,
        )
        break

      default:
        formRef.current?.setValue('hierarchyAttributes.buyerSideId', null)
        break
    }
  }

  const fields: IConcordFormField[] = [
    {
      name: 'hierarchableType',
      label: 'Hierarchable Type',
      type: EFieldType.singleSelect,
      options: [
        'Pricing',
        'Setting',
        'Tax',
        'PaymentTerm',
        'ProductTaxOverride',
        'ProductGlCode',
        'AutoCharge',
        'AutoExtra',
        'Discount',
      ].map(value => ({
        label: value,
        value,
      })),
      isRequired: true,
      isHidden: true,
    },
    {
      name: 'hierarchyAttributes.allowedHierarchyId',
      label: 'Allowed Hierarchy',
      type: EFieldType.singleSelect,
      options: allowedHierarchyOpts,
      isLoading: isLoadingAllowedHierarchies,
      components: {
        Option: optProps => {
          const { data } = optProps

          return (
            <components.Option {...optProps}>
              <div>
                <Badge style={{ marginRight: 8 }} bg='dark'>
                  {data.item?.rank}
                </Badge>
                <span>{data.label}</span>
              </div>
            </components.Option>
          )
        },
      },
      isRequired: true,
      onChange({ selectedOption }) {
        if (billLineData) {
          setSellerSideId(selectedOption.item)
          setBuyerSideId(selectedOption.item)
        }
        formRef.current?.setValue(
          'hierarchyAttributes.sellerSideType',
          selectedOption.sellerSideType,
        )
        formRef.current?.setValue(
          'hierarchyAttributes.buyerSideType',
          selectedOption.buyerSideType,
        )
        formRef.current?.clearErrors([
          'hierarchyAttributes.sellerSideType',
          'hierarchyAttributes.buyerSideType',
          'hierarchyAttributes.sellerSideId',
          'hierarchyAttributes.buyerSideId',
        ])
      },
    },
    {
      name: 'hierarchyAttributes',
      render({ control, watch }) {
        let sellerSideOptions: any[] = []
        let buyerSideOptions: any[] = []
        const sellerSideType = watch('hierarchyAttributes.sellerSideType', null)
        const buyerSideType = watch('hierarchyAttributes.buyerSideType', null)
        switch (sellerSideType) {
          case 'Seller':
            sellerSideOptions = sellerCompanyOptions
            break
          case 'SellerTerminal':
            sellerSideOptions = sellerTerminalOptions
            break
        }

        switch (buyerSideType) {
          case 'Buyer':
            buyerSideOptions = buyerCompanyOptions
            break
          case 'BuyerTerminal':
            buyerSideOptions = buyerTerminalOptions
            break
        }

        return (
          <ToggleSection label='Details'>
            <Container style={{ padding: 0 }}>
              <Row>
                <Col>
                  <ConcordDropdownV2WithFormControl
                    control={control}
                    name='hierarchyAttributes.sellerSideType'
                    label='Seller Side Type'
                    isRequired
                    options={ahSellerSideOptions}
                    isReadOnly
                  />
                </Col>
                <Col>
                  <ConcordDropdownV2WithFormControl
                    control={control}
                    name='hierarchyAttributes.sellerSideId'
                    label='Seller Side #'
                    isRequired
                    options={sellerSideOptions}
                    isReadOnly
                  />
                </Col>
              </Row>
              <Row style={{ marginTop: 4 }}>
                <Col>
                  <ConcordDropdownV2WithFormControl
                    control={control}
                    name='hierarchyAttributes.buyerSideType'
                    label='Buyer Side Type'
                    isRequired
                    options={ahBuyerSideOptions}
                    isReadOnly
                  />
                </Col>
                <Col>
                  <ConcordDropdownV2WithFormControl
                    control={control}
                    name='hierarchyAttributes.buyerSideId'
                    label='Buyer Side #'
                    isRequired
                    options={buyerSideOptions}
                    isReadOnly
                  />
                </Col>
              </Row>
            </Container>
          </ToggleSection>
        )
      },
    },
    {
      name: 'hierarchableAttributes.dateRangeInput',
      label: 'Date Range',
      type: EFieldType.dateRange,
      isRequired: true,
      size: 6,
    },
    {
      name: 'hierarchableAttributes.sellerProductId',
      label: 'Seller Product',
      type: EFieldType.singleSelect,
      options:
        currentScope === EScope.buyer
          ? buyerSellerProductOptions
          : sellerProductOptions,
      size: 6,
      isRequired: true,
      isHidden: Boolean(billLineData?.sellerProductId),
      isLoading: isLoadingBuyerSellerProducts || isLoadingSellerProducts,
    },
    {
      name: 'sellerProductElement',
      type: EFieldType.custom,
      size: 6,
      isHidden: !billLineData?.sellerProductId,
      render() {
        const sellerProd = sellerProducts.find(
          ({ id }) => id === billLineData?.sellerProductId,
        )
        return (
          <ConcordFormLayout label='Seller Product'>
            <div>
              <Badge>
                {buildObjectName({
                  name: sellerProd?.name,
                  code: sellerProd?.code,
                })}
              </Badge>
              {sellerProd?.qtyType && (
                <Badge style={{ marginLeft: 4 }}>
                  {_.startCase(sellerProd.qtyType)}
                </Badge>
              )}
            </div>
          </ConcordFormLayout>
        )
      },
    },
    {
      name: 'hierarchableAttributes.amount',
      label: 'Amount',
      type: EFieldType.number,
      isRequired: true,
      prefix: '$',
      size: 6,
    },
    {
      name: 'hierarchableAttributes.pricingType',
      label: 'Pricing Type',
      isRequired: true,
      type: EFieldType.singleSelect,
      size: 6,
      options({ watch }) {
        const sellerProductId = watch('hierarchableAttributes.sellerProductId')
        const sellerProd = sellerProducts.find(
          ({ id }) => id === sellerProductId,
        )
        const sellerCompany = findCompanyById(sellerProd?.sellerId)
        const uom = findUomById(sellerCompany?.uomId)

        return [
          {
            label: uom ? `Per Qty (${uom.code})` : 'Per Qty',
            value: EPricingType.perQty,
          },
          {
            label: 'Per Load',
            value: EPricingType.perLoad,
          },
          {
            label: 'Flat Fee',
            value: EPricingType.flatFee,
          },
          {
            label: 'Percentage',
            value: EPricingType.percentage,
          },
        ]
      },
    },
  ]

  const defaultValues = useMemo<IHierarchyRowFormValues>(
    () => ({
      hierarchableType: 'Pricing',
      hierarchyAttributes: {
        allowedHierarchyId: null,
        sellerSideType: '',
        sellerSideId: null,
        buyerSideId: null,
        buyerSideType: '',
      },
      hierarchableAttributes: {
        dateRangeInput: null,
        sellerProductId: null,
        amount: null,
        pricingType: null,
      },
    }),
    [],
  )

  const schema = useMemo(
    () =>
      Yup.object({
        hierarchableType: Yup.string()
          .required('This field is required!')
          .typeError('This field is required!'),
        hierarchableAttributes: Yup.object({
          amount: Yup.number()
            .required('This field is required!')
            .typeError('This field is required!'),
          pricingType: Yup.string()
            .required('This field is required!')
            .typeError('This field is required!'),
          sellerProductId: Yup.number()
            .required('This field is required!')
            .typeError('This field is required!'),
          dateRangeInput: Yup.lazy(value => {
            if (value && billLineData) {
              return Yup.mixed()
                .test(
                  'within-range',
                  `Bill line (${format(
                    new Date(billLineData?.lineDate as any),
                    'yyyy/MM/dd',
                  )}) is not within this date range`,
                  (date: any) => {
                    return isDateInRange(
                      billLineData?.lineDate,
                      date?.startDate,
                      date?.endDate,
                    )
                  },
                )
                .transform(value => {
                  if (value.startDate) {
                    return {
                      startDate: moment(value.startDate).format('YYYY-MM-DD'),
                      endDate: moment(value.endDate).format('YYYY-MM-DD'),
                    }
                  }
                  return value
                })
            }
            return Yup.mixed().nullable()
          }),
        }),
        hierarchyAttributes: Yup.object({
          sellerSideType: Yup.string()
            .required('This field is required!')
            .typeError('This field is required!'),
          sellerSideId: Yup.number().nullable(),
          buyerSideType: Yup.string().nullable(),
          buyerSideId: Yup.number().nullable(),
          allowedHierarchyId: Yup.number().nullable(),
        }),
      }),
    [billLineData],
  )

  const onCreate = useCallback(
    async (formValues: IHierarchyRowFormValues) => {
      const { errors, ...response } = await apiClient.hierarchyRows.create({
        hierarchyRow: {
          ...formValues,
          rank: 1,
          hierarchableAttributes: {
            ...formValues.hierarchableAttributes,
            dateRangeInput: formValues.hierarchableAttributes.dateRangeInput
              ? `${formValues.hierarchableAttributes.dateRangeInput.startDate}..${formValues.hierarchableAttributes.dateRangeInput.endDate}`
              : null,
          },
        },
      })
      if (errors.length > 0) {
        setError(errors[0])
      } else {
        afterCreate && afterCreate(response)
        toast.success(toastMessages.createSuccess)
      }
    },
    [afterCreate],
  )

  const onUpdate = useCallback(
    async (formValues: IHierarchyRowFormValues) => {
      if (formValues.id) {
        const payload = _.pick(formValues, [
          'sellerProductId',
          'hierarchableType',
          'hierarchyId',
        ])
        const { errors, ...response } = await apiClient.hierarchyRows.update(
          formValues.id,
          {
            hierarchyRow: {
              ...payload,
            },
          },
        )
        if (errors.length > 0) {
          setError(errors[0])
        } else {
          afterUpdate && afterUpdate(response)
          toast.success(toastMessages.updateSuccess)
        }
      } else {
        setError('Id is not found')
      }
    },
    [afterUpdate],
  )

  const handleSubmit = useCallback(
    async (formValues: IHierarchyRowFormValues) => {
      setIsLoading(true)
      setError('')
      try {
        if (isUpdating) {
          await onUpdate(formValues)
        } else {
          await onCreate(formValues)
        }
      } catch (error) {
        toast.error(toastMessages.serverError)
      } finally {
        setIsLoading(false)
      }
    },
    [isUpdating, onCreate, onUpdate],
  )

  useEffect(() => {
    if (sellerProduct) {
      formRef.current?.setValue(
        'hierarchableAttributes.sellerProductId',
        sellerProduct?.id,
      )
      if (sellerProduct?.qtyType) {
        const pricingType =
          sellerProduct?.qtyType === EQtyType.per_load
            ? EPricingType.perLoad
            : sellerProduct?.qtyType === EQtyType.per_order
            ? EPricingType.flatFee
            : sellerProduct?.qtyType
        formRef.current?.setValue(
          'hierarchableAttributes.pricingType',
          pricingType,
        )
      }
    }
  }, [sellerProduct])

  return (
    <div>
      <When condition={Boolean(error)}>
        <Alert variant='danger' style={{ margin: 0, fontSize: 14 }}>
          {error}
        </Alert>
      </When>
      <ConcordFormStructure
        {...formProps}
        isLoading={isLoading}
        ref={formRef}
        defaultValues={defaultValues}
        formData={formData}
        fields={fields}
        isHiddenCancelButton
        isHiddenSearch
        onSubmit={handleSubmit}
        schema={schema}
        submitText={isUpdating ? 'Update' : 'Create'}
      />
    </div>
  )
}

export default HierarchyRowForm
