import Pusher from 'pusher-js'
import PusherContext from './PusherContext'
import { PropsWithChildren, useMemo } from 'react'

function PusherProvider(props: PropsWithChildren) {
  const { children } = props

  const pusherInstance = useMemo(
    () =>
      new Pusher(process.env.REACT_APP_PUSHER_API_KEY as string, {
        cluster: process.env.REACT_APP_PUSHER_CLUSTER,
      }),
    [],
  )

  return (
    <PusherContext.Provider value={{ pusherInstance }}>
      {children}
    </PusherContext.Provider>
  )
}

export default PusherProvider
