import { useQuery, useQueryClient, UseQueryOptions } from 'react-query'
import { useSelector } from 'react-redux'

import { apiClient } from '~/api/ApiClient'
import { selectSessionUser } from '~/redux/selectors'
import { DEFAULT_QUERY_OPTIONS } from '../constants'

import type { IUser } from '~/types/models/IUser'
import { IDriverFleet } from '~/types/models/IDriverFleet'
import { produce } from 'immer'

const useQueryUserInfo = (options?: Partial<UseQueryOptions<IUser>>) => {
  const sessionUser: IUser | null = useSelector(selectSessionUser)
  const queryClient = useQueryClient()

  const { data, refetch, isFetched, isLoading } = useQuery({
    queryKey: ['userInfo', sessionUser?.id],
    async queryFn() {
      const response = await apiClient.users.getAccountInfo()
      return response
    },
    enabled: Boolean(sessionUser?.id),
    ...DEFAULT_QUERY_OPTIONS,
    ...options,
  })

  const updateDriverFleet = (driverFleet: Partial<IDriverFleet>) => {
    queryClient.setQueryData<IUser | undefined>(
      ['userInfo', sessionUser?.id],
      oldData =>
        produce(oldData, draft => {
          if (draft?.driverFleet) {
            draft.driverFleet = {
              ...draft.driverFleet,
              ...driverFleet,
            }
          }
        }),
    )
  }

  return {
    userInfo: data,
    isUserInfoLoading: isLoading,
    isUserInfoFetched: isFetched,
    updateDriverFleet,
    refetchUserInfo: refetch,
  }
}

export default useQueryUserInfo
