import { useCallback, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import {
  useQueryBuyerSellerProducts,
  useQueryCompanies,
  useQueryLoad,
  useQuerySchedule,
  useQueryTerminals,
  useQueryUoms,
  useQueryUsers,
} from '~/hooks/useQueryData'

import { selectCurrentScope, selectSessionUser } from '~/redux/selectors'
import clsx from 'clsx'

import type { ILoad, ILoadStatus } from '~/types/models/ILoad'
import type { IUseOrderCardOnMapProps } from './type'
import { EScope } from '~/types/enums/ECommonEnum'
import buildFullName from '~/utils/buildFullName'
import { IUser } from '~/types/models/IUser'

export const useScheduleCardOnMap = (props: IUseOrderCardOnMapProps) => {
  const { loadId, truck, isHiddenHyperLink } = props

  const [isOpenOrderForm, setIsOpenOrderForm] = useState(false)
  const [defaultOrderTab, setDefaultOrderTab] =
    useState<string | number | undefined>(undefined)
  const [isOpenLoadStatusModal, setIsOpenLoadStatusModal] = useState(false)

  const { loadData, isLoadingLoadData, updateLoadData } = useQueryLoad(loadId)
  const { scheduleData } = useQuerySchedule(loadData?.scheduleId as number)
  const { currentUom } = useQueryUoms()

  const {
    buyerSellerProducts: [loadBuyerSellerProduct],
  } = useQueryBuyerSellerProducts(
    {
      id: loadData?.buyerSellerProductId as number,
    },
    { enabled: Boolean(loadData?.buyerSellerProductId) },
  )

  const { findTerminalById } = useQueryTerminals()
  const { findUserByDriverFleetId } = useQueryUsers()
  const { findCompanyById } = useQueryCompanies({})

  const currentScope: EScope = useSelector(selectCurrentScope)
  const sessionUser: IUser = useSelector(selectSessionUser)

  const loadBuyerTerminal = findTerminalById(loadData?.buyerTerminalId)

  const loadBuyer = useMemo(() => {
    if (loadData?.buyerId) {
      return findCompanyById(loadData.buyerId)
    }
    return null
  }, [findCompanyById, loadData?.buyerId])

  const loadSeller = useMemo(() => {
    if (loadData?.sellerId) {
      return findCompanyById(loadData.sellerId)
    }
    return null
  }, [findCompanyById, loadData?.sellerId])

  const loadSellerTerminal = findTerminalById(loadData?.sellerTerminalId)

  const percentage = useMemo(() => {
    if (scheduleData) {
      const result = Number(loadData?.qty) / Number(scheduleData.qty)
      return Math.round(result * 100)
    }
    return 0
  }, [loadData?.qty, scheduleData])

  const loadQty = useMemo(
    () =>
      loadData
        ? `${Number(loadData.qty)} ${currentUom?.code || ''} - ${Number(
            loadData?.orderQty,
          )}`
        : '',
    [currentUom?.code, loadData],
  )

  const loadDriverFullName = useMemo(() => {
    if (currentScope === EScope.driverFleet) {
      return buildFullName(sessionUser.person)
    }
    if (loadData?.driverFleetId) {
      const user = findUserByDriverFleetId(loadData?.driverFleetId)
      return buildFullName(user?.person)
    }
    return undefined
  }, [
    currentScope,
    findUserByDriverFleetId,
    loadData?.driverFleetId,
    sessionUser.person,
  ])

  const onOpenOrderForm = useCallback(
    (defaultTab?: number | string) => () => {
      if (!isHiddenHyperLink) {
        setIsOpenOrderForm(true)
        if (defaultTab !== undefined) {
          setDefaultOrderTab(defaultTab)
        }
      }
    },
    [isHiddenHyperLink],
  )

  const orderNumber = useMemo(() => {
    if (scheduleData) {
      return (
        <>
          <span
            className={clsx({ hyperLink: !isHiddenHyperLink })}
            onClick={onOpenOrderForm()}
          >
            #{scheduleData.order.num}
          </span>
          &nbsp;-&nbsp;
          <span
            className={clsx({ hyperLink: !isHiddenHyperLink })}
            onClick={onOpenOrderForm(scheduleData.scheduleNumber as string)}
          >
            {scheduleData.scheduleNumber || '-'}
          </span>
        </>
      )
    }
  }, [isHiddenHyperLink, onOpenOrderForm, scheduleData])

  const onCloseOrderForm = useCallback(() => {
    setIsOpenOrderForm(false)
  }, [])

  const onOpenLoadStatusesDialog = useCallback(() => {
    setIsOpenLoadStatusModal(true)
  }, [])

  const onCloseLoadStatusModal = useCallback(() => {
    setIsOpenLoadStatusModal(false)
  }, [])

  const onChangeLoadStatus = useCallback(
    (loadData: ILoad, status: string) => {
      updateLoadData({ status: status as ILoadStatus })
    },
    [updateLoadData],
  )

  return {
    percentage,
    loadBuyerTerminal,
    loadBuyer,
    isLoadingLoadData,
    scheduleData,
    truck,
    loadData,
    orderNumber,
    isOpenOrderForm,
    defaultOrderTab,
    isOpenLoadStatusModal,
    loadSeller,
    loadSellerTerminal,
    currentUom,
    loadQty,
    loadBuyerSellerProduct,
    loadDriverFullName,
    onCloseOrderForm,
    onOpenLoadStatusesDialog,
    onCloseLoadStatusModal,
    onChangeLoadStatus,
  }
}

export default useScheduleCardOnMap
