import React, { useCallback, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'

import { ConcordFormStructure } from '~/components/shared'
import ConfigurationItemDataTable from './ConfigurationItemDataTable'

import { FORM_FIELD_TYPE } from '~/utils/constants'
import { selectIsScopeBuyer, selectMyCurrentCompany } from '~/redux/selectors'
import * as Yup from 'yup'
import { toast } from 'react-toastify'
import { toastMessages } from '~/constants/toast-status-text'

import { apiClient } from '~/api/ApiClient'
import { useQueryCompanies } from '~/hooks/useQueryData'

const FlagableConfigurationsForm = props => {
  const { afterCreate, afterUpdate, formData, ...formProps } = props

  const [isLoading, setIsLoading] = useState(false)

  const isUpdating = useMemo(() => Boolean(formData?.id), [formData?.id])

  const isBuyer = useSelector(selectIsScopeBuyer)
  const currentCompany = useSelector(selectMyCurrentCompany)

  const { sellerCompanyOptions, buyerCompanyOptions } = useQueryCompanies()

  const fields = useMemo(
    () => [
      {
        label: 'Missing fields',
        name: 'missingFields',
        type: FORM_FIELD_TYPE.tags,
        placeholder: 'Enter to add a missing field',
      },
      {
        label: isBuyer ? 'Seller' : 'Buyer',
        name: isBuyer ? 'sellerId' : 'buyerId',
        isRequired: true,
        type: FORM_FIELD_TYPE.singleSelect,
        options: isBuyer ? sellerCompanyOptions : buyerCompanyOptions,
      },
      {
        label: 'Compare fields',
        name: 'compareFields',
        type: FORM_FIELD_TYPE.custom,
        isRequired: true,
        render(params) {
          const { label, name, watch, error, setValue } = params
          const data = watch(name, [])

          return (
            <ConfigurationItemDataTable
              label={label}
              data={data}
              error={error}
              onChange={newItem => {
                setValue(name, newItem)
              }}
            />
          )
        },
      },
      {
        label: 'Calculations',
        name: 'calculations',
        type: FORM_FIELD_TYPE.custom,
        isRequired: true,
        render(params) {
          const { label, name, watch, error, setValue } = params
          const data = watch(name, [])

          return (
            <ConfigurationItemDataTable
              label={label}
              data={data}
              error={error}
              onChange={newItem => {
                setValue(name, newItem)
              }}
            />
          )
        },
      },
    ],
    [buyerCompanyOptions, isBuyer, sellerCompanyOptions],
  )

  const defaultValues = useMemo(
    () => ({
      missingFields: [],
      buyerId: null,
      sellerId: null,
      compareFields: [],
      calculations: [],
    }),
    [],
  )

  const schema = useMemo(
    () =>
      Yup.object({
        buyerId: Yup.lazy(() => {
          if (isBuyer) {
            return Yup.number().nullable()
          }

          return Yup.number('Buyer is required!').typeError(
            'Buyer is required!',
          )
        }),
        sellerId: Yup.lazy(() => {
          if (isBuyer) {
            return Yup.number('Seller is required!').typeError(
              'Seller is required!',
            )
          }
          return Yup.number().nullable()
        }),
        compareFields: Yup.array().min(1, 'At least one field is present!'),
        calculations: Yup.array().min(1, 'At least one field is present!'),
      }),
    [isBuyer],
  )

  const updateFlagableConfig = useCallback(
    async formData => {
      const { id, ...payload } = formData
      const { errors, ...response } =
        await apiClient.flagableConfigurations.update(id, payload)
      if (errors?.length > 0) {
        const [errorMessage] = errors
        toast.error(errorMessage)
      } else {
        afterUpdate && afterUpdate(response)
        toast.success(toastMessages.updateSuccess)
      }
    },
    [afterUpdate],
  )

  const createFlagableConfig = useCallback(
    async formData => {
      const payload = {
        ...formData,
      }
      if (isBuyer) {
        payload.buyerId = currentCompany?.id
      } else {
        payload.sellerId = currentCompany?.id
      }

      const { errors, ...response } =
        await apiClient.flagableConfigurations.create(payload)
      if (errors?.length > 0) {
        const [errorMessage] = errors
        toast.error(errorMessage)
      } else {
        afterCreate && afterCreate(response)
        toast.success(toastMessages.createSuccess)
      }
    },
    [afterCreate, currentCompany?.id, isBuyer],
  )

  const handleSubmit = useCallback(
    async formData => {
      setIsLoading(true)
      try {
        if (isUpdating) {
          await updateFlagableConfig(formData)
        } else {
          await createFlagableConfig(formData)
        }
      } catch (error) {
        console.log('error', error)
      } finally {
        setIsLoading(false)
      }
    },
    [createFlagableConfig, isUpdating, updateFlagableConfig],
  )

  return (
    <ConcordFormStructure
      {...formProps}
      formData={formData}
      fields={fields}
      defaultValues={defaultValues}
      onSubmit={handleSubmit}
      schema={schema}
      isLoading={isLoading}
    />
  )
}

export default FlagableConfigurationsForm
