import { useEffect, useRef, useState } from 'react'
import Select, { GroupBase, SelectInstance, Props } from 'react-select'
import './styles.scss'

type SelectProps<
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
> = Props<Option, IsMulti, Group> & {
  text?: string
  label?: string
  toggleShow: boolean
}

export const TextToggleSelect = <
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>(
  props: SelectProps<Option, IsMulti, Group>,
) => {
  const { text, label, toggleShow } = props
  const [showInput, setShowInput] = useState<boolean>(false)

  const inputRef = useRef<SelectInstance<Option, IsMulti, Group>>(null)

  useEffect(() => {
    toggleShow && setShowInput(toggleShow)
  }, [toggleShow])

  useEffect(() => {
    showInput && inputRef.current?.focus()
  }, [showInput])

  return (
    <div className='d-flex flex-row align-items-center'>
      {!showInput && (
        <div
          onClick={event => {
            event.stopPropagation()
            setShowInput(!showInput)
          }}
        >
          {label} {text}
        </div>
      )}
      <div
        style={{
          opacity: showInput ? 1 : 0,
          width: showInput ? '100%' : 0,
          height: showInput ? 'auto' : 0,
        }}
      >
        <Select
          openMenuOnFocus
          {...props}
          ref={inputRef}
          onFocus={() => setShowInput(true)}
          onBlur={() => setShowInput(false)}
        />
      </div>
    </div>
  )
}
