export enum ESellerProductUsage {
  product = 'product',
  bundleItem = 'bundle_item',
  both = 'both',
}

export enum EQtyType {
  per_qty = 'per_qty',
  per_load = 'per_load',
  per_order = 'per_order',
  flat_fee = 'flat_fee',
}

export enum EOrderType {
  primary = 'primary',
  extra = 'extra',
  both = 'both',
}

export enum ESellerProductMaterialType {
  bundled_material = 'bundled_material',
  single_material = 'single_material',
  freight = 'freight',
  not_freight_or_material = 'not_freight_or_material',
}
