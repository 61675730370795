import { CommonDialogV2 } from '~/components/shared/CommonDialogV2'
import { IDialogAutoChargesFormProps } from './type'
import { useMemo } from 'react'
import AutoChargeForm from './AutoChargeForm'

function DialogAutoChargesForm(props: IDialogAutoChargesFormProps) {
  const { formData, afterCreate, afterUpdate, onClose, ...modalProps } = props
  const isUpdating = useMemo(() => Boolean(formData?.id), [formData?.id])

  return (
    <CommonDialogV2
      {...modalProps}
      okText={isUpdating ? 'Update' : 'Create'}
      title='Auto Charge'
      isHiddenOkButton
      onClose={onClose}
    >
      <AutoChargeForm
        formData={formData}
        afterCreate={formData => {
          afterCreate && afterCreate(formData)
          onClose && onClose()
        }}
        afterUpdate={formData => {
          afterUpdate && afterUpdate(formData)
          onClose && onClose()
        }}
      />
    </CommonDialogV2>
  )
}
export default DialogAutoChargesForm
