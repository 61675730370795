import { useQuery, UseQueryOptions } from 'react-query'
import { useSelector } from 'react-redux'

import { apiClient } from '~/api/ApiClient'
import { selectSessionUser } from '~/redux/selectors'

import type { IUser } from '~/types/models/IUser'
import {
  ILoadTimesGetListParams,
  ILoadTimesGetListResponse,
} from '~/types/models/ILoadTime'
import { buildGetUrl } from '~/utils/buildUrl'
import { useMemo } from 'react'
import { DEFAULT_QUERY_OPTIONS } from '../constants'

const useQueryLoadTimes = (
  params: ILoadTimesGetListParams,
  options?: Partial<UseQueryOptions<ILoadTimesGetListResponse>>,
) => {
  const sessionUser: IUser | null = useSelector(selectSessionUser)

  const { data, isLoading, refetch, isFetched } = useQuery({
    queryKey: [
      'loadTimes',
      sessionUser?.id,
      buildGetUrl(apiClient.loadTimes.endpoint, params),
    ],
    queryFn() {
      return apiClient.loadTimes.get(params)
    },
    enabled: Boolean(sessionUser?.id && params.filters.loadId),
    ...DEFAULT_QUERY_OPTIONS,
    ...options,
  })

  const loadTimesMapped = useMemo(() => {
    if (data?.loadTimes) {
      return data.loadTimes[0]?.load?.loadTimes || []
    }
    return []
  }, [data?.loadTimes])

  return {
    loadTimesData: data,
    isLoadTimesDataLoading: isLoading,
    loadTimesMapped,
    isLoadTimesDataFetched: isFetched,
    refetchLoadTimesData: refetch,
  }
}

export default useQueryLoadTimes
