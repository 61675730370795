import { useCallback, useState, memo } from 'react'

import { CommonDialogV2 } from '~/components/shared'
import { IonButton, IonLabel, IonButtons } from '@ionic/react'
import moment from 'moment'
import clsx from 'clsx'
import { Calendar } from 'react-date-range'

import './styles.scss'

const DatePicker = props => {
  const { date, title, onDateChange, className, style, minDate, maxDate } =
    props

  const [isShow, setIsShow] = useState(false)

  const titleLabel = date ? moment(date).format('YYYY-MM-DD') : 'All'

  const onHidePicker = useCallback(() => {
    setIsShow(false)
  }, [])

  const onShowPicker = useCallback(() => {
    setIsShow(true)
  }, [])

  return (
    <>
      <IonButtons className={clsx('dateSubmitButton', className)} style={style}>
        <IonButton
          fill='clear'
          onClick={onShowPicker}
          color={date ? 'medium' : 'primary'}
        >
          <IonLabel style={{ fontWeight: 'bold', fontSize: 'small' }}>
            {title}:&nbsp;
          </IonLabel>
          <IonLabel
            style={{
              maxWidth: '100%',
              padding: 0,
              opacity: date ? 0.6 : 1,
              fontSize: 'small',
            }}
            color='dark'
          >
            {titleLabel}
          </IonLabel>
        </IonButton>
      </IonButtons>
      <CommonDialogV2
        isOpen={isShow}
        onClose={onHidePicker}
        onOk={() => {
          onDateChange(null)
          onHidePicker()
        }}
        okText='Clear'
        okButtonProps={{
          color: 'fleet',
        }}
        className='DatePicker__dialog'
        title='Select Date'
      >
        <Calendar
          date={
            date instanceof Date
              ? date
              : date
              ? moment(date).toDate()
              : undefined
          }
          onChange={date => {
            onDateChange(date)
            onHidePicker()
          }}
          minDate={minDate ? moment(minDate).toDate() : undefined}
          maxDate={maxDate ? moment(maxDate).toDate() : undefined}
        />
      </CommonDialogV2>
    </>
  )
}

export default memo(DatePicker)
