import { useCallback, useMemo, useRef, useState } from 'react'

import { ConcordFormStructure, IConcordFormField } from '~/components/shared'
import { When } from 'react-if'
import { Alert } from 'react-bootstrap'

import * as Yup from 'yup'
import { apiClient } from '~/api/ApiClient'
import { toast } from 'react-toastify'
import { toastMessages } from '~/constants/toast-status-text'

import { EFieldType, EScope } from '~/types/enums/ECommonEnum'
import { useQuerySellerProducts } from '~/hooks/useQueryData'
import { ICompany } from '~/types/models/ICompany'
import { useSelector } from 'react-redux'
import { selectCurrentScope, selectMyCurrentCompany } from '~/redux/selectors'
import {
  EAutoChargeDateRangeType,
  ICreateAutoChargePayload,
  IUpdateAutoChargePayload,
} from '~/types/models/IAutoCharge'
import { DateTime } from 'luxon'
import { makeOptions } from '~/utils/utils'
import { IAutoChargesFormProps, IAutoChargeFormValues } from './type'
import { ACTIVE_OPTIONS } from '~/utils/constants'

const AutoChargeForm = (props: IAutoChargesFormProps) => {
  const { afterCreate, afterUpdate, formData, ...formProps } = props

  const isUpdating = useMemo(() => Boolean(formData?.id), [formData?.id])

  const [error, setError] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const formRef = useRef<any>()

  const currentScope: EScope = useSelector(selectCurrentScope)
  const currentCompany: ICompany = useSelector(selectMyCurrentCompany)

  const { sellerProductOptions } = useQuerySellerProducts({
    filters: {
      sellerId:
        currentScope === EScope.seller ? [currentCompany.id] : undefined,
    },
  })

  const dateRangeTypeOptions = useMemo(
    () => makeOptions(EAutoChargeDateRangeType),
    [],
  )

  const fields = useMemo<IConcordFormField[]>(
    () => [
      {
        name: 'sellerProductId',
        label: 'Seller Product',
        options: sellerProductOptions,
        isRequired: true,
        type: EFieldType.singleSelect,
        size: 6,
      },
      {
        label: 'Qty',
        name: 'qty',
        size: 6,
        type: EFieldType.number,
      },
      {
        label: 'Start date',
        name: 'dateRangeStart',
        size: 6,
        type: EFieldType.date,
      },
      {
        label: 'End date',
        name: 'dateRangeEnd',
        size: 6,
        type: EFieldType.date,
      },
      {
        label: 'Date range type',
        name: 'dateRangeType',
        size: 6,
        type: EFieldType.singleSelect,
        options: dateRangeTypeOptions,
      },

      {
        label: 'Active',
        name: 'active',
        size: 6,
        options: ACTIVE_OPTIONS,
        type: EFieldType.singleSelect,
      },
    ],
    [dateRangeTypeOptions, sellerProductOptions],
  )

  const schema = useMemo(
    () =>
      Yup.object({
        dateRangeType: Yup.string(),
        active: Yup.boolean(),
        sellerProductId: Yup.string().required('This field is required!'),
        qty: Yup.number().required('This field is required!'),
        dateRangeStart: Yup.string(),
        dateRangeEnd: Yup.string().test({
          name: 'is-greater',
          message: 'End date must be after start date',
          test: function (value) {
            if (!value && !this.parent.dateRangeStart) return true
            if (this.parent.dateRangeType === 'seasonal') {
              return true
            }
            return (
              DateTime.fromISO(value as any) >
              DateTime.fromISO(this.parent.dateRangeStart)
            )
          },
        }),
      }),
    [],
  )

  const defaultValues = useMemo<IAutoChargeFormValues>(
    () => ({
      active: true,
      dateRangeStart: undefined,
      dateRangeEnd: undefined,
      dateRangeType: EAutoChargeDateRangeType.dateRange,
      qty: 1,
      sellerProductId: undefined,
    }),
    [],
  )

  const onCreate = useCallback(
    async (payload: ICreateAutoChargePayload) => {
      const { errors, ...res } = await apiClient.autoCharges.create(payload)
      if (errors.length > 0) {
        setError(errors[0])
      } else {
        afterCreate && afterCreate(res)
        toast.success(toastMessages.createSuccess)
      }
    },
    [afterCreate],
  )

  const onUpdate = useCallback(
    async (payload: IUpdateAutoChargePayload) => {
      if (formData?.id) {
        const { errors, ...response } = await apiClient.autoCharges.update(
          formData.id,
          payload,
        )

        if (errors.length > 0) {
          setError(errors[0])
        } else {
          afterUpdate && afterUpdate(response)
          toast.success(toastMessages.updateSuccess)
        }
      } else {
        setError('Id is not found')
      }
    },
    [afterUpdate, formData?.id],
  )

  const handleSubmit = useCallback(
    async (formValues: IAutoChargeFormValues) => {
      setIsLoading(true)
      setError('')

      try {
        const { dateRangeEnd, dateRangeStart, ...restOfForm } = formValues
        const start = dateRangeStart
          ? DateTime.fromISO(dateRangeStart).toFormat('yyyy-MM-dd')
          : undefined
        const end = dateRangeEnd
          ? DateTime.fromISO(dateRangeEnd).toFormat('yyyy-MM-dd')
          : undefined

        const low = '-infinity'
        const hi = 'infinity'

        if (isUpdating) {
          const payload = {
            autoCharge: {
              ...restOfForm,
              dateRange: `${start || low}..${end || hi}`,
            },
          } as IUpdateAutoChargePayload

          await onUpdate(payload)
        } else {
          const payload = {
            ...restOfForm,
            dateRange: `${start || low}..${end || hi}`,
          } as ICreateAutoChargePayload

          await onCreate(payload)
        }
      } catch (error) {
        toast.error(toastMessages.serverError)
      } finally {
        setIsLoading(false)
      }
    },
    [isUpdating, onCreate, onUpdate],
  )

  return (
    <div>
      <When condition={Boolean(error)}>
        <Alert variant='danger' style={{ margin: 0, fontSize: 14 }}>
          {error}
        </Alert>
      </When>
      <ConcordFormStructure
        {...formProps}
        isLoading={isLoading}
        ref={formRef}
        formData={formData}
        fields={fields}
        defaultValues={defaultValues}
        schema={schema}
        isHiddenCancelButton
        isHiddenSearch
        onSubmit={handleSubmit}
        submitText={isUpdating ? 'Update' : 'Create'}
      />
    </div>
  )
}

export default AutoChargeForm
