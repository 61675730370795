import { useCallback, useEffect, useMemo, useState } from 'react'
import { Badge, Col, Container, Placeholder, Row } from 'react-bootstrap'
import { SubmitHandler, useForm, useWatch } from 'react-hook-form'
import { apiClient } from '~/api/ApiClient'
import { DeleteIcon, ReusableButton } from '~/components/shared'
import { FloatingInput } from '~/components/shared/FloatingForm/Input'
import { EOrderType } from '~/types/enums/ESellerProduct'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { ILoadExtra } from '~/types/models/ILoadExtra'
import { toast } from 'react-toastify'
import { useSelectOptionsCombinedBuyerAndSellerProducts } from '~/hooks/useSelectOptionsCombinedBuyerAndSellerProducts'
import { ILoadExtraLineFormProps, ILoadExtraLineFormValues } from './type'
import ProductSelect from '~/components/shared/ProductSelect/ProductSelect'
import _ from 'lodash'
import { useQueryUoms } from '~/hooks/useQueryData'

const extraOrderTypes = [EOrderType.extra, EOrderType.both]

interface IFormValues {
  buyerSellerProductId: number
  orderExtraQty: number
}

export const LoadExtraLineForm = ({
  sellerId,
  buyerId,
  loadId,
  extra,
  loadQty,
  loadSellerProductId,
  onUpdate,
  onCreate,
  onDelete,
  onSubmit,
  onClickCancel,
  isOptionDisabled,
}: ILoadExtraLineFormProps) => {
  const [loading, setLoading] = useState(false)
  const [loadExtra, setLoadExtra] = useState<ILoadExtra | null>(null)

  useEffect(() => {
    extra && setLoadExtra(extra)
  }, [extra])

  const { buyerSellerProducts, getSellerProduct } =
    useSelectOptionsCombinedBuyerAndSellerProducts({
      buyerId,
      sellerId,
      orderTypes: extraOrderTypes,
    })

  const { uomsObj, currentUom } = useQueryUoms()

  const { control, handleSubmit, setValue } = useForm<
    Partial<IFormValues>,
    void,
    ILoadExtraLineFormValues
  >({
    mode: 'onBlur',
    defaultValues: {
      buyerSellerProductId: undefined,
      orderExtraQty: 1,
    },
    resolver: yupResolver(
      yup
        .object({
          buyerSellerProductId: yup.number().positive().integer().required(),
          orderExtraQty: yup.number().positive().required(),
        })
        .required(),
    ),
  })

  const buyerSellerProductId = useWatch({
    control,
    name: 'buyerSellerProductId',
  })

  const qty = useWatch({
    control,
    name: 'orderExtraQty',
  })

  const qtyType = buyerSellerProductId
    ? getSellerProduct(buyerSellerProductId)?.qtyType
    : undefined

  const sellerProductId = buyerSellerProductId
    ? getSellerProduct(buyerSellerProductId)?.id
    : undefined

  const uomCode = useCallback(
    (spId: number) => {
      if (spId !== undefined) {
        const product = getSellerProduct(spId)
        if (product && product.uomId !== null && product.uomId !== undefined) {
          const uom = uomsObj[product.uomId]
          return uom.code
        }
      }
      return currentUom?.code || ''
    },
    [currentUom?.code, getSellerProduct, uomsObj],
  )

  const qtySummarized = useMemo(() => {
    if (
      qty &&
      loadQty &&
      loadSellerProductId &&
      sellerProductId &&
      qtyType === 'per_qty'
    )
      return `${qty} ${uomCode(sellerProductId)}s / ${uomCode(
        loadSellerProductId,
      )} x ${loadQty} ${uomCode(loadSellerProductId)} = ${
        qty * parseFloat(loadQty)
      } ${uomCode(sellerProductId)}s`
  }, [qty, loadQty, loadSellerProductId, qtyType, uomCode, sellerProductId])

  const createLoadExtra: SubmitHandler<ILoadExtraLineFormValues> =
    async formValues => {
      if (loadId) {
        setLoading(true)
        try {
          const { errors, ...newLoadExtra } = await apiClient.loadExtras.create(
            {
              loadId,
              ...formValues,
              sellerProductId: getSellerProduct(formValues.buyerSellerProductId)
                ?.id as number,
              qty: 1,
            },
          )
          if (errors.length > 0) {
            toast.error(errors.join('\n'))
          } else {
            setLoadExtra(newLoadExtra)
            onCreate && onCreate(newLoadExtra)
          }
        } catch (error) {
          console.error(error)
        } finally {
          setLoading(false)
        }
      } else {
        onSubmit && onSubmit(formValues)
      }
    }

  const updateLoadExtra = async (formValues: ILoadExtraLineFormValues) => {
    setLoading(true)
    if (!loadExtra) return
    try {
      const { errors, ...newLoadExtra } = await apiClient.loadExtras.update(
        loadExtra.id,
        {
          qty: 1,
          ...formValues,
          sellerProductId: getSellerProduct(formValues.buyerSellerProductId)
            ?.id as number,
        },
      )

      if (errors.length > 0) {
        toast.error(errors.join('\n'))
      } else {
        setLoadExtra(newLoadExtra)
        onUpdate && onUpdate(newLoadExtra)
      }
    } catch (error) {
      console.error(error)
    }
    setLoading(false)
  }

  const deleteLoadExtra = async () => {
    setLoading(true)
    if (loadExtra) {
      try {
        const { errors, ...res } = await apiClient.loadExtras.delete(
          loadExtra.id,
        )

        if (errors.length > 0) {
          toast.error(errors.join('\n'))
        } else {
          setLoadExtra(null)
          onDelete && onDelete(res.id)
        }
      } catch (error) {
        console.error(error)
      }
    }
    setLoading(false)
  }

  useEffect(() => {
    if (extra?.sellerProductId) {
      const buyerSellerProduct = buyerSellerProducts.find(
        ({ sellerProductId }) => sellerProductId === extra?.sellerProductId,
      )
      setValue('buyerSellerProductId', buyerSellerProduct?.id)
    }
    setValue('orderExtraQty', extra?.orderExtraQty || 1)
  }, [buyerSellerProducts, extra, setValue])

  return (
    <>
      <form className='d-flex align-items-center gap-1'>
        {loading && (
          <Container className='p-0'>
            <Row>
              <Col xs={5}>
                <Placeholder animation='wave'>
                  <Placeholder xs={12} />
                </Placeholder>
              </Col>
              <Col xs={2}>
                <Placeholder animation='wave'>
                  <Placeholder xs={12} />
                </Placeholder>
              </Col>
              <Col xs={2} />
              <Col>
                <Placeholder animation='wave' className='pe-3'>
                  <Placeholder.Button xs={5} />
                </Placeholder>
                <Placeholder animation='wave'>
                  <Placeholder.Button xs={5} size='lg' variant='secondary' />
                </Placeholder>
              </Col>
            </Row>
          </Container>
        )}
        {!loading && (
          <>
            <ProductSelect
              control={control}
              controlName='buyerSellerProductId'
              sellerId={sellerId}
              buyerId={buyerId}
              orderType={extraOrderTypes}
              isOptionDisabled={isOptionDisabled}
            />

            <FloatingInput
              control={control}
              inputType='number'
              label='QTY'
              name='orderExtraQty'
              placeholder='QTY'
              style={{ width: '80px' }}
            />

            {qtyType && (
              <Badge pill className='d-flex ms-1'>
                {_.startCase(qtyType)}
              </Badge>
            )}

            <div className='ms-auto' />
            {extra && (
              <ReusableButton
                onClick={deleteLoadExtra}
                variant='danger'
                className='ms-auto'
              >
                <DeleteIcon color='#fff' />
              </ReusableButton>
            )}
            <ReusableButton
              onClick={handleSubmit(
                loadExtra ? updateLoadExtra : createLoadExtra,
              )}
            >
              {loadExtra ? 'Update' : 'Create'}
            </ReusableButton>
            {onClickCancel && (
              <ReusableButton
                onClick={() => onClickCancel()}
                variant='secondary'
              >
                Cancel
              </ReusableButton>
            )}
          </>
        )}
      </form>
      {qtySummarized && (
        <div className='d-flex'>
          <div className='ms-auto' />
          <Badge pill className='d-flex ms-1'>
            {qtySummarized}
          </Badge>
        </div>
      )}
    </>
  )
}
