import React, { useEffect, useState } from 'react'
import { CheckMarkIcon, CloseIcon, ReusableTimeField } from '../shared'
import moment from 'moment'
import { selectMyCurrentCompany } from '~/redux/selectors'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { toastMessages } from '~/constants/toast-status-text'
import { apiClient } from '~/api/ApiClient'
import Skeleton from 'react-loading-skeleton'

function EditableTimeCell(props) {
  const { loadTime, onUpdate } = props

  const [isEditing, setIsEditing] = useState(false)
  const [timeValue, setTimeValue] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const currentCompany = useSelector(selectMyCurrentCompany)

  const timeFormat = ['H:mm', 'h:mm aa'].includes(currentCompany.timeFormat)
    ? currentCompany.timeFormat || 'H:mm'
    : 'H:mm'

  const onCloseEditInput = () => {
    setIsEditing(false)
  }

  const onSubmit = async () => {
    onCloseEditInput()
    setIsLoading(true)
    try {
      const { loadTime: newLoadTime } = await apiClient.loadTimes.update(
        loadTime.id,
        {
          loadTime: {
            time: timeValue,
          },
        },
      )
      onUpdate && onUpdate(newLoadTime)
    } catch (error) {
      console.log('error', error)
      toast.error(toastMessages.serverError)
    } finally {
      setIsLoading(false)
    }
  }

  const onCancel = () => {
    setTimeValue(loadTime.time)
    onCloseEditInput()
  }

  useEffect(() => {
    setTimeValue(loadTime.time)
  }, [loadTime.time])

  return (
    <td
      style={{
        borderRight: '1px solid #e5e5e5',
        textAlign: 'center',
        maxWidth: 60,
      }}
    >
      {isLoading ? (
        <Skeleton width='100%' height={20} style={{ marginBottom: 4 }} />
      ) : isEditing ? (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <ReusableTimeField
            value={timeValue}
            onChange={date => {
              setTimeValue(date)
            }}
          />
          <div>
            <span onClick={onSubmit}>
              <CheckMarkIcon className='clickable' color='var(--bs-success)' />
            </span>
            <span onClick={onCancel}>
              <CloseIcon
                className='clickable'
                size={17}
                color='var(--bs-danger)'
              />
            </span>
          </div>
        </div>
      ) : (
        <span
          onClick={() => {
            setIsEditing(true)
          }}
        >
          {timeValue ? moment(timeValue).format(timeFormat) : '-'}
        </span>
      )}
    </td>
  )
}

export default EditableTimeCell
