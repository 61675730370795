import { useContext } from 'react'
import PusherContext from './PusherContext'

const usePusherProvider = () => {
  const context = useContext(PusherContext)

  return context
}

export default usePusherProvider
