import { useQuery, UseQueryOptions } from 'react-query'
import { useSelector } from 'react-redux'
import { useCallback, useMemo } from 'react'

import { buildGetUrl } from '~/utils/buildUrl'
import { apiClient } from '~/api/ApiClient'
import { selectSessionUser } from '~/redux/selectors'

import type {
  IGetSellerProductQueryParams,
  IGetSellerProductResponse,
} from '~/types/models/ISellerProduct'
import type { IUser } from '~/types/models/IUser'
import _ from 'lodash'
import useModifySellerProducts from './useModifySellerProducts'
import { DEFAULT_QUERY_OPTIONS } from '../constants'

const useQuerySellerProducts = (
  params: IGetSellerProductQueryParams = {},
  options?: Partial<UseQueryOptions<IGetSellerProductResponse>>,
) => {
  const sessionUser: IUser | null = useSelector(selectSessionUser)

  const queryKey = useMemo(
    () => [
      'sellerProducts',
      sessionUser?.id,
      buildGetUrl(apiClient.sellerProducts.endpoint, params),
    ],
    [params, sessionUser?.id],
  )

  const { data, isLoading, isFetched, refetch } = useQuery({
    queryKey,
    queryFn() {
      return apiClient.sellerProducts.get(params)
    },
    enabled: Boolean(sessionUser?.id && sessionUser?.currentScope),
    ...DEFAULT_QUERY_OPTIONS,
    ...options,
  })

  const { deleteSellerProduct, updateSellerProduct, addSellerProduct } =
    useModifySellerProducts(params)

  const sellerProducts = useMemo(
    () => data?.sellerProducts || [],
    [data?.sellerProducts],
  )

  const sellerProductsObj = useMemo(
    () => Object.fromEntries(sellerProducts.map(sp => [sp.id, sp])),
    [sellerProducts],
  )

  const findSellerProductsBySellerId = useCallback(
    (sellerId: number) =>
      sellerProducts.filter(item => item.sellerId === sellerId),
    [sellerProducts],
  )

  const findSellerProductById = useCallback(
    (id: number | null | undefined) =>
      sellerProducts.find(item => item.id === id),
    [sellerProducts],
  )

  const sellerProductOptions = useMemo(
    () =>
      sellerProducts.map(item => {
        const { id, name, code, productUsage } = item
        const label = `${code} - ${name} (${_.lowerCase(productUsage)})`
        return {
          value: id,
          label,
          item,
        }
      }),
    [sellerProducts],
  )

  return {
    sellerProducts,
    sellerProductsObj,
    sellerProductOptions,
    findSellerProductsBySellerId,
    findSellerProductById,
    isLoadingSellerProducts: isLoading,
    addSellerProduct,
    deleteSellerProduct,
    updateSellerProduct,
    refetchSellerProductsData: refetch,
    isSellerProductsFetched: isFetched,
  }
}

export default useQuerySellerProducts
