import { useCallback, useMemo } from 'react'
import { useQueryClient } from 'react-query'
import { useSelector } from 'react-redux'
import { produce } from 'immer'

import { selectSessionUser } from '~/redux/selectors'
import type { IUser } from '~/types/models/IUser'
import { IGetLoadExtrasResponse, ILoadExtra } from '~/types/models/ILoadExtra'

const useModifyLoadExtras = (params = {}) => {
  const queryClient = useQueryClient()

  const sessionUser: IUser | null = useSelector(selectSessionUser)

  const queryKey = useMemo(
    () => ['loadExtras', params, sessionUser?.id],
    [params, sessionUser?.id],
  )

  const createLoadExtra = useCallback(
    (data: ILoadExtra) => {
      queryClient.setQueryData<IGetLoadExtrasResponse | undefined>(
        queryKey,
        oldData =>
          produce(oldData, draft => {
            if (draft) {
              draft.loadExtras.push(data)
            }
          }),
      )
    },
    [queryClient, queryKey],
  )

  const updateLoadExtra = useCallback(
    (data: ILoadExtra) => {
      queryClient.setQueryData<IGetLoadExtrasResponse | undefined>(
        queryKey,
        oldData =>
          produce(oldData, draft => {
            if (draft) {
              const index = draft.loadExtras.findIndex(
                loadExtra => loadExtra.id === data.id,
              )
              draft.loadExtras[index] = data
            }
          }),
      )
    },
    [queryClient, queryKey],
  )

  const deleteLoadExtra = useCallback(
    (id: number) => {
      queryClient.setQueryData<IGetLoadExtrasResponse | undefined>(
        queryKey,
        oldData =>
          produce(oldData, draft => {
            if (draft) {
              draft.loadExtras = draft.loadExtras.filter(
                loadExtra => loadExtra.id !== id,
              )
            }
          }),
      )
    },
    [queryClient, queryKey],
  )

  return { createLoadExtra, updateLoadExtra, deleteLoadExtra }
}

export default useModifyLoadExtras
