import { useCallback, useMemo, useState } from 'react'

import TruckInfoBox from '../TruckInfoBox/TruckInfoBox'

import type { ITruckInfoBoxListProps } from './type'
import type { ITruckInfoBoxOnClickParams } from '../TruckInfoBox/type'
import type { ITruck } from '~/types/models/ITruck'
import type { IDriverFleet } from '~/types/models/IDriverFleet'
import { useQueryLocations } from '~/hooks/useQueryData'
import { ELocationableType } from '~/types/enums/ELocation'

const useTruckInfoBoxList = (props: ITruckInfoBoxListProps) => {
  const { trucks, onClickTruck } = props

  const [selectedSchedule, setSelectedSchedule] = useState({
    isOpen: false,
    truck: null as ITruck | null,
    driverFleet: null as IDriverFleet | null | undefined,
  })

  const { findLocationByLocationableId, isLocationsDataFetched } =
    useQueryLocations(
      {
        filters: {
          locationableId: trucks.map(({ id }) => id),
          locationableType: ELocationableType.Truck,
        },
      },
      { enabled: trucks.length > 0 },
    )

  const selectedTruck = useMemo(
    () => selectedSchedule.truck,
    [selectedSchedule.truck],
  )

  const onClickTruckInfo = useCallback(
    (params: ITruckInfoBoxOnClickParams) => {
      const { driverFleet, isOpenInfoBox, truck } = params
      setSelectedSchedule({
        isOpen: isOpenInfoBox,
        driverFleet,
        truck,
      })
      onClickTruck && onClickTruck(params)
    },
    [onClickTruck],
  )

  const renderTrucks = useMemo(() => {
    if (isLocationsDataFetched) {
      if (selectedTruck) {
        const location = findLocationByLocationableId(selectedTruck.id)
        return (
          <TruckInfoBox
            truck={selectedTruck}
            onClick={onClickTruckInfo}
            isOpenInfoBox
            location={location}
          />
        )
      }
      return trucks.map(truck => {
        const location = findLocationByLocationableId(truck.id)
        if (Number(location?.lat || 0) > 0 || Number(location?.lng || 0) > 0) {
          return (
            <TruckInfoBox
              key={truck.id}
              truck={truck}
              location={location}
              onClick={onClickTruckInfo}
            />
          )
        }
      })
    }
    return null
  }, [
    findLocationByLocationableId,
    isLocationsDataFetched,
    onClickTruckInfo,
    selectedTruck,
    trucks,
  ])

  return { renderTrucks, selectedSchedule }
}

export default useTruckInfoBoxList
