import { useQueryLoads } from '../useQueryLoads'
import { useQuerySchedules } from '../useQuerySchedules'
import { useQueryTerminals } from '../useQueryTerminals'
import { useQueryTruckFleets } from '../useQueryTruckFleets'
import { useQueryUserInfo } from '../useQueryUserInfo'
import { useSelector } from 'react-redux'
import { selectCurrentScope } from '~/redux/selectors'
import { EScope } from '~/types/enums/ECommonEnum'

const useCustomQueryDataOfDriver = () => {
  const currentScope: EScope = useSelector(selectCurrentScope)

  const {
    userInfo,
    refetchUserInfo,
    updateDriverFleet,
    isUserInfoFetched,
    isUserInfoLoading,
  } = useQueryUserInfo({
    enabled: currentScope === EScope.driverFleet,
  })
  const {
    loadsData: [loadData],
    updateLoad,
    isLoadsDataFetched,
  } = useQueryLoads(
    {
      id: userInfo?.driverFleet?.loadId as number,
    },
    { enabled: Boolean(userInfo?.driverFleet?.loadId) },
  )
  const {
    truckFleetsData: [currentTruckFleet],
    updateTruck,
    isLoadingTruckFleetsData,
  } = useQueryTruckFleets(
    {
      id: userInfo?.driverFleet?.currentTruckFleetId as number,
    },
    { enabled: Boolean(userInfo?.driverFleet?.currentTruckFleetId) },
  )
  const {
    schedulesData: [scheduleData],
  } = useQuerySchedules(
    {
      id: loadData?.scheduleId,
    } as any,
    { enabled: Boolean(loadData?.scheduleId) },
  )

  const { findTerminalById } = useQueryTerminals()

  const truckFleetTerminal = findTerminalById(currentTruckFleet?.terminalId)

  return {
    userInfo,
    currentTruckFleet,
    truckFleetTerminal,
    scheduleData,
    loadData,
    isLoadsDataFetched,
    isUserInfoFetched,
    isUserInfoLoading,
    isLoadingTruckFleetsData,
    updateLoad,
    refetchUserInfo,
    updateDriverFleet,
    updateTruck,
  }
}

export default useCustomQueryDataOfDriver
