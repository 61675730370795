import moment from 'moment'
import { REGEX_SPLIT_TWO_DOTS_BETWEEN_ITEMS } from './constants'

const isInfinity = (input: string) => input.includes('Infinity')

export const getPricingDate = (dateRange: string | null) => {
  if (dateRange) {
    const matches = dateRange.match(REGEX_SPLIT_TWO_DOTS_BETWEEN_ITEMS)
    if (matches) {
      const startDate = matches[1]
      const endDate = matches[3]

      return {
        startDate: startDate
          ? isInfinity(startDate)
            ? null
            : startDate
          : null,
        endDate: endDate ? (isInfinity(endDate) ? null : endDate) : null,
      }
    }
  }

  return null
}

const formatPricingDate = (dateRange: string) => {
  const pricingDate = getPricingDate(dateRange)
  if (pricingDate) {
    const { startDate, endDate } = pricingDate
    const format = 'MMM-D-YY'
    if (startDate && endDate) {
      return `${moment(startDate).format(format)} to ${moment(endDate).format(
        format,
      )}`
    }
    if (startDate) {
      return moment(startDate).format(format)
    }
    if (endDate) {
      return moment(endDate).format(format)
    }
  }

  return 'All'
}

export default formatPricingDate
