import defaultModuleFilters from '~/constants/defaultModuleFilters'
import { createSlice } from '@reduxjs/toolkit'
import DEFAULT_INVOICES_COLUMNS from '~/constants/invoices/columns'

const controls = [
  'filters',
  'page',
  'perPage',
  'searchWord',
  'sortOptions',
  'source',
  'totalCount',
  'sorting',
]

export const invoicesUiSlice = createSlice({
  name: 'invoices',
  initialState: {
    loading: false,
    error: null,
    canLoadMore: false,
    loadingMore: false,
    startRange: 0,
    endRange: 0,
    pageCount: 0,
    dataLoaded: false,
    lastUpdatedAt: Date.now(),
    filtersInitialLoad: true,
    control: {
      page: 1,
      perPage: 24,
      totalCount: 0,
      filters: defaultModuleFilters.invoices,
      sortOptions: [],
      searchWord: '',
      source: 'Invoice',
      columns: DEFAULT_INVOICES_COLUMNS,
      sorting: [
        {
          label: 'Number',
          sortField: 'number',
          isAsc: true,
          sorted: true,
        },
        {
          label: 'Buyer Name',
          sortField: 'buyer.name',
          isAsc: false,
          sorted: false,
        },
        {
          label: 'Buyer Code',
          sortField: 'buyer.code',
          isAsc: false,
          sorted: false,
        },
        {
          label: 'Seller Name',
          sortField: 'seller.name',
          isAsc: false,
          sorted: false,
        },
        {
          label: 'Seller Code',
          sortField: 'seller.code',
          isAsc: false,
          sorted: false,
        },
        {
          label: 'Total',
          sortField: 'total',
          isAsc: false,
          sorted: false,
        },
        {
          label: 'Qty',
          sortField: 'qty',
          isAsc: false,
          sorted: false,
        },
        {
          label: 'Loads',
          sortField: 'loadCount',
          isAsc: false,
          sorted: false,
        },
        {
          label: 'Invoice Period',
          sortField: 'endDate',
          isAsc: false,
          sorted: false,
        },
        {
          label: 'Due Date',
          sortField: 'dueDate',
          isAsc: false,
          sorted: false,
        },
        {
          label: 'Created At',
          sortField: 'createdAt',
          isAsc: false,
          sorted: false,
        },
      ],
    },
    isFiltersInitialized: false,
    isFilterConfigured: false,
  },
  reducers: {
    updateUi: (state, action) => {
      Object.entries(action.payload).forEach(([key, value]) => {
        controls.includes(key)
          ? (state.control[key] = value)
          : (state[key] = value)
      })
      state['dataLoaded'] = false
    },
    updateUiFilter: (state, action) => {
      state.control.filters = { ...state.control.filters, ...action.payload }
      state.filtersInitialLoad = false
    },
    updateUiSortOptions: (state, action) => {
      action.payload.forEach(sort => {
        const index = state.control.sortOptions.findIndex(
          ({ value }) => value === sort.value,
        )
        if (index !== -1)
          return (state.control.sortOptions[index] = {
            ...state.control.sortOptions[index],
            ...sort,
          })

        state.control.sortOptions.push(sort)
      })
    },
    updateUiColumns: (state, action) => {
      let columns = state.control['columns']

      action.payload.forEach(col => {
        let updateIndex = columns.findIndex(
          columnState => col.sortField == columnState.sortField,
        )

        if (updateIndex === -1) {
          state.control['columns'].push(...col)

          return
        }

        state.control['columns'][updateIndex] = {
          ...state.control['columns'][updateIndex],
          ...col,
        }
      })
    },
  },
})

export default invoicesUiSlice
