import { useCallback } from 'react'

import {
  EAllowedHierarchyBuyerSideType,
  EAllowedHierarchySellerSideType,
} from '~/types/enums/EAllowedHierarchy'
import { useQueryCompanies, useQueryTerminals } from './useQueryData'

const useGetSideOptions = () => {
  const { sellerCompanyOptions, buyerCompanyOptions } = useQueryCompanies({})
  const { sellerTerminalOptions, buyerTerminalOptions } = useQueryTerminals()

  const getSellerSideOptions = useCallback(
    (sideType: string) => {
      switch (sideType) {
        case EAllowedHierarchySellerSideType.Seller: {
          return sellerCompanyOptions
        }
        case EAllowedHierarchySellerSideType.SellerTerminal: {
          return sellerTerminalOptions
        }
        default:
          return []
      }
    },
    [sellerCompanyOptions, sellerTerminalOptions],
  )

  const getBuyerSideOptions = useCallback(
    (sideType: string) => {
      switch (sideType) {
        case EAllowedHierarchyBuyerSideType.Buyer: {
          return buyerCompanyOptions
        }
        case EAllowedHierarchyBuyerSideType.BuyerTerminal: {
          return buyerTerminalOptions
        }
        default:
          return []
      }
    },
    [buyerCompanyOptions, buyerTerminalOptions],
  )

  return {
    getSellerSideOptions,
    getBuyerSideOptions,
  }
}

export default useGetSideOptions
