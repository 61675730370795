import { useCallback, useMemo } from 'react'
import { useQuery, UseQueryOptions } from 'react-query'
import { useSelector } from 'react-redux'

import { buildGetUrl } from '~/utils/buildUrl'
import { apiClient } from '~/api/ApiClient'
import { selectMyCurrentCompany, selectSessionUser } from '~/redux/selectors'

import type { IUser } from '~/types/models/IUser'
import type { ICompany, IGetCompaniesParams } from '~/types/models/ICompany'
import { EYesNo } from '~/types/enums/ECommonEnum'
import useModifyCompanies from './useModifyCompanies'
import { useQueryBuyerSellers } from '../useQueryBuyerSellers'
import _ from 'lodash'
import { DEFAULT_QUERY_OPTIONS } from '../constants'
import buildObjectName from '~/utils/buildObjectName'

const useQueryCompanies = (
  params: IGetCompaniesParams & {
    id?: number
    enableFetchingBuyerSeller?: boolean
  },
  options?: Partial<UseQueryOptions<ICompany[]>>,
) => {
  const sessionUser: IUser | null = useSelector(selectSessionUser)
  const currentCompany: ICompany = useSelector(selectMyCurrentCompany)

  const { data, isLoading, refetch, isFetched } = useQuery({
    queryKey: [
      'companies',
      sessionUser?.id,
      buildGetUrl(apiClient.companies.endpoint, params),
    ],
    async queryFn() {
      if (params?.id) {
        const response = await apiClient.companies.getById(params.id)
        return [response]
      }
      const response = await apiClient.companies.get(params)
      return response.companies
    },
    enabled: Boolean(sessionUser?.id),
    ...DEFAULT_QUERY_OPTIONS,
    ...options,
  })

  const { addCompany, updateCompany, removeCompany } =
    useModifyCompanies(params)

  const { buyerSellersData } = useQueryBuyerSellers(
    {},
    {
      enabled: params?.enableFetchingBuyerSeller,
    },
  )

  const companiesData = useMemo(() => data || [], [data])
  const companiesObj = useMemo(
    () =>
      Object.fromEntries(companiesData.map(company => [company.id, company])),
    [companiesData],
  )
  const companiesDataWithoutBuyerSeller = useMemo(() => {
    if (params?.enableFetchingBuyerSeller) {
      return companiesData.filter(
        company =>
          !buyerSellersData.some(bs => bs.buyerId === company.id) &&
          !buyerSellersData.some(bs => bs.sellerId === company.id),
      )
    }
    return []
  }, [params?.enableFetchingBuyerSeller, companiesData, buyerSellersData])

  const companiesDataWithoutCurrentCompany = useMemo(
    () => companiesData.filter(company => company.id !== currentCompany.id),
    [companiesData, currentCompany.id],
  )

  const companyOptions = useMemo(() => {
    const options = companiesData.map(({ id, code, name, logo }) => ({
      value: id,
      label: buildObjectName({ code, name }),
      logo,
      code,
      name,
    }))

    return _.orderBy(options, ['code'], ['asc'])
  }, [companiesData])

  const sellerCompanies = useMemo(
    () => companiesData.filter(({ seller }) => seller === EYesNo.Yes),
    [companiesData],
  )

  const otherSellerCompanies = useMemo(
    () => sellerCompanies.filter(({ id }) => id !== currentCompany.id),
    [currentCompany.id, sellerCompanies],
  )

  const buyerCompanies = useMemo(
    () => companiesData.filter(({ buyer }) => buyer === EYesNo.Yes),
    [companiesData],
  )

  const fleetCompanies = useMemo(
    () => companiesData.filter(({ fleet }) => fleet === EYesNo.Yes),
    [companiesData],
  )

  const fleetCompanyOptions = useMemo(() => {
    const options = fleetCompanies.map(({ id, code, name, logo }) => ({
      value: id,
      label: `${code} - ${name}`,
      logo,
      code,
      name,
    }))
    return _.orderBy(options, ['code'], ['asc'])
  }, [fleetCompanies])

  const sellerCompanyOptions = useMemo(() => {
    const options = sellerCompanies.map(({ id, code, name, logo }) => ({
      value: id,
      label: `${code} - ${name}`,
      logo,
      code,
      name,
    }))

    return _.orderBy(options, ['code'], ['asc'])
  }, [sellerCompanies])

  const buyerCompanyOptions = useMemo(() => {
    const options = buyerCompanies.map(({ id, code, name, logo }) => ({
      value: id,
      label: `${code} - ${name}`,
      logo,
      code,
      name,
    }))

    return _.orderBy(options, ['code'], ['asc'])
  }, [buyerCompanies])

  const findCompanyById = useCallback(
    (companyId: number | undefined | null) =>
      companiesData.find(({ id }) => id === companyId),
    [companiesData],
  )

  return {
    companiesData,
    companiesObj,
    companyOptions,
    isLoadingCompaniesData: isLoading,
    fleetCompanyOptions,
    sellerCompanyOptions,
    buyerCompanyOptions,
    sellerCompanies,
    buyerCompanies,
    fleetCompanies,
    companiesDataWithoutBuyerSeller,
    isCompaniesDataFetched: isFetched,
    companiesDataWithoutCurrentCompany,
    otherSellerCompanies,
    addCompany,
    updateCompany,
    removeCompany,
    findCompanyById,
    refetchCompaniesData: refetch,
  }
}

export default useQueryCompanies
