import { useCallback, useMemo } from 'react'
import { useQuery, UseQueryOptions } from 'react-query'
import { useSelector } from 'react-redux'
import useModifyTerminals from './useModifyTerminals'

import { apiClient } from '~/api/ApiClient'
import {
  selectCurrentScope,
  selectMyCurrentCompany,
  selectSessionUser,
} from '~/redux/selectors'
import { buildGetUrl } from '~/utils/buildUrl'

import type { IUser } from '~/types/models/IUser'
import type { IGetTerminalsParams, ITerminal } from '~/types/models/ITerminal'
import { EScope } from '~/types/enums/ECommonEnum'
import { ICompany } from '~/types/models/ICompany'
import { useQueryCompanies } from '../useQueryCompanies'
import buildObjectName from '~/utils/buildObjectName'
import { DEFAULT_QUERY_OPTIONS } from '../constants'
import { ILocation } from '~/types/models/ILocation'

const useQueryTerminals = (
  params: Partial<IGetTerminalsParams & { id?: number }> = {},
  options?: Partial<UseQueryOptions<ITerminal[]>>,
) => {
  const sessionUser: IUser | null = useSelector(selectSessionUser)
  const currentScope: EScope = useSelector(selectCurrentScope)
  const currentCompany: ICompany = useSelector(selectMyCurrentCompany)

  const isBuyer = currentScope === EScope.buyer

  const { otherSellerCompanies } = useQueryCompanies({})

  const otherSellerCompanyIds = otherSellerCompanies.map(({ id }) => id)

  const { data, isLoading, isFetched, refetch } = useQuery({
    queryKey: [
      'terminals',
      sessionUser?.id,
      buildGetUrl(apiClient.terminals.endpoint, params),
    ],
    async queryFn() {
      if (params.id) {
        const response = await apiClient.terminals.getById(params.id, {
          includeAddress: true,
          includeGeofence: true,
          includeLocation: true,
        })
        return [response]
      }
      const response = await apiClient.terminals.get({
        includeGeofence: true,
        includeLocation: true,
        includeAddress: true,
        ...params,
      })
      return response
    },
    enabled: Boolean(sessionUser?.id),
    ...DEFAULT_QUERY_OPTIONS,
    ...options,
  })

  const terminalsData = useMemo(() => data || [], [data])

  const terminalLocations = useMemo(
    () =>
      terminalsData
        .map(({ location }) => location as ILocation)
        .filter(Boolean),
    [terminalsData],
  )

  const terminalObjs = useMemo(
    () => Object.fromEntries(terminalsData.map(t => [t.id, t])),
    [terminalsData],
  )

  const companyTerminals = useMemo(
    () =>
      terminalsData.filter(({ companyId }) => companyId === currentCompany.id),
    [currentCompany.id, terminalsData],
  )

  const companyTerminalOptions = useMemo(
    () =>
      companyTerminals.map(item => {
        const { id, name, code, companyId, checkUid } = item
        const label = buildObjectName(item)
        return {
          label: label,
          value: id,
          code,
          name,
          companyId,
          checkUid,
          item,
        }
      }),
    [companyTerminals],
  )

  const otherSellerTerminals = useMemo(
    () =>
      terminalsData.filter(({ companyId }) =>
        otherSellerCompanyIds.includes(companyId),
      ),
    [otherSellerCompanyIds, terminalsData],
  )

  const otherSellerTerminalOptions = useMemo(
    () =>
      otherSellerTerminals.map(({ id, name, code, companyId }) => {
        return {
          label: buildObjectName({
            name,
            code,
          }),
          value: id,
          companyId,
          code,
          name,
        }
      }),
    [otherSellerTerminals],
  )

  const sellerTerminalsData = useMemo(() => {
    if (isBuyer) {
      return terminalsData.filter(
        ({ companyId }) => companyId !== currentCompany.id,
      )
    }
    return terminalsData.filter(
      ({ companyId }) => companyId === currentCompany.id,
    )
  }, [currentCompany.id, isBuyer, terminalsData])

  const buyerTerminalsData = useMemo(() => {
    if (isBuyer) {
      return terminalsData.filter(
        ({ companyId }) => companyId === currentCompany.id,
      )
    }
    return terminalsData.filter(
      ({ companyId }) => companyId !== currentCompany.id,
    )
  }, [currentCompany.id, isBuyer, terminalsData])

  const terminalOptions = useMemo(
    () =>
      terminalsData.map(({ id, code, name, companyId }) => {
        return {
          label: buildObjectName({ name, code }),
          value: id,
          companyId,
        }
      }),
    [terminalsData],
  )

  const sellerTerminalOptions = useMemo(
    () =>
      sellerTerminalsData.map(({ id, code, name, companyId }) => {
        return {
          label: buildObjectName({ name, code }),
          value: id,
          companyId,
          code,
          name,
        }
      }),
    [sellerTerminalsData],
  )

  const buyerTerminalOptions = useMemo(
    () =>
      buyerTerminalsData.map(({ id, code, name, companyId }) => {
        return {
          label: buildObjectName({ name, code }),
          value: id,
          companyId,
          code,
          name,
        }
      }),
    [buyerTerminalsData],
  )

  const terminalsHavingCheckUid = useMemo(
    () => terminalsData.filter(({ checkUid }) => checkUid),
    [terminalsData],
  )

  const companyTerminalsHavingCheckUid = useMemo(
    () => companyTerminals.filter(({ checkUid }) => checkUid),
    [companyTerminals],
  )

  const companyTerminalsWithoutCheckUid = useMemo(
    () => companyTerminals.filter(({ checkUid }) => !checkUid),
    [companyTerminals],
  )

  const { addTerminal, updateTerminal, removeTerminal } =
    useModifyTerminals(params)

  const findTerminalById = useCallback(
    (id: number | null | undefined) => terminalsData.find(t => t.id === id),
    [terminalsData],
  )

  return {
    terminalsData,
    companyTerminals,
    isLoadingTerminals: isLoading,
    terminalOptions,
    isFetchedTerminals: isFetched,
    terminalsHavingCheckUid,
    buyerTerminalOptions,
    sellerTerminalOptions,
    sellerTerminalsData,
    buyerTerminalsData,
    terminalObjs,
    companyTerminalsHavingCheckUid,
    companyTerminalsWithoutCheckUid,
    findTerminalById,
    addTerminal,
    updateTerminal,
    removeTerminal,
    companyTerminalOptions,
    otherSellerTerminals,
    refetchTerminalsData: refetch,
    otherSellerTerminalOptions,
    terminalLocations,
  }
}

export default useQueryTerminals
