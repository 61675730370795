export enum EScheduleStatusGroup {
  Upcoming = 'Upcoming',
  Current = 'Current',
  Complete = 'Complete',
  Cancelled = 'Canceled',
  All = 'All',
  Active = 'Active',
}

export enum EScheduleView {
  leftOrderRightDeliveries = 'leftOrderRightDeliveries',
  columnStructure = 'columnStructure',
  trucks = 'trucks',
}
