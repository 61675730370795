import { DEFAULT_ICON_SIZE } from './constants'

import type { ISvgIconProps } from './type'

const DollarIcon = (props: ISvgIconProps) => {
  const { size = DEFAULT_ICON_SIZE, color = '#000', ...svgProps } = props

  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      fill='none'
      viewBox='0 0 24 24'
      {...svgProps}
    >
      <circle cx={12} cy={12} r={10} stroke={color} strokeWidth={1.5} />
      <path
        stroke={color}
        strokeLinecap='round'
        strokeWidth={1.5}
        d='M12 17v1M12 6v1M15 9.5C15 8.12 13.657 7 12 7S9 8.12 9 9.5s1.343 2.5 3 2.5 3 1.12 3 2.5-1.343 2.5-3 2.5-3-1.12-3-2.5'
      />
    </svg>
  )
}

export default DollarIcon
