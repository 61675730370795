import { IonSpinner } from '@ionic/react'

import type { IUniversalLoadingPageProps } from './type'

import './styles.scss'
import Body from '../Body'

function UniversalLoadingPage(props: IUniversalLoadingPageProps) {
  const { isWrappedByBody, description } = props

  if (isWrappedByBody) {
    return (
      <Body>
        <div className='UniversalLoadingPage__container'>
          <img src='/logo.png' />
          <IonSpinner name='dots' />
        </div>
      </Body>
    )
  }

  return (
    <div className='UniversalLoadingPage__container'>
      <img src='/logo.png' />
      <IonSpinner name='dots' />
      {description && (
        <div style={{ fontSize: 14, fontWeight: 600, marginTop: 8 }}>
          {description}
        </div>
      )}
    </div>
  )
}
export default UniversalLoadingPage
