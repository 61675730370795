import { ICommonModel, ICommonPostResponse } from './ICommonModel'

export enum EAutoChargeDateRangeType {
  dateRange = 'date_range',
  seasonal = 'seasonal',
}

export interface IAutoCharge extends ICommonModel {
  qty: number | null
  sellerProductId: number
  dateRange: string
  dateRangeType: EAutoChargeDateRangeType
  active: boolean
  companyId: number
}

export interface IGetAutoChargesResponse {
  autoCharges: IAutoCharge[]
}

export type IAutoChargeFormData = Pick<
  IAutoCharge,
  'qty' | 'dateRange' | 'sellerProductId' | 'dateRangeType' | 'active'
> & {
  id?: number
}
export interface IGetAutoChargesParams {
  filters?: {
    active?: boolean
    companyId?: number
    dateRangeType?: EAutoChargeDateRangeType
    dateRange?: string
    qty?: number
    sellerProductId?: number
    searchWord?: string
  }
}

export interface ICreateAutoChargePayload
  extends Omit<IAutoCharge, keyof ICommonModel> {}
export interface ICreateAutoChargeResponse extends IAutoCharge {
  errors: string[]
}

export interface IUpdateAutoChargeResponse extends IAutoCharge {
  errors: string[]
}

export interface IDeleteAutoChargeResponse
  extends ICommonPostResponse,
    IAutoCharge {}

export interface IUpdateAutoChargePayload {
  autoCharge: Partial<IAutoCharge>
}

export interface IAutoChargeFilter
  extends Pick<
    IAutoCharge,
    'qty' | 'sellerProductId' | 'active' | 'companyId' | 'dateRange'
  > {}
