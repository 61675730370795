import React, { useEffect, useRef, useState } from 'react'

import ReactDatePicker from 'react-datepicker'

import clsx from 'clsx'

import './styles.scss'
import moment from 'moment'
import { toast } from 'react-toastify'
import { selectMyCurrentCompany } from '~/redux/selectors'
import { useSelector } from 'react-redux'
import { ICompany } from '~/types/models/ICompany'
import { ClockIcon, SearchIcon } from '../SvgIcons'
import { Form } from 'react-bootstrap'
import { DropdownWithCustomChildren } from '../ConcordForm'
import TimeField from 'react-simple-timefield'

const INTERVAL_OPTIONS = [
  {
    label: '1 min',
    value: 1,
  },
  {
    label: '2 min',
    value: 2,
  },
  {
    label: '5 min',
    value: 5,
  },
  {
    label: '10 min',
    value: 10,
  },
  {
    label: '15 min',
    value: 15,
  },
  {
    label: '20 min',
    value: 20,
  },
  {
    label: '30 min',
    value: 30,
  },
  {
    label: '1 hr',
    value: 60,
  },
]

export interface IReusableTimeFieldProps {
  className?: string
  inputClassName?: string
  isDisabled?: boolean
  isReadOnly?: boolean
  value?: string | Date | null
  placeholder?: string
  onChange?: (date: string | null) => void
  customInput?: JSX.Element
  portalId?: string
  onBlur?: () => void
  onKeyDown?: (event: React.KeyboardEvent<HTMLElement>) => void
}

function findNearestTime(inputTime: string, interval: number) {
  // Handle null, undefined, or empty string input
  if (!inputTime || inputTime.trim() === '') {
    return '00:00'
  }

  // Parse the input time (HH:mm format)
  const [hours, minutes] = inputTime.split(':').map(Number)

  // Handle invalid time input
  if (isNaN(hours) || isNaN(minutes)) {
    return '00:00'
  }

  // Convert the input time to total minutes
  const totalMinutes = hours * 60 + minutes

  // Calculate the nearest multiple of the interval
  const roundedMinutes = Math.round(totalMinutes / interval) * interval

  // Convert the rounded minutes back to hours and minutes
  const nearestHours = Math.floor(roundedMinutes / 60) % 24 // Use % 24 to wrap around after midnight
  const nearestMinutes = roundedMinutes % 60

  // Format the result as HH:mm
  return (
    nearestHours.toString().padStart(2, '0') +
    ':' +
    nearestMinutes.toString().padStart(2, '0')
  )
}

function ReusableTimeField(props: IReusableTimeFieldProps, ref: any) {
  const {
    className,
    inputClassName,
    isDisabled,
    isReadOnly,
    value,
    customInput,
    onChange,
    portalId,
    onBlur,
    onKeyDown,
  } = props

  const [time, setTime] = useState<Date | null | undefined>(null)
  const [interval, setInterval] = useState(5)
  const [searchValue, setSearchValue] = useState('')
  const timePickerRef = useRef<ReactDatePicker | null>()

  const currentCompany: ICompany = useSelector(selectMyCurrentCompany)

  const timeFormat = ['H:mm', 'h:mm aa'].includes(
    currentCompany.timeFormat as string,
  )
    ? currentCompany.timeFormat || 'H:mm'
    : 'H:mm'

  const getIntervalLabel = (minutes: number) => {
    if (minutes < 60) {
      return `${minutes} min`
    } else {
      const hours = Math.floor(minutes / 60)
      const remainingMinutes = minutes % 60
      return `${hours} hr${hours > 1 ? 's' : ''} ${
        remainingMinutes > 0 ? remainingMinutes + ' min' : ''
      }`.trim()
    }
  }

  useEffect(() => {
    if (typeof value === 'string') {
      try {
        const newTimeValue = moment(value).toDate()
        setTime(newTimeValue)
        setSearchValue(moment(value).format('HH:mm'))
      } catch (error) {
        console.log('error', error)
        toast.error('Invalid date format')
        setTime(null)
        setSearchValue('00:00')
      }
    } else {
      setTime(value)
      setSearchValue(moment(value || undefined).format('HH:mm'))
    }
  }, [value])

  return (
    <ReactDatePicker
      portalId={portalId}
      wrapperClassName={clsx('ReusableTimeField__datePicker', className)}
      className={clsx('ReusableTimeField__dateInput', inputClassName, {
        isDisabled,
        isReadOnly,
      })}
      popperClassName='ReusableTimeField__popperClassName'
      selected={time}
      onChange={newDate => {
        onChange && onChange(newDate ? newDate.toISOString() : null)
      }}
      ref={node => {
        if (ref?.current) {
          ref!.current = node
        }
        timePickerRef.current = node
      }}
      showTimeCaption={false}
      showTimeSelect
      showTimeSelectOnly
      timeIntervals={interval}
      dateFormat={timeFormat}
      showPopperArrow={false}
      timeFormat={timeFormat}
      showIcon
      customInput={customInput || <Form.Control />}
      icon={<ClockIcon />}
      onBlur={onBlur}
      onKeyDown={onKeyDown}
    >
      <DropdownWithCustomChildren
        options={INTERVAL_OPTIONS}
        isCreatable
        className='no-hover'
        value={interval}
        onChange={(event, { value }) => {
          if (!Number.isNaN(Number(value))) {
            setInterval(Number(value))
          }
        }}
      >
        <div style={{ width: '100%', textAlign: 'center' }}>
          Interval: {getIntervalLabel(interval)}
        </div>
      </DropdownWithCustomChildren>

      <div className='ReusableTimeField__timeInputContainer'>
        <TimeField
          value={searchValue}
          className='ReusableTimeField__timeInput'
          onChange={(event, value) => {
            setSearchValue(value)
            const [hours, minutes] = findNearestTime(value, interval).split(':')
            const newTime = moment(time || undefined)
              .set({
                hours: Number(hours),
                minutes: Number(minutes),
              })
              .toDate()
            setTime(newTime)
            const selectedTimeItem = Array.from(
              document.querySelectorAll('li'),
            ).find(item => item?.textContent?.trim() === `${hours}:${minutes}`)

            if (selectedTimeItem) {
              selectedTimeItem?.scrollIntoView({ block: 'center' })
            }
          }}
        />
        <span className='ReusableTimeField__searchButton'>
          <SearchIcon />
        </span>
      </div>
    </ReactDatePicker>
  )
}

export default React.forwardRef(ReusableTimeField)
