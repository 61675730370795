import { useState, useCallback, useMemo } from 'react'
import { useUpdateEffect } from 'react-use'

import { CommonDialog } from '~/components/shared'
import {
  IonList,
  IonItem,
  IonCheckbox,
  IonLabel,
  IonSearchbar,
  IonSpinner,
} from '@ionic/react'

import _ from 'lodash'
import { produce } from 'immer'
import { toast } from 'react-toastify'
import { selectMyCurrentCompany } from '~/redux/selectors'
import { apiClient } from '~/api/ApiClient'
import { useQueryTerminals } from '~/hooks/useQueryData'

function DialogUserTerminals(props) {
  const { userAccessId, open, ...dialogProps } = props

  const [terminalAccesses, setTerminalAccesses] = useState([])
  const [loading, setLoading] = useState(false)
  const [loadingTerminals, setLoadingTerminals] = useState([]) // number[]
  const [searchKey, setSearchKey] = useState('')

  const myCurrentCompany = selectMyCurrentCompany(selectMyCurrentCompany)

  const { companyTerminals } = useQueryTerminals()

  const filteredTerminalAccesses = useMemo(() => {
    const lowerSearchKey = _.toLower(searchKey)

    return terminalAccesses.filter(({ name, code }) => {
      if (_.size(searchKey) === 0) {
        return true
      }

      const matchedName = _.toLower(name).includes(lowerSearchKey)
      const matchedCode = _.toLower(code).includes(lowerSearchKey)

      return matchedName || matchedCode
    })
  }, [searchKey, terminalAccesses])

  const mapTerminalAccesses = useCallback(
    userTerminals => {
      const newTerminalAccesses = companyTerminals.map(myTerminal => {
        const matchTerminalAccess = userTerminals.find(
          ({ terminalId }) => myTerminal.id === terminalId,
        )

        return {
          ...myTerminal,
          userTerminalId: matchTerminalAccess?.id || null,
        }
      })
      setTerminalAccesses(newTerminalAccesses)
    },
    [companyTerminals],
  )

  const toggleLoadingTerminal = useCallback(terminal => {
    setLoadingTerminals(prev =>
      produce(prev, draft => {
        const index = draft.indexOf(terminal.id)
        if (index === -1) {
          draft.push(terminal.id)
        } else {
          draft.splice(index, 1)
        }
      }),
    )
  }, [])

  const deleteUserTerminalAccess = useCallback(async ({ userTerminalId }) => {
    await apiClient.terminalAccesses.delete(userTerminalId)
    setTerminalAccesses(prev =>
      produce(prev, draft => {
        const index = _.findIndex(draft, { userTerminalId })

        if (index !== -1) {
          draft[index].userTerminalId = null
        }
      }),
    )
  }, [])

  const createUserTerminalAccess = useCallback(
    async terminal => {
      const bodyReq = {
        terminalId: terminal.id,
        userAccessId,
        companyId: myCurrentCompany.id,
      }

      const response = await apiClient.terminalAccesses.create({
        terminalAccess: bodyReq,
      })
      setTerminalAccesses(prev =>
        produce(prev, draft => {
          const index = _.findIndex(draft, { id: terminal.id })

          if (index !== -1) {
            draft[index].userTerminalId = response.id
          }
        }),
      )
    },
    [myCurrentCompany.id, userAccessId],
  )

  const handleChangeTerminalAccess = useCallback(
    terminal => async () => {
      toggleLoadingTerminal(terminal)
      try {
        if (terminal.userTerminalId) {
          await deleteUserTerminalAccess(terminal)
        } else {
          await createUserTerminalAccess(terminal)
        }
      } catch (error) {
        console.log('error', error)
      } finally {
        toggleLoadingTerminal(terminal)
      }
    },
    [createUserTerminalAccess, deleteUserTerminalAccess, toggleLoadingTerminal],
  )

  const getUserTerminals = useCallback(async () => {
    if (!userAccessId) {
      return toast.error('User Access ID is undefined.')
    }
    setLoading(true)
    try {
      const response = await apiClient.terminalAccesses.get({
        filters: {
          userAccessId,
        },
      })
      mapTerminalAccesses(response)
    } catch (error) {
      console.log('error', error)
      toast.error('An error has occurred.')
    } finally {
      setLoading(false)
    }
  }, [mapTerminalAccesses, userAccessId])

  const handleSearch = useCallback(event => {
    setSearchKey(event.detail.value)
  }, [])

  const renderTerminalItems = useCallback(() => {
    return filteredTerminalAccesses.map(terminal => {
      return (
        <IonItem key={terminal.id}>
          <IonLabel>{`${terminal.code} - ${terminal.name}`}</IonLabel>
          {loadingTerminals.includes(terminal.id) && (
            <IonSpinner slot='end' name='lines-small' />
          )}

          {!loadingTerminals.includes(terminal.id) && (
            <IonCheckbox
              slot='end'
              checked={terminal.userTerminalId}
              onClick={handleChangeTerminalAccess(terminal)}
            />
          )}
        </IonItem>
      )
    })
  }, [filteredTerminalAccesses, loadingTerminals, handleChangeTerminalAccess])

  useUpdateEffect(() => {
    if (open) {
      getUserTerminals()
    } else {
      setTerminalAccesses([])
    }
  }, [open])

  return (
    <CommonDialog hideHeader open={open} loading={loading} {...dialogProps}>
      <IonSearchbar onIonChange={handleSearch} />
      <IonList>{renderTerminalItems()}</IonList>
    </CommonDialog>
  )
}

DialogUserTerminals.propTypes = {}

export default DialogUserTerminals
