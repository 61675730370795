import { useCallback, useMemo, useState } from 'react'
import { useController, Control } from 'react-hook-form'
import Select, { Props, StylesConfig } from 'react-select'
import { IonLabel } from '@ionic/react'

import { DialogSellerProductForm } from '../ConcordForm'
import { EditOption } from '../ReactSelect/components/EditOption'
import { CreateButton } from '../ReactSelect/components/CreateButton'
import { useSelectOptionsCombinedBuyerAndSellerProducts } from '~/hooks/useSelectOptionsCombinedBuyerAndSellerProducts'

import { EOrderType } from '~/types/enums/ESellerProduct'
import { ISellerProduct } from '~/types/models/ISellerProduct'

import '~/components/shared/FloatingForm/styles.scss'
import clsx from 'clsx'

const selectErrorStyle: StylesConfig = {
  control: base => ({
    ...base,
    borderColor: 'red',
  }),
  placeholder: base => ({
    ...base,
    color: 'red',
  }),
  menuPortal: provided => ({
    ...provided,
    zIndex: 999999,
  }),
}

const selectStyle: StylesConfig = {
  control: base => ({
    ...base,
    color: 'black',
    backgroundColor: '#f7f7f7',
    borderWidth: '0px',
  }),
  menuPortal: provided => ({
    ...provided,
    zIndex: 999999,
  }),
}

interface IProductSelectProps extends Props {
  buyerId?: number
  sellerId: number
  orderType?: EOrderType[]
  controlName: string
  control: Control
  customStyles?: StylesConfig
}

interface IShowProductForm {
  isOpen: boolean
  defaultValues?: Partial<ISellerProduct>
  shouldHideOptionalFields?: boolean
}

interface ILoadExtraFormBSPOption {
  label: string
  value: number
  code: string
  isSellerProduct?: boolean
}

export const ProductSelect = ({
  buyerId,
  sellerId,
  orderType,
  controlName,
  control,
  customStyles,
  isDisabled,
  isOptionDisabled,
  ...selectProps
}: IProductSelectProps) => {
  const {
    field: { onChange, onBlur, value, ref },
    fieldState: { error },
  } = useController({
    name: controlName,
    control,
  })

  const selectDisplay = error ? selectErrorStyle : selectStyle

  const [isLoading, setIsLoading] = useState(false)
  const [productForm, setProductForm] = useState<IShowProductForm>({
    isOpen: false,
  })

  const {
    productOptions,
    isLoadingOptions,
    sellerProducts,
    selectedValue,
    createBSPfromSP,
    addSellerProduct,
  } = useSelectOptionsCombinedBuyerAndSellerProducts({
    buyerId,
    sellerId,
    ...(orderType && { orderTypes: orderType }),
  })

  const selectedOption = useMemo(
    () => (value && selectedValue(value)) || '',
    [value, selectedValue],
  )

  const onClickCreateProduct = useCallback(
    () =>
      setProductForm({
        isOpen: true,
        defaultValues: { sellerId },
        shouldHideOptionalFields: true,
      }),
    [sellerId],
  )

  const onClickEditSellerProduct = useCallback(
    (sellerProductId: number) => {
      setProductForm({
        isOpen: true,
        defaultValues: sellerProducts[sellerProductId],
      })
    },
    [sellerProducts],
  )

  const onCreateSellerProduct = useCallback(
    async (sp: ISellerProduct) => {
      setProductForm({ isOpen: false })
      addSellerProduct(sp)
    },
    [addSellerProduct],
  )

  return (
    <div className={clsx('FloatingSelectV2_root', { isDisabled })}>
      <IonLabel className='FloatingSelectV2_text'>Product</IonLabel>

      <Select
        className='d-flex'
        options={productOptions}
        placeholder='Select product'
        components={{
          MenuList: CreateButton(onClickCreateProduct),
          Option: EditOption(onClickEditSellerProduct),
          IndicatorSeparator: null,
        }}
        styles={customStyles || selectDisplay}
        onBlur={onBlur}
        isSearchable
        isDisabled={isDisabled}
        onChange={async newValue => {
          setIsLoading(true)
          const e = newValue as ILoadExtraFormBSPOption
          if (e?.isSellerProduct) {
            const res = await createBSPfromSP(e.value)
            res && onChange(res.id)
          } else {
            onChange(e.value)
          }
          setIsLoading(false)
        }}
        value={selectedOption} //if react-hook-form's value DNE, clear the select
        isLoading={isLoading || isLoadingOptions}
        openMenuOnFocus
        ref={ref}
        menuPosition='fixed'
        isOptionDisabled={isOptionDisabled}
        {...selectProps}
      />

      <DialogSellerProductForm
        isOpen={productForm?.isOpen}
        onClose={() => setProductForm({ isOpen: false })}
        afterCreate={onCreateSellerProduct}
        formData={{ sellerId }}
        shouldHideOptionalFields={productForm.shouldHideOptionalFields}
      />
    </div>
  )
}

export default ProductSelect
