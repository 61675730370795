import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { toast } from 'react-toastify'
import * as Yup from 'yup'

import { EFieldType } from '~/types/enums/ECommonEnum'
import { ConcordFormStructure, IConcordFormField } from '../../FormStructure'
import { apiClient } from '~/api/ApiClient'
import { ITestResult, ITestResultTest } from '~/types/models/ITestResult'
import { useQuerySellerProducts } from '~/hooks/useQueryData'

interface Props {
  testResult?: ITestResult
  sellerProductId?: number
  onUpdateTestResult?: (testResult: ITestResult) => void
  onCreateTestResult?: (testResult: ITestResult) => void
}
interface FormValues {
  sellerProductId: number
  name: string
  value: number
  testDate?: string
}

export const TestResultForm = ({
  testResult,
  sellerProductId,
  onUpdateTestResult,
  onCreateTestResult,
}: Props) => {
  const [isLoading, setIsLoading] = useState(false)
  const [tests, setTests] = useState<ITestResultTest[]>([])

  const { sellerProductOptions } = useQuerySellerProducts()
  const formRef = useRef<any>()

  const id = useMemo(() => testResult?.id, [testResult])

  const nameOptions = useMemo(
    () =>
      tests.map(test => ({
        label: `${test.name} - ${test.test}`,
        value: test.name,
      })),
    [tests],
  )

  useEffect(() => {
    const fetch = async () => {
      try {
        const { tests } = await apiClient.testResults.new()
        setTests(tests)
      } catch (error) {
        console.error(error)
      }
    }
    fetch()
  }, [])

  const defaultValues = useMemo<Partial<FormValues>>(
    () => ({
      sellerProductId: sellerProductId,
      name: testResult?.name || '',
      value: testResult?.value ? parseFloat(testResult.value.toString()) : 0,
      testDate: testResult?.testDate || undefined,
    }),
    [sellerProductId, testResult],
  )

  const fields = useMemo<IConcordFormField[]>(
    () => [
      {
        name: 'sellerProductId',
        label: 'Seller Product',
        type: EFieldType.singleSelect,
        isRequired: true,
        isReadOnly: sellerProductId ? true : false,
        isHidden: sellerProductId ? true : false,
        options: sellerProductOptions,
        size: 6,
      },
      {
        name: 'name',
        label: 'Test Result Name',
        type: EFieldType.singleSelect,
        isRequired: true,
        options: nameOptions,
        size: 6,
      },
      {
        name: 'value',
        label: 'Value',
        type: EFieldType.number,
        isRequired: true,
        size: 6,
      },
      {
        name: 'testDate',
        label: 'Test Date',
        type: EFieldType.date,
        size: 6,
      },
    ],
    [nameOptions, sellerProductId, sellerProductOptions],
  )

  const schema = useMemo(
    () =>
      Yup.object({
        sellerProductId: Yup.number().required('Seller Product is required'),
        name: Yup.string().required('Name is required'),
        testDate: Yup.string().nullable(),
        value: Yup.number()
          .required('Value is required')
          .test(
            'is-within-tests',
            d => `${d.path} must be within the tests`,
            (value, { createError, path, parent: { name } }) => {
              const currentTest = tests.find(test => test.name === name)
              if (currentTest) {
                const [lower, upper] = currentTest.values.split('..')
                if (value < parseFloat(lower) || value > parseFloat(upper)) {
                  return createError({
                    path,
                    message: `${value} is not within the range ${currentTest.values} of ${currentTest.name}`,
                  })
                }
              }
              return true
            },
          ),
      }),
    [tests],
  )

  useEffect(() => {
    if (formRef.current) {
      formRef.current.setValue('sellerProductId', sellerProductId)
    }
  }, [sellerProductId])

  useEffect(() => {
    if (formRef.current && testResult?.name && testResult?.value) {
      formRef.current.setValue('name', testResult?.name)
      formRef.current.setValue(
        'value',
        parseFloat(testResult?.value.toString()),
      )
    }
  }, [testResult])

  const handleSubmit = useCallback(
    async (formValues: FormValues) => {
      setIsLoading(true)
      const testResultPayload = {
        ...formValues,
      }
      try {
        if (id) {
          const { errors, ...testResult } = await apiClient.testResults.update({
            ...testResultPayload,
            id,
          })

          if (errors && errors.length > 0) {
            toast.error(errors.join(', '))
          } else {
            onUpdateTestResult && onUpdateTestResult(testResult)
            toast.success('Test Result updated successfully')
          }
        } else {
          const { errors, ...testResult } = await apiClient.testResults.create(
            testResultPayload,
          )
          if (errors && errors.length > 0) {
            toast.error(errors.join(', '))
          } else {
            onCreateTestResult && onCreateTestResult(testResult)
            toast.success('Test Result created successfully')
          }
        }
      } catch (error) {
        console.log('error', error)
      } finally {
        setIsLoading(false)
      }
    },
    [id, onUpdateTestResult, onCreateTestResult],
  )
  return (
    <ConcordFormStructure
      isLoading={isLoading}
      fields={fields}
      defaultValues={defaultValues}
      schema={schema}
      ref={formRef}
      isHiddenCancelButton
      isHiddenSearch
      onSubmit={handleSubmit}
      submitText={id ? 'Update' : 'Create'}
      autoHideCRUDBtn
    />
  )
}
