import { useQueryCompanies } from '~/hooks/useQueryData'

import { ConcordFormDropdown } from '~/components/shared'

import clsx from 'clsx'

function ConcordFormCommonFleetDropdown(props) {
  const { className, ...dropdownProps } = props

  const { fleetCompanyOptions, isLoadingCompaniesData } = useQueryCompanies({})

  return (
    <ConcordFormDropdown
      options={fleetCompanyOptions}
      isLoading={isLoadingCompaniesData}
      className={clsx('remove-border-from-common-seller-dropdown', className)}
      styles={{
        menuList: provided => ({
          ...provided,
          maxHeight: 150,
        }),
      }}
      placement='top'
      {...dropdownProps}
    />
  )
}

ConcordFormCommonFleetDropdown.propTypes = {}

export default ConcordFormCommonFleetDropdown
