import { DEFAULT_ICON_SIZE } from './constants'

import type { ISvgIconProps } from './type'

const ZoomIcon = (props: ISvgIconProps) => {
  const { size = DEFAULT_ICON_SIZE, color = '#000', ...svgProps } = props

  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      fill='none'
      viewBox='0 0 16 16'
      {...svgProps}
    >
      <path
        fill={color}
        fillRule='evenodd'
        d='M11.192 12.606a7 7 0 1 1 1.414-1.414l3.101 3.1-1.414 1.415-3.1-3.1ZM4 6h2V4h2v2h2v2H8v2H6V8H4V6Z'
        clipRule='evenodd'
      />
    </svg>
  )
}

export default ZoomIcon
