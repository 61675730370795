type IParam =
  | null
  | undefined
  | string
  | Date
  | { startDate: string | Date; endDate: string | Date }

function getDateRange(
  input: IParam,
): { startDate: Date; endDate: Date } | undefined {
  if (input === null || input === undefined) {
    return undefined
  }

  if (typeof input === 'object' && 'startDate' in input && 'endDate' in input) {
    if (input.startDate && input.endDate) {
      const startDate = new Date(input.startDate)
      const endDate = new Date(input.endDate)

      if (isNaN(startDate.getTime()) || isNaN(endDate.getTime())) {
        return undefined // Nếu bất kỳ ngày nào không hợp lệ, trả về undefined
      }

      return { startDate, endDate }
    }
    return undefined
  }

  if (input instanceof Date) {
    return { startDate: input, endDate: input }
  }

  if (typeof input === 'string') {
    const rangeRegex = /^(.*?)(\.\.\.|\.\.)(.*?)$/

    if (rangeRegex.test(input)) {
      const [, start, , end] = input.match(rangeRegex) || []
      const startDate = new Date(start)
      const endDate = new Date(end)

      if (isNaN(startDate.getTime()) || isNaN(endDate.getTime())) {
        return undefined
      }

      return { startDate, endDate }
    }

    const singleDate = new Date(input)
    if (!isNaN(singleDate.getTime())) {
      return { startDate: singleDate, endDate: singleDate }
    }
  }

  return undefined
}

export default getDateRange
