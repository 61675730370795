import _ from 'lodash'
import { ERTDisplayColumnId } from '~/types/enums/ECommonEnum'

const mapExportDataFromColumns = (columns, data) => {
  try {
    if (columns.length === 0) {
      return []
    }
    const result = []
    data.forEach(item => {
      const object = {}
      columns
        .filter(({ hide, accessor }) => hide !== true && accessor !== 'menu')
        .forEach(
          ({
            header,
            exportValueGetter,
            accessorKey,
            exportable,
            accessorFn,
            id,
          }) => {
            let value = _.get(item, accessorKey) || ''

            if (_.isFunction(accessorFn)) {
              value = accessorFn(item)
            }

            if (_.isFunction(exportValueGetter)) {
              value = exportValueGetter(item)
            }

            if (
              exportable !== false &&
              ![
                ERTDisplayColumnId.select,
                ERTDisplayColumnId.actions,
                ERTDisplayColumnId.expand,
              ].includes(id)
            ) {
              object[header] = value
            }
          },
        )
      result.push(object)
    })

    return result
  } catch (error) {
    console.log('error', error)

    return []
  }
}

export default mapExportDataFromColumns
