import { useState, useCallback, useEffect, useMemo } from 'react'

import { CommonDialogV2, ViewPDF } from '~/components/shared'
import ShowingPdfViewerContext from './ShowingPdfViewerContext'

import queryString from 'query-string'

import './styles.scss'
import { toastMessages } from '~/constants/toast-status-text'
import { apiClient } from '~/api/ApiClient'
import { Alert } from 'react-bootstrap'

function UseShowingPdfViewerProvider(props) {
  const { children } = props

  const [isOpen, setIsOpen] = useState(false)
  const [modalState, setModalState] = useState({
    id: null,
    model: '',
    urlName: '',
  })
  const [url, setUrl] = useState('')
  const [isFetching, setIsFetching] = useState(false)
  const [backendError, setBackendError] = useState('')

  const filename = useMemo(() => {
    if (url) {
      const { query } = queryString.parseUrl(url)
      const filenameHash = query['response-content-disposition']
      const regex = /filename\*?=.*?'?([^';]+)\.pdf(?:;|$)/
      const match = filenameHash.match(regex)
      if (match[1]) {
        return match[1]
      }
    }
    return 'concord_pdf'
  }, [url])

  const onOpenPdfViewer = useCallback(({ id, model, urlName }) => {
    setModalState({
      id,
      model,
      urlName,
    })
    setIsOpen(true)
    setUrl('')
  }, [])

  const onClosePdfViewer = useCallback(() => {
    setModalState({
      id: null,
      model: '',
      urlName: '',
    })
    setIsOpen(false)
    setUrl('')
    setBackendError('')
  }, [])

  const fetchUrl = useCallback(async () => {
    setIsFetching(true)
    try {
      if (modalState.id && modalState.model && modalState.urlName) {
        const response = await apiClient.url.getById(modalState.id, {
          model: modalState.model,
          urlName: modalState.urlName,
        })
        if (response.url) {
          setUrl(response.url)
        } else {
          setBackendError('Url is not found!')
        }
      } else {
        setBackendError('Url is not found')
      }
    } catch (error) {
      console.log('error', error)
      setBackendError(toastMessages.serverError)
    } finally {
      setIsFetching(false)
    }
  }, [modalState.id, modalState.model, modalState.urlName])

  useEffect(() => {
    if (isOpen) {
      fetchUrl()
    }
  }, [fetchUrl, isOpen])

  return (
    <>
      <ShowingPdfViewerContext.Provider value={{ onOpenPdfViewer }}>
        {children}
      </ShowingPdfViewerContext.Provider>
      <CommonDialogV2
        size='xl'
        isHiddenOkButton
        isHiddenHeader
        isOpen={isOpen}
        onClose={onClosePdfViewer}
        className='ShowingPdfViewerContext__modal'
        isLoading={isFetching}
      >
        {backendError && (
          <Alert style={{ fontSize: 13 }} variant='danger'>
            {backendError}
          </Alert>
        )}
        {url && <ViewPDF link={url} fileName={filename} />}
      </CommonDialogV2>
    </>
  )
}

UseShowingPdfViewerProvider.propTypes = {}

export default UseShowingPdfViewerProvider
