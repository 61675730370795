import { useRef, useState } from 'react'

import { Button, CompanyForm } from '~/components/shared'
import { EScope, EYesNo } from '~/types/enums/ECommonEnum'
import { ListGroup } from 'react-bootstrap'
import { useQueryBuyerSellers, useQueryTerminals } from '~/hooks/useQueryData'
import { useSelector } from 'react-redux'
import { selectCurrentScope, selectMyCurrentCompany } from '~/redux/selectors'
import { IonButtons } from '@ionic/react'
import { addCircle } from 'ionicons/icons'
import { CompanyTerminalsCard } from '../CompanyTerminalsCard'

const BuyerSellerForm = props => {
  const { onBack, onNext, afterCreate, scope = EScope.seller } = props

  const [isOpenForm, setIsOpenForm] = useState(false)
  const [savedRelationship, setSavedRelationship] = useState({})

  const companyFormRef = useRef()

  const isBuyer = scope === EScope.buyer

  const {
    sellerRelationships,
    addBuyerSeller,
    buyerRelationships,
    buyerSellerCompanyIds,
  } = useQueryBuyerSellers()
  const currentScope = useSelector(selectCurrentScope)
  const currentCompany = useSelector(selectMyCurrentCompany)

  const { terminalsData, addTerminal } = useQueryTerminals(
    {
      filters: {
        companyId: buyerSellerCompanyIds,
      },
    },
    { enabled: buyerSellerCompanyIds.length > 0 },
  )

  const relationshipData = isBuyer ? buyerRelationships : sellerRelationships

  const afterCreateCompany = (newCompany, formValues) => {
    const { buyerSeller, addresses } = formValues
    addBuyerSeller({
      ...buyerSeller,
      buyer: currentScope === EScope.buyer ? currentCompany : newCompany,
      seller: [EScope.seller, EScope.fleet].includes(currentScope)
        ? currentCompany
        : newCompany,
    })

    const addressSelected = addresses?.find(
      ({ id }) =>
        Number(id) === Number(companyFormRef.current?.idAddressSelected),
    )

    if (addressSelected) {
      setSavedRelationship(prev => ({
        ...prev,
        [newCompany.id]: {
          address: addressSelected,
          location: addressSelected.location,
        },
      }))
    }
    afterCreate && afterCreate(newCompany, formValues)
    setIsOpenForm(false)
  }

  return (
    <div>
      <IonButtons>
        <Button
          label={`Create ${scope}`}
          icon={addCircle}
          onClick={() => {
            setIsOpenForm(prev => !prev)
          }}
        />
      </IonButtons>

      {isOpenForm && (
        <div
          style={{
            border: '1px solid #e5e5e5',
            paddingBottom: 8,
            marginTop: 8,
          }}
        >
          <CompanyForm
            ref={companyFormRef}
            formData={{
              [isBuyer ? 'buyer' : 'seller']: EYesNo.Yes,
            }}
            hiddenFields={[
              'seller',
              'buyer',
              'fleet',
              'parentCompanyId',
              'timeZone',
              'workPayTypeId',
              'uomId',
            ]}
            canSkipAddressSetup
            canSkipPhoneNumberSetup
            canSkipEmailSetup
            onCancel={() => {
              setIsOpenForm(false)
            }}
            afterCreate={afterCreateCompany}
          />
        </div>
      )}

      <ListGroup style={{ marginTop: 6 }} flush>
        {relationshipData.map(({ seller, buyer, id }) => {
          const relationship = isBuyer ? buyer : seller

          return (
            <ListGroup.Item
              key={id}
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <CompanyTerminalsCard
                company={relationship}
                scope={scope}
                terminalsData={terminalsData.filter(
                  ({ companyId }) => relationship.id === companyId,
                )}
                afterCreateTerminal={addTerminal}
                savedRelationship={savedRelationship}
              />
            </ListGroup.Item>
          )
        })}
      </ListGroup>

      <div style={{ marginTop: 8, display: 'flex', alignItems: 'center' }}>
        <Button
          label='Next'
          expand='full'
          onClick={onNext}
          style={{ width: '100%' }}
        />
        <Button
          label='Back'
          expand='full'
          color='medium'
          onClick={onBack}
          style={{ width: '100%' }}
        />
      </div>
    </div>
  )
}

export default BuyerSellerForm
