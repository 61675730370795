import * as types from './actionTypes'
import _ from 'lodash'
import { toast } from 'react-toastify'
import { toastMessages } from '~/constants/toast-status-text'
import invoicesSlice from '~/redux/reducers/data/invoices'
import invoicesUiSlice from '~/redux/reducers/ui/invoices'
import invoiceUiSlice from '~/redux/reducers/ui/invoice'
import {
  updateMultipleBillLines,
  updateSingleBillLine,
} from './billLineActions'
import { apiClient } from '~/api/ApiClient'

export const fetchInvoices = (params = '', dateFilter = false) => {
  return async (dispatch, getState) => {
    dispatch(invoicesUiSlice.actions.updateUi({ loading: true }))
    try {
      const newParams = { ...params }
      if (dateFilter) {
        const lastUpdatedAt = getState().ui.invoices.lastUpdatedAt
        newParams.filters.updatedAt = {
          startDate: lastUpdatedAt,
          endDate: new Date(),
        }
      }
      const response = await apiClient.invoices.get(newParams)
      const invoices = response.invoices

      if (dateFilter) {
        if (invoices.length > 0)
          dispatch(invoicesSlice.actions.updateInvoices(invoices))
      } else {
        dispatch(invoicesSlice.actions.index(response))

        const ui = {
          startRange: response.startRange,
          endRange: response.endRange,
          totalCount: response.count,
          pageCount: response.pageCount,
          page: response.page,
          dataLoaded: true,
          lastUpdatedAt: Date.now(),
        }
        dispatch(invoicesUiSlice.actions.updateUi(ui))
      }
    } catch {
      toast.error('There was an error fetching invoices.')
    } finally {
      dispatch(invoicesUiSlice.actions.updateUi({ loading: false }))
    }
  }
}

export const updateInvoices = updateInvoice => ({
  type: types.UPDATE_INVOICES,
  updateInvoice,
})

export const updateInvoice = invoice => async (dispatch, getState) => {
  dispatch({
    type: types.UPDATE_INVOICE,
  })

  const response = await apiClient.invoices.update(invoice.id, invoice)

  if (response && !response?.errors) {
    const currentInvoice = getState().invoice.invoice
    const newInvoice = { ...currentInvoice, ...response }

    toast.success(toastMessages.updateSuccess)

    dispatch(invoicesSlice.actions.updateInvoice(newInvoice))

    return dispatch({
      type: types.UPDATE_INVOICE_SUCCESS,
      invoice: newInvoice,
      error: response.error,
    })
  }

  return dispatch({
    type: types.UPDATE_INVOICE_ERROR,
    error: response?.errors?.length ? response.errors[0] : null,
  })
}

/**
 * @param {{ invoiceId: number, updatedBillLines: any[] }} payload
 */
export const updateBillLinesAfterUpdatingBuyerSellerProduct = payload => ({
  type: types.UPDATE_BILL_LINES_AFTER_UPDATING_BUYER_SELLER_PRODUCT,
  payload,
})

export const updateBillLine =
  ({ compareId, ...payload }, isCompareInvoice = false) =>
  async dispatch => {
    // dispatch({
    //   type: types.UPDATE_BILL_LINE,
    // })

    const response = await apiClient.billLines.update(payload.id, payload)
    const billLine = isCompareInvoice
      ? response.compareBillLine || response.previousBillLine
      : response.billLine
    const compareBillLine = isCompareInvoice
      ? response.billLine
      : response.compareBillLine || response.previousBillLine

    if (response?.errors?.length) {
      dispatch({
        type: types.UPDATE_BILL_LINE_ERROR,
        billLine: null,
        error: response?.errors?.length ? response.errors[0] : null,
      })

      return
    }

    const invoiceAction = {
      type: types.UPDATE_BILL_LINE_SUCCESS,
      billLine,
      invoice: response.invoice,
      compareBillLine,
      isCompareInvoice,
      error: null,
    }

    if (_.has(payload, 'buyerSellerProductId')) {
      const { billLines } = await apiClient.billLines.get({
        filters: {
          buyerSellerProductId: [payload.buyerSellerProductId],
          invoiceId: [payload.invoiceId, compareId],
        },
      })
      if (billLines.length > 0) {
        invoiceAction.updatedBillLines = billLines
      }
    }

    invoiceAction?.updatedBillLines
      ? dispatch(updateMultipleBillLines(invoiceAction.updatedBillLines))
      : dispatch(updateSingleBillLine(billLine))
  }

export const updateUseBillLine =
  (response, isCompareInvoice = false) =>
  async dispatch => {
    dispatch({
      type: types.UPDATE_BILL_LINE,
    })
    const billLine = isCompareInvoice
      ? response.compareBillLine || response.previousBillLine
      : response.billLine
    const compareBillLine = isCompareInvoice
      ? response.billLine
      : response.compareBillLine || response.previousBillLine

    if (response) {
      return dispatch({
        type: types.UPDATE_BILL_LINE_SUCCESS,
        billLine,
        invoice: response.invoice,
        compareBillLine,
        isCompareInvoice,
        error: response.errors?.length ? response.errors[0] : null,
      })
    }

    return dispatch({
      type: types.UPDATE_BILL_LINE_ERROR,
      billLine: null,
      error: response?.errors?.length ? response.errors[0] : null,
    })
  }

export const removeInvoiceCompare = id => dispatch => {
  return dispatch({
    type: types.REMOVE_INVOICE_COMPARE,
    compareInvoiceId: id,
  })
}

export const updateInvoiceBillLine =
  (billLineId, payload, invoice, isUpdateCompareBillLine) => async dispatch => {
    const response = await apiClient.billLines.update(billLineId, {
      billLine: payload,
    })

    if (response && !response.error) {
      let invoiceUpdate = isUpdateCompareBillLine
        ? invoice.compareInvoice
        : invoice
      const billLinesUpdated = response.billLine
      const { total, loadCount, qty } = response.invoice
      invoiceUpdate = {
        ...invoiceUpdate,
        total,
        loadCount,
        qty,
      }
      const newBillLines = invoiceUpdate.billLines.map(line =>
        line.id === billLinesUpdated?.id ? billLinesUpdated : line,
      )
      const newInvoice = {
        ...invoiceUpdate,
        billLines: newBillLines,
      }

      return dispatch({
        type: types.UPDATE_INVOICE_SUCCESS,
        isCompareInvoice: isUpdateCompareBillLine,
        invoice: newInvoice,
      })
    }

    return dispatch({
      type: types.UPDATE_INVOICE_ERROR,
      error: response?.errors?.length ? response.errors[0] : null,
    })
  }

export const updateInvoiceBillLines =
  (billLineIds, payload, invoice, isUpdateCompareBillLine) =>
  async dispatch => {
    const response = await apiClient.billLines.multipleUpdate({
      ids: billLineIds,
      billLine: payload,
    })

    if (response && !response.error) {
      const newBillLines = invoice.billLines.map(line => {
        return billLineIds.includes(line.id)
          ? {
              ...line,
              ...payload,
            }
          : line
      })
      const newInvoice = {
        ...invoice,
        billLines: newBillLines,
      }

      return dispatch({
        type: types.UPDATE_INVOICE_SUCCESS,
        isCompareInvoice: isUpdateCompareBillLine,
        invoice: newInvoice,
      })
    }

    return dispatch({
      type: types.UPDATE_INVOICE_ERROR,
      error: response?.errors?.length ? response.errors[0] : null,
    })
  }

export const updateInvoiceBillLineStore =
  (invoiceUpdated, billLinesUpdated) => (dispatch, getState) => {
    const currentInvoice = getState().invoice.invoice
    const newInvoice = {
      ...currentInvoice,
      ...invoiceUpdated,
      billLines: billLinesUpdated,
    }

    return dispatch({
      type: types.UPDATE_INVOICE_SUCCESS,
      isCompareInvoice: false,
      invoice: newInvoice,
    })
  }

export const updateCompareInvoiceStore =
  compareInvoice => (dispatch, getState) => {
    const currentInvoice = getState().invoice.invoice
    const { id, proveUrl, imgThumbnailUrl } = compareInvoice
    const newInvoice = {
      ...currentInvoice,
      compareInvoiceId: id,
      proveUrl,
      imgThumbnailUrl,
      compareInvoice,
    }

    return dispatch({
      type: types.UPDATE_INVOICE_SUCCESS,
      isCompareInvoice: false,
      invoice: newInvoice,
    })
  }

export const loadProductDetails = () => dispatch => {
  return dispatch({
    type: types.ONLOAD_PRODUCT_DETAILS,
  })
}

export const loadProductDetailsCompleted = () => dispatch => {
  return dispatch({
    type: types.LOAD_PRODUCT_DETAILS_SUCCESS,
  })
}

export const setProjectedFilter = selectedSource => dispatch => {
  return dispatch({
    type: types.SET_PROJECTED_FILTER,
    selectedSource,
  })
}

export const openSearchTable = data => ({
  data,
  type: types.OPEN_BILL_LINES_SEARCH_TABLE,
})

export const closeSearchTable = data => ({
  data,
  type: types.CLOSE_BILL_LINES_SEARCH_TABLE,
})

export const openAssociateBillLinesConfirmation = data => ({
  data,
  type: types.OPEN_BILL_LINES_ASSOCIATION_CONFIRMATION,
})

export const closeAssociateBillLinesConfirmation = () => ({
  type: types.CLOSE_BILL_LINES_ASSOCIATION_CONFIRMATION,
})

export const resetInvoicesFilters = () => ({
  type: types.INVOICES_RESET_FILTERS,
})

export const toggleBillLinesFormField = isOpen => ({
  type: types.TOGGLE_BILL_LINES_FORM_FIELD,
  isOpen,
})

/**
 * @param {object} payload
 */
export const invoiceUpdateDetail = payload => ({
  type: types.INVOICE_UPDATE_INVOICE_DETAIL,
  payload,
})

export const initializeInvoicesIndex = payload => dispatch => {
  dispatch(invoicesSlice.actions.index(payload))
}

export const updateMultipleInvoices = payload => dispatch => {
  dispatch(invoicesSlice.actions.updateInvoices(payload))
}

export const updateSingleInvoice = payload => dispatch => {
  dispatch(invoicesSlice.actions.updateInvoice(payload))
}

export const updateInvoicesUI = payload => dispatch => {
  dispatch(invoicesUiSlice.actions.updateUi(payload))
}

export const updateInvoicesUIFilter = payload => dispatch => {
  dispatch(invoicesUiSlice.actions.updateUiFilter(payload))
}

export const updateInvoicesColumns = payload => dispatch => {
  dispatch(invoicesUiSlice.actions.updateUiColumns(payload))
}

export const updateInvoiceUi = payload => dispatch => {
  dispatch(invoiceUiSlice.actions.updateUi(payload))
}

export const updateComparisonTableColumns = payload => dispatch => {
  dispatch(invoiceUiSlice.actions.updateComparisonTableColumns(payload))
}

export const updateInvoicesUISort = payload => dispatch => {
  if (payload.length)
    return dispatch(invoicesUiSlice.actions.updateUiSortOptions(payload))

  dispatch(invoicesUiSlice.actions.updateUiSortOptions([payload]))
}

export const updateCompareInvoicePage = payload => dispatch => {
  dispatch(invoiceUiSlice.actions.updateCompareInvoiceControl(payload))
}
