import { useEffect } from 'react'
import Pusher from 'pusher-js'
import PropTypes from 'prop-types'
import _ from 'lodash'
import { usePusherProvider } from '~/contexts'

const usePusher = ({ channelName, events, canRegisterEvents }) => {
  const { pusherInstance } = usePusherProvider()

  useEffect(() => {
    if (!channelName || !events) return

    const isTest = process.env.NODE_ENV === 'development' || null
    if (process.env.REACT_APP_TURN_ON_PUSHER_LOGGER === 'true') {
      Pusher.logToConsole = true
    }
    if (process.env.REACT_APP_PUSHER_API_KEY && canRegisterEvents) {
      const envChannel = `${isTest ? 'TEST_' : ''}${channelName}`
      const channelFound = pusherInstance.channels.find(envChannel)

      if (channelFound) {
        channelFound.unbind_all()
        events &&
          events.forEach(event => {
            channelFound.bind(event.name, data => {
              const camelized = camelize(data)
              event.handler && event.handler(camelized)
            })
          })
      } else {
        const channel = pusherInstance.subscribe(envChannel)
        events &&
          events.forEach(event => {
            channel.bind(event.name, data => {
              const camelized = camelize(data)
              event.handler && event.handler(camelized)
            })
          })
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channelName, events, canRegisterEvents])

  return {}
}

const camelize = obj =>
  _.transform(obj, (acc, value, key, target) => {
    const camelKey = _.isArray(target) ? key : _.camelCase(key)

    acc[camelKey] = _.isObject(value) ? camelize(value) : value
  })

usePusher.propTypes = {
  channelName: PropTypes.string.isRequired,
  events: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      handler: PropTypes.func,
    }),
  ),
}

usePusher.defaultProps = {
  channelName: 'channel',
  events: [
    {
      name: 'event',
      handler: res => console.log(res),
    },
  ],
}

export default usePusher
