import { useState, useCallback, useRef, useEffect, useMemo } from 'react'
import { DOCUMENTS_PER_PAGE } from './helpers'
import { useSelector, useDispatch } from 'react-redux'
import _ from 'lodash'
import { getDocumentURL } from '~/utils/documentUtils'
import { selectDocumentsUiSortableColumns } from '~/redux/selectors'
import useDocumentsUiControls from '~/hooks/useDocuments/useDocumentsUiControls'
import { sellersList } from '~/utils/sellersUtils'
import { toast } from 'react-toastify'
import { toastMessages } from '~/constants/toast-status-text'
import { onUpdateUi } from '~/redux/actions/documentsActions'
import {
  sortDataFromSortList,
  sortOnHeader,
  getLocalSortFields,
} from '~/utils/sortTables'
import DEFAULT_DOCUMENT_COLUMNS from '~/constants/documents/columns'
import humps from 'humps'
import { produce } from 'immer'
import { useFiltersInURL } from '~/hooks/useFilters/useFiltersInURL'
import { apiClient } from '~/api/ApiClient'
import { formatOrderParams } from '~/utils/buildUrl'
import { useQueryBuyerSellers, useQueryCompanies } from '~/hooks/useQueryData'

const useLoadDocument = (location, needLoadData = true) => {
  const { onUpdateSortOptions } = useDocumentsUiControls()

  const [documents, setDocuments] = useState([])

  const firstLoading = useRef(true)
  const sortOptions = useSelector(selectDocumentsUiSortableColumns)
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingMore, setIsLoadingMore] = useState(false)

  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(DOCUMENTS_PER_PAGE)
  const [settingsPage, setSettingsPage] = useState({
    totalItems: 0,
    startRange: 0,
    endRange: 0,
  })

  const [invoice, setInvoice] = useState()
  const [preloadData, setNeedPreloadData] = useState(needLoadData)
  const [showError, setShowError] = useState(false)
  const [sellersValues, setSellerValues] = useState([])

  const filters = useSelector(state => state.ui.documents.filters)
  const { user: currentUser } = useSelector(state => state.session)

  const { findCompanyById } = useQueryCompanies({})
  const { sellerRelationships } = useQueryBuyerSellers()

  const dispatch = useDispatch()

  const loadDocumentRef = useRef()
  //Call load file every time click on assign image button

  const mapDocumentsWithSeller = useMemo(
    () =>
      documents.map(concordDocument => {
        const seller = findCompanyById(concordDocument.sellerId)
        return {
          ...concordDocument,
          seller,
        }
      }),
    [documents, findCompanyById],
  )

  useFiltersInURL({
    filters,
    sortOptions,
  })

  const fetchDocuments = useCallback(async () => {
    if (currentUser.currentScope !== 'buyer') return
    if (!preloadData) return
    //Reset all setting when open the storage list
    setIsLoading(true)

    const params = {
      filters: {},
      order: formatOrderParams(sortOptions),
    }
    if (invoice) {
      params.buyerId = invoice.buyerId
      params.filters.sellerId = [invoice.sellerId]
      params.perPage = 200
    } else {
      params.buyerId = currentUser.company.id
      params.page = page
      params.perPage = perPage
      params.filters = filters
    }

    let {
      documents: newDocuments,
      error,
      count: totalItems = 1000,
      startRange = 1,
      endRange = 24,
    } = await apiClient.documents.get(params)

    if (error) {
      toast.error(toastMessages.documentFetchFail)
      setShowError(true)
    }

    setDocuments(newDocuments)
    setSettingsPage({
      totalItems,
      startRange,
      endRange,
    })
    setIsLoading(false)
    setIsLoadingMore(false)
  }, [
    currentUser.company.id,
    currentUser.currentScope,
    filters,
    invoice,
    page,
    perPage,
    preloadData,
    sortOptions,
  ])

  const sortedDocuments = useMemo(() => {
    return sortDataFromSortList(
      documents,
      getLocalSortFields(sortOptions.filter(({ sorted }) => sorted)),
    )
  }, [documents, sortOptions])

  const updateDocumentSellers = useCallback(seller => {
    const { sellerId, sellerImage, sellerName } = seller
    setDocuments(prev =>
      produce(prev, draft => {
        for (let i = 0; i < draft.length; i++) {
          if (draft[i].sellerId === sellerId) {
            draft[i].sellerName = sellerName
            draft[i].sellerImage = sellerImage
          }
        }
      }),
    )
    toast.success(toastMessages.editCompanyContainer.infoSuccess)
  }, [])

  const handleUpdateMultipleDocument = useCallback(updatedDocuments => {
    if (_.size(updatedDocuments) === 0) {
      return
    }
    let countDocumentUpdated = 0
    setDocuments(prev =>
      produce(prev, draft => {
        draft.forEach((concordDocument, index) => {
          const indexUpdated = _.findIndex(updatedDocuments, {
            id: concordDocument.id,
          })

          if (indexUpdated > -1) {
            draft[index] = {
              ...draft[index],
              ...updatedDocuments[indexUpdated],
            }
            countDocumentUpdated++
          }
        })
      }),
    )

    toast.success(`${countDocumentUpdated} documents updated`)
  }, [])

  const onLoadDocuments = useCallback(
    (forceReload, page, perPage) => {
      // const searchParams = new URLSearchParams(router.location?.search)
      // if (searchParams.toString().length > 0) {
      //   const newFilters = searchParamsToFilters(searchParams, 'documents')
      //   if (initialLoad.current && typeof newFilters !== 'undefined') {
      //     dispatch(updateDashboardFilters(newFilters))
      //     initialLoad.current = false

      //     return
      //   }
      // }
      fetchDocuments(page, preloadData, currentUser, forceReload, perPage)
    },
    [fetchDocuments, preloadData, currentUser],
  )

  const onSortChange = useCallback(
    options => {
      dispatch(onUpdateSortOptions(options))
    },
    [dispatch, onUpdateSortOptions],
  )

  const handleChangePage = useCallback((event, nextPage) => {
    setPage(nextPage)
  }, [])

  const handleChangePerPage = useCallback((event, nextPerPage) => {
    setPerPage(nextPerPage)
  }, [])

  const onHeaderSort = useCallback(
    ({ value }) => {
      const sortField = humps.decamelize(value)
      const newOptions = sortOnHeader(sortOptions, sortField)
      dispatch(onUpdateSortOptions(newOptions))
    },
    [sortOptions, onUpdateSortOptions, dispatch],
  )

  const onSearchKeyChange = _.debounce(key => {
    dispatch(onUpdateUi({ key: 'searchWord', value: key }))
  }, 500)

  useEffect(() => {
    loadDocumentRef.current = onLoadDocuments
  }, [onLoadDocuments])

  useEffect(() => {
    // Reload data
    if (!firstLoading.current) loadDocumentRef.current(true)
  }, [sortOptions, filters, invoice])

  useEffect(() => {
    if (currentUser.currentScope === 'buyer' && firstLoading.current) {
      loadDocumentRef.current()
      firstLoading.current = false
    } else if (currentUser.currentScope === 'buyer') {
      firstLoading.current = false
    }
  }, [currentUser, firstLoading])

  const onOpenNewWindow = useCallback(async document => {
    const url = await getDocumentURL(document)
    window.open(url, '_blank', 'toolbar=yes,scrollbars=yes,resizable=yes')
  }, [])

  const onOpenNewTab = useCallback(async document => {
    const url = await getDocumentURL(document)
    window.open(url, '_blank')
  }, [])

  const onInvoiceBuyerAndSellerId = useCallback(async invoice => {
    setInvoice(invoice)
  }, [])

  const onFiltersReset = () => {
    dispatch(onUpdateUi(DEFAULT_DOCUMENT_COLUMNS))
  }

  const onFiltersWithInvoiceDocType = () => {
    dispatch(onUpdateUi({ ...DEFAULT_DOCUMENT_COLUMNS, docType: [2] }))
  }

  const addDefaultFiltersForAssignedDocument = (values = []) => {
    const payLoad = {}
    values.forEach(val => (payLoad[val.key] = val.value))

    dispatch(onUpdateUi(payLoad))
  }

  const setDefaultFilterForDocumentPage = () => {
    dispatch(onUpdateUi(DEFAULT_DOCUMENT_COLUMNS))
  }

  useEffect(() => {
    setSellerValues(_.sortBy(sellersList(sellerRelationships), 'label'))
  }, [sellerRelationships])

  useEffect(() => {
    fetchDocuments()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [perPage, page])

  return {
    isLoading,
    isLoadingMore,
    filters,
    documents: sortedDocuments,
    loadDocumentRef,
    showError,
    sortOptions,
    setDocuments,
    onSortChange,
    onHeaderSort,
    onSearchKeyChange,
    onOpenNewWindow,
    onOpenNewTab,
    onInvoiceBuyerAndSellerId,
    setNeedPreloadData,
    onFiltersReset,
    onFiltersWithInvoiceDocType,
    sellersValues,
    addDefaultFiltersForAssignedDocument,
    setDefaultFilterForDocumentPage,
    onLoadDocuments,
    perPage,
    page,
    handleChangePage,
    handleChangePerPage,
    updateDocumentSellers,
    handleUpdateMultipleDocument,
    mapDocumentsWithSeller,
    ...settingsPage,
  }
}

export default useLoadDocument
