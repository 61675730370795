import { useMemo } from 'react'

import { CompanyAvatar, ToolTipOverlay, CompanyInfo } from '~/components/shared'
import DocTypeCol from '../DocTypeCol'
import StatusCol from '../StatusCol'
import { DOCUMENT_FILE_TYPES } from '../helpers'

import {
  DOCUMENT_STATUSES,
  DOCUMENT_DOC_TYPES,
  DOCUMENT_ACCESS_TYPES,
} from '~/components/company/DocumentList/helpers'

import './TableView.scss'
import { useDateTimeFormat } from '~/hooks/useFormatDateToTz'
import { EFieldType } from '~/types/enums/ECommonEnum'
import { useQueryCompanies, useQueryTerminals } from '~/hooks/useQueryData'

const useDocumentTableColumns = ({ sellerTerminalOptions }) => {
  const { sellerCompanyOptions } = useQueryCompanies({})
  const { buyerTerminalOptions } = useQueryTerminals()

  const { getDateTimeFormat, getTimeFormat } = useDateTimeFormat()

  const columns = useMemo(
    () => [
      {
        accessorKey: 'createdAt',
        header: 'Created At',
        filterVariant: EFieldType.dateRange,
        Cell: ({ cell }) => {
          const cellData = cell.getValue()
          const tooltip = getTimeFormat(cellData)
          const date = getDateTimeFormat(cellData, 'MMM-D')

          return (
            <ToolTipOverlay content={tooltip}>
              <span>{date}</span>
            </ToolTipOverlay>
          )
        },
        maxSize: 120,
      },
      {
        accessorKey: 'name',
        header: 'Name',
        size: 280,
        enableEditing: true,
        enableSorting: false,
      },
      {
        accessorKey: 'sellerId',
        header: 'Seller',
        minSize: 200,
        maxSize: 200,
        enableSorting: false,
        filterVariant: EFieldType.multipleSelect,
        filterSelectOptions: sellerCompanyOptions,
        enableEditing: true,
        editSelectOptions: sellerCompanyOptions,
        editVariant: EFieldType.singleSelect,
        editDropdownFieldProps() {
          return {
            formatOptionLabel: opt => (
              <CompanyInfo
                searchableGoogle={false}
                hideAnchor
                companyType='seller'
                company={opt}
                tooltipMessage={opt?.label || 'Click to select seller'}
              />
            ),
          }
        },
      },
      {
        accessorKey: 'user',
        header: 'User',
        enableSorting: false,
        filterVariant: EFieldType.singleSelect,
        filterSelectOptions: DOCUMENT_ACCESS_TYPES,
        minSize: 80,
        maxSize: 80,
        Cell: ({ cell }) => {
          const cellData = cell.getValue()
          if (cellData) {
            const { email, firstName, lastName, id } = cellData
            const fullName = `${firstName} ${lastName}`.trim()

            const titleRendered = (
              <div>
                <div>
                  <strong>Email</strong>: {email}
                </div>
                <div>
                  <strong>Name</strong>: {fullName}
                </div>
              </div>
            )

            return (
              <CompanyAvatar
                company={{
                  name: `${firstName} ${lastName}`,
                  value: id,
                }}
                tooltipProps={{
                  className: 'DocumentsTableView__tooltip',
                }}
                tooltipMessage={titleRendered}
              />
            )
          }

          return null
        },
      },
      {
        accessorKey: 'docType',
        header: 'Doc Type',
        enableSorting: false,
        filterVariant: EFieldType.multipleSelect,
        filterSelectOptions: DOCUMENT_DOC_TYPES,
        enableEditing: true,
        editSelectOptions: DOCUMENT_DOC_TYPES,
        editVariant: EFieldType.singleSelect,
        minSize: 150,
        maxSize: 150,
        editDropdownFieldProps() {
          return {
            formatOptionLabel: opt => <DocTypeCol docType={opt.value} />,
          }
        },
      },
      {
        accessorKey: 'buyerTerminalId',
        header: 'Buyer Terminal',
        minSize: 220,
        maxSize: 220,
        enableSorting: false,
        filterSelectOptions: buyerTerminalOptions,
        filterVariant: EFieldType.multipleSelect,
        enableEditing: true,
        editSelectOptions: [
          {
            code: null,
            id: null,
            label: 'Not Set',
            name: 'Not Set',
            value: null,
          },
          ...buyerTerminalOptions,
        ],
        editVariant: EFieldType.singleSelect,
      },
      {
        accessorKey: 'sellerTerminalId',
        header: 'Seller Terminal',
        minSize: 220,
        maxSize: 220,
        enableEditing: true,
        enableSorting: false,
        editSelectOptions: [
          {
            code: null,
            id: null,
            label: 'Not Set',
            name: 'Not Set',
            value: null,
          },
          ...sellerTerminalOptions,
        ],
        editVariant: EFieldType.singleSelect,
      },
      {
        accessorKey: 'status',
        header: 'Status',
        enableSorting: false,
        filterSelectOptions: DOCUMENT_STATUSES,
        filterVariant: EFieldType.multipleSelect,
        Cell: ({ row }) => {
          const rowData = row.original
          const { status, issueDescription, usedAt } = rowData

          return (
            <StatusCol
              status={status}
              description={issueDescription}
              usedAt={usedAt}
            />
          )
        },
        size: 135,
      },
      {
        header: 'Pages',
        accessorKey: 'totalPages',
        size: 100,
        enableSorting: false,
      },
      {
        header: 'File Type',
        accessorKey: 'fileType',
        enableSorting: false,
        filterSelectOptions: DOCUMENT_FILE_TYPES,
        filterVariant: EFieldType.multipleSelect,
        size: 80,
      },
    ],
    [
      sellerCompanyOptions,
      buyerTerminalOptions,
      sellerTerminalOptions,
      getTimeFormat,
      getDateTimeFormat,
    ],
  )

  return { columns }
}

export default useDocumentTableColumns
