import { useMemo } from 'react'
import { useQuery, UseQueryOptions } from 'react-query'
import { useSelector } from 'react-redux'
import useModifyOrderExtraRanges from './useModifyOrderExtraRanges'

import { apiClient } from '~/api/ApiClient'
import { selectSessionUser } from '~/redux/selectors'
import { buildGetUrl } from '~/utils/buildUrl'

import type { IUser } from '~/types/models/IUser'
import type {
  IGetOrderExtraRangesParams,
  IOrderExtraRange,
} from '~/types/models/IOrderExtraRange'
import { DEFAULT_QUERY_OPTIONS } from '../constants'

const useQueryOrderExtraRanges = (
  params: IGetOrderExtraRangesParams,
  options?: Partial<UseQueryOptions<IOrderExtraRange[]>>,
) => {
  const sessionUser: IUser | null = useSelector(selectSessionUser)

  const { data, isLoading, refetch } = useQuery({
    queryKey: [
      'orderExtraRanges',
      sessionUser?.id,
      buildGetUrl(apiClient.orderExtraRanges.endpoint, params),
    ],
    async queryFn() {
      if ((params as any).filters.orderExtraId?.length > 0) {
        const response = await apiClient.orderExtraRanges.get(params)
        return response.orderExtraRanges
      }
      return []
    },
    ...DEFAULT_QUERY_OPTIONS,
    ...options,
  })

  const orderExtraRanges = useMemo(() => data || [], [data])

  const findAutoExtraById = (id: number) =>
    orderExtraRanges.find(i => i.id === id)

  const { addOrderExtraRange, updateOrderExtraRange, removeOrderExtraRange } =
    useModifyOrderExtraRanges(params)

  return {
    orderExtraRanges,
    isOrderExtraRangesLoading: isLoading,
    addOrderExtraRange,
    updateOrderExtraRange,
    removeOrderExtraRange,
    findAutoExtraById,
    refetchOrderExtraRangesData: refetch,
  }
}

export default useQueryOrderExtraRanges
