import { useQuery, UseQueryOptions } from 'react-query'
import { useSelector } from 'react-redux'

import { apiClient } from '~/api/ApiClient'
import { selectSessionUser } from '~/redux/selectors'

import type { IUser } from '~/types/models/IUser'
import { ITruckFleet } from '~/types/models/ITruckFleet'
import useModifyTruckFleets from './useModifyTerminals'
import { useQueryUsers } from '../useQueryUsers'
import { useCallback, useMemo } from 'react'
import buildFullName from '~/utils/buildFullName'
import { buildGetUrl } from '~/utils/buildUrl'
import { DEFAULT_QUERY_OPTIONS } from '../constants'

const useQueryTruckFleets = (
  params: Partial<{ id: number }> = {},
  options?: Partial<UseQueryOptions<ITruckFleet[]>>,
) => {
  const sessionUser: IUser | null = useSelector(selectSessionUser)

  const { data, isLoading, refetch, isFetched } = useQuery({
    queryKey: [
      'truckFleets',
      sessionUser?.id,
      buildGetUrl(apiClient.truckFleets.endpoint, params),
    ],
    async queryFn() {
      if (params.id) {
        const response = await apiClient.truckFleets.getById(params.id)
        return [response]
      }
      const response = await apiClient.truckFleets.get()
      return response.truckFleets
    },
    enabled: Boolean(sessionUser?.id),
    ...DEFAULT_QUERY_OPTIONS,
    ...options,
  })

  const { addTruckFleet, updateTruckFleet, removeTruckFleet, updateTruck } =
    useModifyTruckFleets(params)

  const { workerUsers } = useQueryUsers()

  const currentTruckFleetIdInUsed = useMemo(() => {
    const ids: number[] = []
    workerUsers.forEach(({ driverFleet }) => {
      if (driverFleet?.currentTruckFleetId) {
        ids.push(driverFleet.currentTruckFleetId)
      }
    })
    return ids
  }, [workerUsers])

  const defaultTruckFleetIdInUsed = useMemo(() => {
    const ids: number[] = []
    workerUsers.forEach(({ driverFleet }) => {
      if (driverFleet?.defaultTruckFleetId) {
        ids.push(driverFleet.defaultTruckFleetId)
      }
    })
    return ids
  }, [workerUsers])

  const truckFleetsData = useMemo(() => data || [], [data])

  const trucksData = useMemo(
    () => truckFleetsData.map(({ truck }) => truck).filter(Boolean),
    [truckFleetsData],
  )

  const trucksObj = useMemo(
    () => Object.fromEntries(trucksData.map(truck => [truck.id, truck])),
    [trucksData],
  )

  const truckFleetOptions = useMemo(
    () =>
      truckFleetsData.map(({ id, truck }) => ({
        value: id,
        label: truck?.name || 'Unknown',
      })),
    [truckFleetsData],
  )

  const availableCurrentTruckFleetOptions = useMemo(() => {
    const opts = truckFleetsData
      .filter(({ id }) => !currentTruckFleetIdInUsed.includes(id))
      .map(({ truck, id }) => ({
        label: truck.name,
        value: id,
      }))

    return {
      label: 'Available',
      options: [
        {
          label: 'Not set',
          value: null,
        },
        ...opts,
      ],
    }
  }, [currentTruckFleetIdInUsed, truckFleetsData])

  const assignedCurrentTruckFleetOptions = useMemo(
    () => ({
      label: 'Assigned',
      options: truckFleetsData
        .filter(({ id }) => currentTruckFleetIdInUsed.includes(id))
        .map(({ truck, id }) => {
          const assignee = workerUsers.find(
            ({ driverFleet }) => driverFleet?.currentTruckFleetId === id,
          )

          return {
            label: `${truck.name} - ${buildFullName(assignee?.person)}`,
            value: id,
          }
        }),
    }),
    [currentTruckFleetIdInUsed, truckFleetsData, workerUsers],
  )

  const currentTruckFleetOptions = useMemo(() => {
    return [availableCurrentTruckFleetOptions, assignedCurrentTruckFleetOptions]
  }, [assignedCurrentTruckFleetOptions, availableCurrentTruckFleetOptions])

  //--

  const availableDefaultTruckFleetOptions = useMemo(() => {
    const opts = truckFleetsData
      .filter(({ id }) => !defaultTruckFleetIdInUsed.includes(id))
      .map(({ truck, id }) => ({
        label: truck.name,
        value: id,
      }))

    return {
      label: 'Available',
      options: [
        {
          label: 'Not set',
          value: null,
        },
        ...opts,
      ],
    }
  }, [defaultTruckFleetIdInUsed, truckFleetsData])

  const assignedDefaultTruckFleetOptions = useMemo(
    () => ({
      label: 'Assigned',
      options: truckFleetsData
        .filter(({ id }) => defaultTruckFleetIdInUsed.includes(id))
        .map(({ truck, id }) => {
          const assignee = workerUsers.find(
            ({ driverFleet }) => driverFleet?.defaultTruckFleetId === id,
          )

          return {
            label: `${truck.name} - ${buildFullName(assignee?.person)}`,
            value: id,
          }
        }),
    }),
    [defaultTruckFleetIdInUsed, truckFleetsData, workerUsers],
  )

  const defaultTruckFleetOptions = useMemo(() => {
    return [availableDefaultTruckFleetOptions, assignedDefaultTruckFleetOptions]
  }, [assignedDefaultTruckFleetOptions, availableDefaultTruckFleetOptions])

  const findTruckFleetById = useCallback(
    (truckFleetId: number | null | undefined) =>
      truckFleetsData.find(({ id }) => id === truckFleetId),
    [truckFleetsData],
  )
  const findTruckFleetByTruckId = useCallback(
    (truckId: number) =>
      truckFleetsData.find(({ truck }) => truck.id === truckId),
    [truckFleetsData],
  )

  return {
    truckFleetsData,
    isLoadingTruckFleetsData: isLoading,
    isTruckFleetsDataFetched: isFetched,
    currentTruckFleetOptions,
    availableCurrentTruckFleetOptions,
    assignedCurrentTruckFleetOptions,
    trucksData,

    defaultTruckFleetOptions,
    availableDefaultTruckFleetOptions,
    assignedDefaultTruckFleetOptions,
    truckFleetOptions,
    trucksObj,

    addTruckFleet,
    updateTruckFleet,
    removeTruckFleet,
    findTruckFleetById,
    findTruckFleetByTruckId,
    refetchTruckFleetsData: refetch,
    updateTruck,
  }
}

export default useQueryTruckFleets
