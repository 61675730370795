import { createSlice } from '@reduxjs/toolkit'
import usersSlice from './users'
import parsersSlice from './parsers'
import sellersSlice from './sellers'
import buyersSlice from './buyers'
import fleetsSlice from './fleets'
import emailParsersSlice from './emailParsers'
import parserColumnMappingsSlice from './parserColumnMappings'
import productsSlice from './products'
import fixedPricesSlice from './fixedPrices'
import sellerProductsSlice from './sellerProducts'
import buyerSellerProductsSlice from './buyerSellerProducts'
import buyerTerminalReferencesSlice from './buyerTerminalReferences'
import referenceNamesSlice from './referenceNames'
import btfStpsSlice from './btfStps'
import dataCorrectionMethodsSlice from './dataCorrectionMethods'
import companyTagsSlice from './companyTags'
import setupSlice from './setup'
import costCodesSlice from './costCodes'
import userGroupsSlice from './userGroups'
import productGroupsSlice from './productGroups'

const companySubTabsSlice = createSlice({
  name: 'companySubTabs',
  initialState: {
    [usersSlice.name]: usersSlice.getInitialState(),
    [parsersSlice.name]: parsersSlice.getInitialState(),
    [sellersSlice.name]: sellersSlice.getInitialState(),
    [buyersSlice.name]: buyersSlice.getInitialState(),
    [fleetsSlice.name]: fleetsSlice.getInitialState(),
    [emailParsersSlice.name]: emailParsersSlice.getInitialState(),
    [parserColumnMappingsSlice.name]:
      parserColumnMappingsSlice.getInitialState(),
    [productsSlice.name]: productsSlice.getInitialState(),
    [fixedPricesSlice.name]: fixedPricesSlice.getInitialState(),
    [sellerProductsSlice.name]: sellerProductsSlice.getInitialState(),
    [buyerSellerProductsSlice.name]: buyerSellerProductsSlice.getInitialState(),
    [buyerTerminalReferencesSlice.name]:
      buyerTerminalReferencesSlice.getInitialState(),
    [referenceNamesSlice.name]: referenceNamesSlice.getInitialState(),
    [btfStpsSlice.name]: btfStpsSlice.getInitialState(),
    [dataCorrectionMethodsSlice.name]:
      dataCorrectionMethodsSlice.getInitialState(),
    [companyTagsSlice.name]: companyTagsSlice.getInitialState(),
    [setupSlice.name]: setupSlice.getInitialState(),
    [costCodesSlice.name]: costCodesSlice.getInitialState(),
    [userGroupsSlice.name]: userGroupsSlice.getInitialState(),
    [productGroupsSlice.name]: productGroupsSlice.getInitialState(),
  },
  reducers: {
    updateSubTabUi: (state, action) => {
      const subTab = action.payload.subTab

      for (const [key, value] of Object.entries(action.payload.data)) {
        state[subTab][`${key}`] = value
      }
    },
    updateSubTabUiColumn: (state, action) => {
      const subTab = action.payload.subTab
      let columns = state[subTab]['columns']

      let { column } = action.payload.data

      let updateIndex = columns.findIndex(
        columnState => columnState.sortField == column.sortField,
      )
      state[subTab]['columns'][updateIndex] = {
        ...state[subTab]['columns'][updateIndex],
        ...column,
      }
    },
    updateSubTabUiColumns: (state, action) => {
      const subTab = action.payload.subTab
      let columns = state[subTab]['columns']

      action.payload.data.forEach(column => {
        let updateIndex = columns.findIndex(
          columnState => column.sortField == columnState.sortField,
        )

        if (updateIndex === -1) {
          state[subTab]['columns'].push({ ...column })

          return
        }

        state[subTab]['columns'][updateIndex] = {
          ...state[subTab]['columns'][updateIndex],
          ...column,
        }
      })
    },
  },
})

export default companySubTabsSlice
