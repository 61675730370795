import { useCallback, useMemo } from 'react'
import { useQuery, UseQueryOptions } from 'react-query'
import { useSelector } from 'react-redux'
import useModifyTags from './useModifyTags'

import { apiClient } from '~/api/ApiClient'
import { selectSessionUser } from '~/redux/selectors'
import { buildGetUrl } from '~/utils/buildUrl'

import type { IUser } from '~/types/models/IUser'
import type { IGetTagsParams, ITag } from '~/types/models/ITag'
import { DEFAULT_QUERY_OPTIONS } from '../constants'
import { ETagModels } from '~/types/enums/ETag'
import getFilteredData from '~/utils/getFilteredData'
import buildObjectName from '~/utils/buildObjectName'
import { colord } from 'colord'

interface IOption {
  models: ETagModels[]
  onClick: (tag: ITag) => void
  hasCheckbox?: boolean
  isChecked?: (tag: ITag) => boolean
}

const useQueryTags = (
  params: IGetTagsParams = {},
  options?: Partial<UseQueryOptions<ITag[]>>,
) => {
  const sessionUser: IUser | null = useSelector(selectSessionUser)

  const { data, isLoading, isFetched } = useQuery({
    queryKey: [
      'tags',
      sessionUser?.id,
      buildGetUrl(apiClient.tags.endpoint, params),
    ],
    async queryFn() {
      const response = await apiClient.tags.get(params)
      return response
    },
    enabled: Boolean(sessionUser?.id),
    ...DEFAULT_QUERY_OPTIONS,
    ...options,
  })

  const tagsData = useMemo(() => data || [], [data])

  const tagOptions = useMemo(
    () =>
      tagsData.map(({ id, name }) => ({
        value: id,
        label: name,
      })),
    [tagsData],
  )

  const findTagsByModels = useCallback(
    (models: ETagModels[]) =>
      getFilteredData(tagsData, {
        models,
      }) as ITag[],
    [tagsData],
  )

  const getTagOptionsByModels = useCallback(
    (options: IOption) => {
      const data = findTagsByModels(options.models)
      return data.map(tag => {
        const { name, code, color, models, id } = tag

        return {
          label: buildObjectName({ name, code }),
          bgColor: color,
          color: color ? (colord(color).isDark() ? 'white' : 'black') : 'black',
          models,
          value: id,
          onClick: () => {
            options.onClick(tag)
          },
          hasCheckbox: options.hasCheckbox,
          isChecked: () => (options.isChecked ? options.isChecked(tag) : false),
        }
      })
    },
    [findTagsByModels],
  )

  const getRowClassNameBasedOnTagIds = useCallback(
    ({ row: { original }, table }: any) => {
      if (isFetched) {
        const existingColors: string[] = original.tagIds.map(
          (id: number) => tagsData.find(t => t.id === id)?.color || '#000',
        )
        if (existingColors.length === 0) {
          return
        }
        const el = table.refs.tableContainerRef.current.querySelector(
          `tr[data-id="${original.id}"].RTTableBodyRow__container`,
        )
        if (el) {
          const existingColorContainer = el.querySelector(
            `div[data-id="${original.id}"].RTTableBodyRow__tag`,
          )

          if (existingColorContainer) {
            existingColorContainer.innerHTML = ''
            existingColors.forEach(color => {
              if (
                !existingColorContainer.querySelector(
                  `div[data-color-id="${`${color}-${original.id}`}"].RTTableBodyRow__tagColor`,
                )
              ) {
                const colorBox = document.createElement('div')
                colorBox.setAttribute(
                  'data-color-id',
                  `${color}-${original.id}`,
                )
                colorBox.className = 'RTTableBodyRow__tagColor'
                colorBox.style.backgroundColor = color
                colorBox.style.width = '3px' // Example width
                colorBox.style.height = `${100 / existingColors.length}%` // Example height
                existingColorContainer.appendChild(colorBox) // Append the box to the container
              }
            })
          } else {
            const colorContainer = document.createElement('div')
            colorContainer.setAttribute('data-id', original.id)
            colorContainer.className = 'RTTableBodyRow__tag'
            colorContainer.style.position = 'absolute'
            colorContainer.style.top = '0px'
            colorContainer.style.left = '0px'
            colorContainer.style.zIndex = '999999'
            colorContainer.style.width = '20px'
            colorContainer.style.height = '100%'
            colorContainer.innerHTML = ''
            existingColors.forEach(color => {
              if (
                !colorContainer.querySelector(
                  `div[data-color-id="${`${color}-${original.id}`}"].RTTableBodyRow__tagColor`,
                )
              ) {
                const colorBox = document.createElement('div')
                colorBox.setAttribute(
                  'data-color-id',
                  `${color}-${original.id}`,
                )
                colorBox.className = 'RTTableBodyRow__tagColor'
                colorBox.style.backgroundColor = color
                colorBox.style.width = '3px' // Example width
                colorBox.style.height = `${100 / existingColors.length}%`
                colorContainer.appendChild(colorBox) // Append the box to the container
              }
            })

            if (el) {
              el.appendChild(colorContainer)
            }
          }
        }

        return ''
      }
      return ''
    },
    [isFetched, tagsData],
  )

  const { addTag, updateTag, removeTag, renewAllTags } = useModifyTags(params)

  return {
    tagsData,
    isTagsLoading: isLoading,
    tagOptions,
    addTag,
    updateTag,
    removeTag,
    renewAllTags,
    getTagOptionsByModels,
    getRowClassNameBasedOnTagIds,
  }
}

export default useQueryTags
