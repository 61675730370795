import moment from 'moment'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useQuerySensors } from '~/hooks/useQueryData'

import type { ITruck } from '~/types/models/ITruck'
import { ToggleSection } from '../ToggleSection'
import { IonCol, IonGrid, IonIcon, IonRow } from '@ionic/react'
import clsx from 'clsx'
import { SensorCard } from '../SensorCard'
import { ISensor } from '~/types/models/ISensor'

import './styles.scss'
import { When } from 'react-if'
import { arrowBackOutline } from 'ionicons/icons'
import { TimeRange } from '../ConcordForm'
import { LineChart } from '../LineChart'
import _ from 'lodash'
import isDateInRange from '~/utils/isDateInRange'
import isNumber from '~/utils/isNumber'
import { ILoad } from '~/types/models/ILoad'
import { useQueryLoadTimes } from '~/hooks/useQueryData/useQueryLoadTimes'
import { ELoadStatus } from '~/types/enums/ELoadStatus'
import { Form } from 'react-bootstrap'
import Skeleton from 'react-loading-skeleton'
import { useUpdateEffect } from 'react-use'

interface ISensorsListProps {
  truck?: ITruck | null
  load?: ILoad
}

function SensorsList(props: ISensorsListProps) {
  const { truck, load } = props

  const [sensorSelected, setSensorSelected] = useState<ISensor | null>(null)
  const [dateRange, setDateRange] = useState({
    startTime: load?.ticketTime,
    endTime: undefined as string | undefined,
  })
  const [hasDataChangesOnly, setHasDataChangesOnly] = useState(false)

  const timeRange = useMemo(
    () => ({
      startTime: dateRange.startTime
        ? moment(dateRange.startTime).format('HH:mm')
        : '',
      endTime: dateRange.endTime
        ? moment(dateRange.endTime).format('HH:mm')
        : '',
    }),
    [dateRange.endTime, dateRange.startTime],
  )

  const { loadTimesMapped, isLoadTimesDataFetched, isLoadTimesDataLoading } =
    useQueryLoadTimes({
      filters: {
        loadId: load?.id as number,
      },
    })

  const deliveryTime = useMemo(() => {
    if (isLoadTimesDataFetched) {
      const obj = loadTimesMapped.find(
        ({ name }) => name === ELoadStatus.deliveryComplete,
      )

      return obj?.time
    }

    return undefined
  }, [isLoadTimesDataFetched, loadTimesMapped])

  const apiParams = useMemo(() => {
    if (sensorSelected) {
      return {
        ...dateRange,
        sensorCodes: [sensorSelected.code],
        loadId: load?.id as number,
        limit: 300,
        dataChangesOnly: hasDataChangesOnly,
      }
    }

    return {
      ...dateRange,
      limit: 300,
      loadId: load?.id as number,
    }
  }, [dateRange, hasDataChangesOnly, load?.id, sensorSelected])

  const { sensorsData, isSensorsDataLoading } = useQuerySensors(
    {
      sensor: apiParams,
      truckId: truck?.id as number,
    },
    {
      enabled: Boolean(
        truck?.id && truck?.sensorSource && isLoadTimesDataFetched,
      ),
      staleTime: Infinity,
    },
  )

  const [firstSensorData] = sensorsData

  const labelsInChart = useMemo(() => {
    if (sensorSelected) {
      return _.orderBy(
        (firstSensorData || sensorSelected).data,
        ['timestamp'],
        ['asc'],
      )
        .filter(({ timestamp }) => {
          return isDateInRange(
            timestamp,
            dateRange.startTime,
            dateRange.endTime,
          )
        })
        .map(({ timestamp }) => moment(timestamp).format('H:mm:ss'))
    }
    return []
  }, [dateRange.endTime, dateRange.startTime, firstSensorData, sensorSelected])

  const dataInChart = useMemo(() => {
    if (sensorSelected) {
      const data = _.orderBy(
        (firstSensorData || sensorSelected).data,
        ['timestamp'],
        ['asc'],
      )
        .filter(({ timestamp }) => {
          return isDateInRange(
            timestamp,
            dateRange.startTime,
            dateRange.endTime,
          )
        })
        .map(({ value }) => {
          if (isNumber(value)) {
            return Number(value)
          }
          return 0
        })
      return [
        {
          data,
          borderColor: sensorSelected.background,
          backgroundColor: sensorSelected.background,
        },
      ]
    }
    return []
  }, [dateRange.endTime, dateRange.startTime, firstSensorData, sensorSelected])

  const onChangeTimeRange = useCallback((newTimeRange: any) => {
    const [startHours, startMinutes] = newTimeRange.startTime.split(':')
    const [endHours, endMinutes] = newTimeRange.endTime.split(':')
    setDateRange(prev => ({
      startTime: startHours
        ? moment(prev.startTime || undefined)
            .set({
              hours: Number(startHours),
              minutes: Number(startMinutes || 0),
            })
            .toISOString()
        : undefined,
      endTime: endHours
        ? moment(prev.endTime || undefined)
            .set({
              hours: Number(endHours),
              minutes: Number(endMinutes || 0),
            })
            .toISOString()
        : undefined,
    }))
  }, [])

  const onSelectSensor = useCallback(
    (sensor: ISensor) => {
      setSensorSelected(prev => {
        if (sensor.code === prev?.code) {
          return null
        }
        return sensor
      })
      setDateRange({
        startTime: load?.ticketTime,
        endTime: deliveryTime,
      })
    },
    [deliveryTime, load?.ticketTime],
  )

  useEffect(() => {
    setDateRange(prev => ({
      ...prev,
      endTime: deliveryTime,
    }))
  }, [deliveryTime])

  useUpdateEffect(() => {
    if (!sensorSelected) {
      setDateRange(prev => ({
        ...prev,
        endTime: deliveryTime,
      }))
      setHasDataChangesOnly(false)
    }
  }, [sensorSelected])

  return (
    <div>
      {isLoadTimesDataLoading || isSensorsDataLoading ? (
        <Skeleton height={20} />
      ) : null}

      {sensorsData.length ? (
        <ToggleSection
          isOpenByDefault
          label='Sensors'
          style={{ marginLeft: 8, marginTop: 8 }}
        >
          <IonGrid className='SensorsList__container'>
            <IonRow>
              {sensorsData.map(sensor => (
                <IonCol
                  size={sensorSelected ? '12' : '4'}
                  key={`${sensor.code}${sensor.name}`}
                  className={clsx('sensorItem clickable', {
                    isExpanded: sensor.code === sensorSelected?.code,
                    isHidden: sensorSelected?.code,
                  })}
                >
                  <SensorCard sensorData={sensor} onClick={onSelectSensor} />
                </IonCol>
              ))}
            </IonRow>
          </IonGrid>
        </ToggleSection>
      ) : null}

      <When condition={Boolean(sensorSelected)}>
        <div className='SensorsList__chartHeader'>
          <div className='SensorsList__topLeftChartHeader'>
            <span
              className='backIcon clickable'
              onClick={() => {
                setSensorSelected(null)
              }}
            >
              <IonIcon icon={arrowBackOutline} />
            </span>
            <span style={{ fontSize: 14, fontWeight: 600 }}>
              <span>{sensorSelected?.name}</span>
            </span>
          </div>
          <Form.Check
            className='SensorsList__dataChangesOnlyChk'
            label='Include Data Changes Only'
            checked={hasDataChangesOnly}
            onChange={event => {
              setHasDataChangesOnly(event.target.checked)
            }}
          />
          <TimeRange value={timeRange} onChange={onChangeTimeRange} />
        </div>
        <LineChart
          isLoading={isSensorsDataLoading}
          labels={labelsInChart}
          datasets={dataInChart}
          options={{
            scales: {
              y: {
                ticks: {
                  callback: value => Number(value),
                },
              },
            },
          }}
        />
      </When>
    </div>
  )
}

export default SensorsList
