/*global google*/

import { Card, Col, Row, Spinner } from 'react-bootstrap'
import { useEffect, useMemo, useState } from 'react'

import AddressTextBox from '../AddressTextBox'
import { apiClient } from '~/api/ApiClient'
import { DirectionsRenderer } from 'react-google-maps'
import useMapDirection from '../GoogleMap/components/MapDirection/useMapDirection'
import { fullAddress } from '~/utils/addresses'
import { GoogleMap } from '~/components/shared'
import { useQueryTerminals } from '~/hooks/useQueryData'
import { TerminalMarker1 } from '~/components/shared'

import './RouteCard.scss'
import { arrowDownCircleOutline, arrowUpCircleOutline } from 'ionicons/icons'
import { IonIcon } from '@ionic/react'

export const RouteCard = ({ startTerminalId, endTerminalId }) => {
  const [start, setStart] = useState()
  const [end, setEnd] = useState()
  const [loading, setLoading] = useState(false)
  const [terminalSelected, setTerminalSelected] = useState(null)

  const { latLng: startLatLng, terminal: startTerminal } =
    useRouteTerminal(startTerminalId)
  const { latLng: endLatLng, terminal: endTerminal } =
    useRouteTerminal(endTerminalId)

  const bounding = useMemo(() => {
    if (terminalSelected) {
      return undefined
    }
    return [startLatLng, endLatLng].filter(Boolean)
  }, [terminalSelected, startLatLng, endLatLng])

  const zoom = terminalSelected ? 15 : 3

  const centerMapLocation = useMemo(() => {
    if (terminalSelected?.location) {
      return {
        lat: Number(terminalSelected.location?.lat),
        lng: Number(terminalSelected.location?.lng),
      }
    }

    return {
      lat: 40.76837807,
      lng: -73.841164619,
    }
  }, [terminalSelected?.location])

  const { directions } = useMapDirection({
    from: start,
    to: end,
    setLoading,
    //waypoints: [],
  })

  return (
    <Card>
      <Row className='ms-1'>
        <Col className='mt-1'>
          <AddressField
            terminalId={startTerminalId}
            onPickAddress={setStart}
            color='black'
            icon={arrowUpCircleOutline}
            onClick={() => {
              setTerminalSelected(prev => {
                if (prev?.id === startTerminalId) {
                  return null
                }
                return startTerminal
              })
            }}
          />
          <AddressField
            terminalId={endTerminalId}
            onPickAddress={setEnd}
            icon={arrowDownCircleOutline}
            color='blue'
            onClick={() => {
              setTerminalSelected(prev => {
                if (prev?.id === endTerminalId) {
                  return null
                }
                return endTerminal
              })
            }}
          />
        </Col>
        <Col className='mapCol' xs={5}>
          {loading && (
            <div className='mt-4 position-absolute w-50  d-flex flex-column align-items-center  justify-content-center'>
              <Spinner className='position-absolute ' />
            </div>
          )}
          <div className='RouteCard__mapContainer'>
            <GoogleMap
              defaultCenter={centerMapLocation}
              options={{
                zoomControl: true,
                fullscreenControl: true,
                streetViewControl: false,
                mapTypeControl: false,
                controlSize: 4,
              }}
              bounding={bounding}
              zoom={zoom}
            >
              {startTerminal && (
                <TerminalMarker1
                  terminal={startTerminal}
                  color='black'
                  icon={arrowUpCircleOutline}
                  title='Pickup'
                />
              )}
              {endTerminal && (
                <TerminalMarker1
                  terminal={endTerminal}
                  icon={arrowDownCircleOutline}
                  color='blue'
                  title='Dropoff'
                />
              )}
              {directions && <DirectionsRenderer {...directions} />}
            </GoogleMap>
          </div>
        </Col>
      </Row>
    </Card>
  )
}

const AddressField = ({ terminalId, onPickAddress, icon, color, onClick }) => {
  const [newAddress, setNewAddress] = useState('')
  const [addressLoading, setAddressLoading] = useState(false)
  const { name, latLng, address, locationId } = useRouteTerminal(terminalId)

  const { refetchTerminalsData } = useQueryTerminals()

  const addressString = useMemo(() => fullAddress(address), [address])

  useEffect(() => {
    setNewAddress(addressString)
  }, [addressString])

  useEffect(
    () => onPickAddress && latLng && onPickAddress(latLng),
    [latLng, onPickAddress],
  )

  const onSelect = (_address, _placeId, suggestion) => {
    if (suggestion?.description) {
      setNewAddress(suggestion.description)
      submitAddress(suggestion.description)
      onPickAddress && onPickAddress(suggestion.description)
    } else {
      setNewAddress(addressString)
    }
  }

  const submitAddress = async addr => {
    const geocoder = new google.maps.Geocoder()
    await geocoder.geocode({ address: addr }, async (results, status) => {
      if (status == google.maps.GeocoderStatus.OK) {
        await updateAddress(addr, results[0])
      }
    })
  }

  const updateAddress = async (addr, geocodingData) => {
    try {
      setAddressLoading(true)
      await apiClient.locations.update(locationId, {
        location: {
          address_attributes: {
            id: address?.id,
            full_address: addr,
            address_types: ['location'], //define const
            geocodingData,
          },
        },
      })
      refetchTerminalsData()
    } catch (e) {
      console.error(e)
    } finally {
      setAddressLoading(false)
    }
  }

  return (
    <div className='d-flex flex-column fs-3 mb-2 gap-2'>
      {addressLoading && (
        <div className='mt-4 position-absolute w-50  d-flex flex-column align-items-center  justify-content-center'>
          <Spinner className='position-absolute ' />
        </div>
      )}
      <div className='clickable' onClick={onClick}>
        {icon && (
          <span
            style={{
              background: color,
              fontWeight: 'bold',
              color: 'white',
              opacity: 1,
              padding: '2px 2px',
              borderRadius: '15px',
              width: '20px',
              height: '20px',
              fontSize: '20px',
              display: 'inline-flex',
              alignItems: 'center',
              justifyContent: 'center',
              verticalAlign: 'middle',
              marginRight: 4,
            }}
          >
            <IonIcon icon={icon} color={color} />
          </span>
        )}
        <span>{name}</span>
      </div>
      <AddressTextBox
        value={newAddress}
        onChange={setNewAddress}
        onSelect={onSelect}
        className={addressString ? 'border' : 'border border-danger'}
        disabled={addressLoading}
      />
    </div>
  )
}

export const useRouteTerminal = id => {
  const { findTerminalById } = useQueryTerminals()

  const terminal = useMemo(() => findTerminalById(id), [findTerminalById, id])
  const name = useMemo(() => terminal?.name, [terminal])

  const location = useMemo(() => terminal?.location, [terminal?.location])
  const address = useMemo(() => terminal?.address, [terminal?.address])

  const locationId = useMemo(
    () => terminal?.location?.id,
    [terminal?.location?.id],
  )
  const addressId = useMemo(
    () => terminal?.address?.id,
    [terminal?.address?.id],
  )

  const latLng = useMemo(
    () =>
      location?.lat &&
      location?.lng && {
        lat: parseFloat(location.lat),
        lng: parseFloat(location.lng),
      },
    [location],
  )

  return { name, latLng, locationId, address, addressId, terminal }
}
