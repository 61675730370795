import * as types from './actionTypes'
import { toast } from 'react-toastify'
import { createAction } from '@reduxjs/toolkit'
import { apiClient } from '~/api/ApiClient'

const fetcheditSubParts = object => ({
  object,
  type: types.EDIT_SUB_PART,
})

const fetcheditDataParser = object => ({
  object,
  type: types.EDIT_DATA_PARSER,
})

const fetcheditCompanyFleet = object => ({
  object,
  type: types.EDIT_COMPANY_FLEET,
})

export const fetcheditEmailParser = object => ({
  object,
  type: types.EDIT_EMAIL_PARSER,
})

export const fetcheditInfoParser = object => ({
  object,
  type: types.EDIT_INFO_PARSER,
})

export const fetcheditCompanyProduct = object => ({
  object,
  type: types.EDIT_COMPANY_PRODUCTS,
})

export const fetcheditBuyerSeller = object => ({
  object,
  type: types.EDIT_BUYER_SELLER,
})

const fetcheditUserAccess = object => ({
  object,
  type: types.EDIT_USER_ACCESS,
})

const fetcheditFleet = object => ({
  object,
  type: types.EDIT_FLEET,
})

export const fetcheditAutoCharge = object => ({
  object,
  type: types.EDIT_AUTO_CHARGE,
})

export const fetcheditBTFSTP = object => ({
  object,
  type: types.EDIT_BTFSTP,
})

export const fetcheditDataCorrection = object => ({
  object,
  type: types.EDIT_DATA_CORRECTION,
})

const fetcheditRouteForm = object => ({
  object,
  type: types.EDIT_ROUTE_FORMS,
})

const fetchsaveLoadForm = object => ({
  object,
  type: types.SAVE_LOAD_FORMS,
})

export const fetchEditFixedPrice = object => ({
  object,
  type: types.EDIT_FIXED_PRICE,
})

export const editFleet = object => dispatch => {
  dispatch(fetcheditFleet(object))
}

export const editFixedPrice = object => dispatch => {
  dispatch(fetchEditFixedPrice(object))
}

export const saveLoadForm = object => dispatch => {
  dispatch(fetchsaveLoadForm(object))
}

export const editRouteForm = object => dispatch => {
  dispatch(fetcheditRouteForm(object))
}

export const editDataCorrection = object => dispatch => {
  dispatch(fetcheditDataCorrection(object))
}

export const editBTFSTP = object => dispatch => {
  dispatch(fetcheditBTFSTP(object))
}

export const editAutoCharge = object => dispatch => {
  dispatch(fetcheditAutoCharge(object))
}

export const editSubParts = object => dispatch => {
  dispatch(fetcheditSubParts(object))
}

export const editDataParser = object => dispatch => {
  dispatch(fetcheditDataParser(object))
}

export const editEmailParser = object => dispatch => {
  dispatch(fetcheditEmailParser(object))
}

export const editInfoParser = object => dispatch => {
  dispatch(fetcheditInfoParser(object))
}

export const editCompanyProduct = object => dispatch => {
  dispatch(fetcheditCompanyProduct(object))
}

export const editBuyerSeller = object => dispatch => {
  dispatch(fetcheditBuyerSeller(object))
}

export const editUserAccess = object => dispatch => {
  dispatch(fetcheditUserAccess(object))
}

export const editCompanyFleet = object => dispatch => {
  dispatch(fetcheditCompanyFleet(object))
}

const setFleetData = fleets => ({
  payload: fleets,
  type: types.COMPANY_SET_FLEETS_DATA,
})

const setCompanyLoading = loading => ({
  payload: loading,
  type: types.COMPANY_SET_LOADING,
})

export const setCompanyEditingReferenceName = payload => ({
  payload,
  type: types.COMPANY_SET_EDITING_REFERENCE_NAME,
})

export const fetchFleetCompany = () => async dispatch => {
  dispatch(setCompanyLoading(true))
  try {
    const { companies } = await apiClient.companies.get({
      filters: {
        fleet: 'Yes',
      },
    })
    dispatch(setFleetData(companies))
  } catch {
    toast.error('There was an error while fetching fleet data.')
  }
  dispatch(setCompanyLoading(false))
}

export const companyUpdateSuccess = payload => ({
  type: types.COMPANY_SET_UPDATE_SUCCESS,
  payload,
})

export const companySetDataParser = createAction(types.COMPANY_SET_DATA_PARSER)
