// import { useWindowSize } from 'react-use'
// import { useIsMobile } from '~/hooks/useIsMobile'

import { Line } from 'react-chartjs-2'

import type { ILineChartProps } from './type'

import './styles.scss'
import { useMemo } from 'react'
import { ChartOptions } from 'chart.js'
import _ from 'lodash'
import Skeleton from 'react-loading-skeleton'

function LineChart(props: React.PropsWithChildren<ILineChartProps>) {
  const {
    className,
    labels,
    datasets,
    isStacked,
    height,
    options: optionsProp,
    isLoading,
  } = props

  // const windowSize = useWindowSize()
  // const isMobile = useIsMobile()

  // const halfScreen = windowSize.height / 2

  const mapDatasets = useMemo(() => {
    if (isStacked) {
      return datasets.map(data => {
        const backgroundColor = data.backgroundColor || data.borderColor
        return {
          ...data,
          backgroundColor,
          fill: true,
        }
      })
    }

    return datasets.map(data => ({
      ...data,
      fill: false,
    }))
  }, [datasets, isStacked])

  const options = useMemo<ChartOptions>(() => {
    const defaultOptions: ChartOptions = {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          position: 'top',
          display: false,
        },
      },
      scales: {
        y: {
          stacked: false,
          ticks: {
            callback: value => Math.round(value as number),
            stepSize: 1,
          },
        },
      },
      // interaction: {
      //   mode: 'nearest',
      //   axis: 'x',
      //   intersect: false,
      // },
    }
    if (isStacked) {
      defaultOptions.scales = {
        y: {
          stacked: true,
          ticks: {
            callback: value => Math.round(value as number),
            stepSize: 1,
          },
        },
      }
    }

    return defaultOptions
  }, [isStacked])

  return (
    <div className={className}>
      {isLoading ? (
        <Skeleton width='100%' height={100} />
      ) : (
        <Line
          // style={{
          //   minHeight:
          //     height || isMobile ? windowSize.height / 2 + 100 : halfScreen + 60,
          //   maxHeight:
          //     height || isMobile ? windowSize.height / 2 + 100 : halfScreen + 60,
          // }}
          data={{
            labels,
            datasets: mapDatasets,
          }}
          options={_.merge(options, optionsProp) as any}
          height={height}
          width={'100%'}
        />
      )}
    </div>
  )
}

export default LineChart
