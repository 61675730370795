import { useQuery, UseQueryOptions } from 'react-query'
import { useSelector } from 'react-redux'
import useModifyPricing from './useModifyPricing'

import { apiClient } from '~/api/ApiClient'
import { selectSessionUser } from '~/redux/selectors'

import type { IUser } from '~/types/models/IUser'
import type { IGetPricingsParams, IPricing } from '~/types/models/IPricing'
import { useMemo } from 'react'
import formatPricingDate from '~/utils/formatPricingDate'
import { formatCurrencyToDollar } from '~/utils/formatCurrency'
import _ from 'lodash'
import { DEFAULT_QUERY_OPTIONS } from '../constants'
import { buildGetUrl } from '~/utils/buildUrl'

const useQueryPricings = (
  params: IGetPricingsParams = {},
  options?: Partial<UseQueryOptions<IPricing[]>>,
) => {
  const sessionUser: IUser | null = useSelector(selectSessionUser)

  const { data, isLoading, refetch } = useQuery({
    queryKey: ['pricings', sessionUser?.id, buildGetUrl('/pricings', params)],
    async queryFn() {
      const response = await apiClient.pricings.get(params)
      return response.pricings
    },
    enabled: Boolean(sessionUser?.id),
    ...DEFAULT_QUERY_OPTIONS,
    ...options,
  })

  const pricingsData = useMemo(() => data || [], [data])

  const findPricingDataById = (id: number | undefined | null) =>
    pricingsData.find(p => p.id === id)

  const pricingOptions = useMemo(
    () =>
      pricingsData.map(({ id, pricingType, amount, dateRange }) => {
        const pricingDate = formatPricingDate(dateRange)
        const amountFormatted = formatCurrencyToDollar.format(Number(amount))
        const pricingTypeFormatted = _.startCase(pricingType)
        const label = [amountFormatted, pricingTypeFormatted, pricingDate]
          .filter(text => text)
          .join(' - ')

        return {
          label,
          value: id,
        }
      }),
    [pricingsData],
  )

  const { addPricing, updatePricing, removePricing } = useModifyPricing(params)

  return {
    pricingsData,
    isLoadingPricings: isLoading,
    pricingOptions,
    findPricingDataById,
    refetchPricingsData: refetch,
    addPricing,
    updatePricing,
    removePricing,
  }
}

export default useQueryPricings
