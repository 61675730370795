import { useQuery, UseQueryOptions } from 'react-query'
import { useSelector } from 'react-redux'

import { apiClient } from '~/api/ApiClient'
import { selectSessionUser } from '~/redux/selectors'

import type { IUser } from '~/types/models/IUser'
import type { IGetAllowedHierarchiesNewResponse } from '~/types/models/IAllowedHierarchy'
import { useCallback, useMemo } from 'react'
import { ICommonOption } from '~/types/models/ICommonModel'
import _ from 'lodash'
import { DEFAULT_QUERY_OPTIONS } from '../constants'
import { useQueryTerminals } from '../useQueryTerminals'
import { useQueryCompanies } from '../useQueryCompanies'

const useQueryAllowedHierarchiesNew = (
  options?: Partial<UseQueryOptions<IGetAllowedHierarchiesNewResponse>>,
) => {
  const sessionUser: IUser | null = useSelector(selectSessionUser)

  const { data, isLoading } = useQuery({
    queryKey: ['allowedHierarchiesNew', sessionUser?.id],
    queryFn() {
      return apiClient.allowedHierarchies.new()
    },
    enabled: Boolean(sessionUser?.id),
    ...DEFAULT_QUERY_OPTIONS,
    ...options,
  })

  const { sellerTerminalOptions, buyerTerminalOptions } = useQueryTerminals()
  const { sellerCompanyOptions, buyerCompanyOptions } = useQueryCompanies({})

  const getSellerSideIdOptions = useCallback(
    (sellerSideType: string) => {
      switch (sellerSideType) {
        case 'Seller':
          return sellerCompanyOptions
        case 'SellerTerminal':
          return sellerTerminalOptions
        default:
          return []
      }
    },
    [sellerCompanyOptions, sellerTerminalOptions],
  )

  const getBuyerSideIdOptions = useCallback(
    (sellerSideType: string) => {
      switch (sellerSideType) {
        case 'Buyer':
          return buyerCompanyOptions
        case 'BuyerTerminal':
          return buyerTerminalOptions
        default:
          return []
      }
    },
    [buyerCompanyOptions, buyerTerminalOptions],
  )

  const ahSellerSideOptions = useMemo(() => {
    if (data) {
      const options: ICommonOption[] = data.sellerSideTypes.map(type => ({
        value: type,
        label: _.startCase(type),
      }))
      return [{ value: null, label: 'Not set' }, ...options]
    }

    return []
  }, [data])

  const ahBuyerSideOptions = useMemo(() => {
    if (data) {
      const options: ICommonOption[] = data.buyerSideTypes.map(type => ({
        value: type,
        label: _.startCase(type),
      }))
      return [{ value: null, label: 'Not set' }, ...options]
    }

    return []
  }, [data])

  return {
    allowedHierarchiesNewData: data,
    ahSellerSideOptions,
    ahBuyerSideOptions,
    isLoadingAhNewData: isLoading,
    getSellerSideIdOptions,
    getBuyerSideIdOptions,
  }
}

export default useQueryAllowedHierarchiesNew
