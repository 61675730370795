import { useCallback, useMemo } from 'react'
import { useQuery, useQueryClient, UseQueryOptions } from 'react-query'
import { useSelector } from 'react-redux'

import { apiClient } from '~/api/ApiClient'
import { selectSessionUser } from '~/redux/selectors'
import { buildGetUrl } from '~/utils/buildUrl'
import { getTitleRange } from '~/utils'

import type { IUser } from '~/types/models/IUser'
import type { IGetInvoicesParams, IInvoice } from '~/types/models/IInvoice'
import { DEFAULT_QUERY_OPTIONS } from '../constants'
import { produce } from 'immer'

const useQueryInvoices = (
  params: Partial<IGetInvoicesParams> & { id?: number },
  options?: Partial<UseQueryOptions<IInvoice[]>>,
) => {
  const queryClient = useQueryClient()

  const sessionUser: IUser | null = useSelector(selectSessionUser)

  const queryKey = useMemo(
    () => [
      'invoices',
      sessionUser?.id,
      buildGetUrl(apiClient.invoices.endpoint, params),
    ],
    [params, sessionUser?.id],
  )

  const { data, isLoading, refetch, isFetched } = useQuery({
    queryKey,
    async queryFn() {
      if (params.id) {
        const response = await apiClient.invoices.getById(params.id)
        return [response]
      }
      const response = await apiClient.invoices.get(params)
      return response.invoices
    },
    enabled: Boolean(sessionUser?.id),
    ...DEFAULT_QUERY_OPTIONS,
    ...options,
  })

  const invoicesData = useMemo(() => data || [], [data])

  const invoiceOptions = useMemo(
    () =>
      invoicesData.map(invoice => {
        let label = invoice.num ? `#${invoice.num} ` : ''
        label += getTitleRange(invoice)

        return {
          value: invoice.id,
          label: label,
          total: invoice.total,
          tax: invoice.tax,
          sub: invoice.sub,
        }
      }),
    [invoicesData],
  )

  const updateInvoice = useCallback(
    (id: number, data: Partial<IInvoice>) => {
      queryClient.setQueryData<IInvoice[] | undefined>(queryKey, oldData =>
        produce(oldData, draft => {
          if (draft) {
            const index = draft.findIndex(item => item.id === id)
            if (draft[index]) {
              draft[index] = {
                ...draft[index],
                ...data,
              }
            }
          }
        }),
      )
    },
    [queryClient, queryKey],
  )

  return {
    invoicesData,
    invoiceOptions,
    isLoadingInvoicesData: isLoading,
    refetchQueryInvoices: refetch,
    isInvoicesFetched: isFetched,
    updateInvoice,
  }
}

export default useQueryInvoices
