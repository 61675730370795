import { createSlice } from '@reduxjs/toolkit'

export const invoicesSlice = createSlice({
  name: 'invoices',
  initialState: {},
  reducers: {
    index: (state, action) => {
      const newState = {}
      action.payload.invoices.forEach(invoice => {
        newState[`key_${invoice.id}`] = invoice
      })

      return newState
    },

    updateInvoices: (state, action) => {
      action.payload.forEach(invoice => {
        if (invoice.deletedAt) {
          delete state[`key_${invoice.id}`]
        } else {
          state[`key_${invoice.id}`] = invoice
        }
      })

      return state
    },

    updateInvoice: (state, action) => {
      state[`key_${action.payload.id}`] = {
        ...state[`key_${action.payload.id}`],
        ...action.payload,
      }
    },

    deleteInvoice: (state, { payload }) => {
      delete state[`key_${payload.id}`]
    },
  },
})

export default invoicesSlice
