import { useMemo } from 'react'
import { useQuery, UseQueryOptions } from 'react-query'
import { useSelector } from 'react-redux'

import { apiClient } from '~/api/ApiClient'
import { selectSessionUser } from '~/redux/selectors'

import type { IUser } from '~/types/models/IUser'

import { DEFAULT_QUERY_OPTIONS } from '../constants'
import { IGetLoadExtrasResponse } from '~/types/models/ILoadExtra'
import useModifyLoadExtras from './useModifyLoadExtras'

const useQueryLoadExtras = (
  params = {},
  options: UseQueryOptions<IGetLoadExtrasResponse> = {},
) => {
  const sessionUser: IUser | null = useSelector(selectSessionUser)

  const { data, isLoading } = useQuery({
    queryKey: ['loadExtras', params, sessionUser?.id],
    queryFn: () => apiClient.loadExtras.get(params),
    ...DEFAULT_QUERY_OPTIONS,
    ...options,
  })

  const loadExtrasData = useMemo(
    () => data?.loadExtras || [],
    [data?.loadExtras],
  )

  const loadExtrasObj = useMemo(
    () =>
      Object.fromEntries(
        loadExtrasData.map(loadExtra => [loadExtra.id, loadExtra]),
      ),
    [loadExtrasData],
  )

  const { createLoadExtra, updateLoadExtra, deleteLoadExtra } =
    useModifyLoadExtras(params)

  return {
    loadExtrasData,
    loadExtrasObj,
    isLoadingLoadExtras: isLoading,

    createLoadExtra,
    updateLoadExtra,
    deleteLoadExtra,
  }
}

export default useQueryLoadExtras
