import { useCallback, useMemo } from 'react'
import { useQueryBuyerSellerProducts } from './useQueryData'
import { apiClient } from '~/api/ApiClient'
import { toast } from 'react-toastify'
import { ISellerProduct } from '~/types/models/ISellerProduct'
import { EOrderType } from '~/types/enums/ESellerProduct'

interface Props {
  buyerId?: number
  sellerId: number
  orderTypes?: EOrderType[]
}

export const useSelectOptionsCombinedBuyerAndSellerProducts = ({
  buyerId,
  sellerId,
  orderTypes,
}: Props) => {
  const {
    isLoadingBuyerSellerProducts,
    productOptions: options,
    buyerSellerProductOptions,
    isLoadingSellerProducts,
    addBuyerSellerProduct,
    buyerSellerProducts,
    sellerProducts,
    addSellerProduct,
    updateSellerProduct,
    findBuyerSellerProductById,
    getSellerProduct,
  } = useQueryBuyerSellerProducts(
    {
      sellerId: sellerId,
      buyerId: buyerId,
      filters: {
        joinsSellerProduct: true,
        ...(orderTypes && orderTypes),
      },
    },
    { enabled: Boolean(buyerId && sellerId) },
  )

  const selectedValue = useCallback(
    (value: number) => {
      if (options) {
        return (
          options[0]?.options.find(option => option.value === value) ||
          options[1]?.options.find(option => option.value === value)
        )
      }
      return null
    },
    [options],
  )

  const createBSPfromSP = useCallback(
    async (sellerProductId: number) => {
      try {
        const { errors, ...buyerSellerProduct } =
          await apiClient.buyerSellerProducts.create(
            {
              buyerId,
              sellerId,
              buyerSellerProduct: {
                sellerProductId,
              },
            },
            {
              query: { sellerProduct: true },
            },
          )

        if (errors.length > 0) {
          toast.error(errors.join('\n'))
        } else {
          const {
            id: sellerProductId,
            name: sellerProductName,
            code: sellerProductCode,
          } = buyerSellerProduct.sellerProduct as ISellerProduct

          addBuyerSellerProduct({
            ...buyerSellerProduct,
            sellerProductCode,
            sellerProductName,
            sellerProductId,
          })

          toast.success('Product added')
          return buyerSellerProduct
        }
      } catch (e) {
        toast.error((e as Error).message)
      }

      return null
    },
    [addBuyerSellerProduct, buyerId, sellerId],
  )

  const isLoadingOptions = useMemo(
    () => isLoadingBuyerSellerProducts || isLoadingSellerProducts,
    [isLoadingBuyerSellerProducts, isLoadingSellerProducts],
  )

  return {
    productOptions: options,
    buyerSellerProductOptions,
    isLoadingOptions,
    buyerSellerProducts,
    sellerProducts,
    addSellerProduct,
    updateSellerProduct,
    selectedValue,
    createBSPfromSP,
    findBuyerSellerProductById,
    getSellerProduct,
  }
}
