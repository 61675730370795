import { sessionService } from 'redux-react-session'
import { pushRegisterService } from '~/services/PushRegisterService'
import PusherService from '~/services/PusherService'
import { isMobileApp, isMobileWebApp } from '~/utils/getCurrentPlatform'
import { toast } from 'react-toastify'
import { produce } from 'immer'
import sessionsUiSlice from '~/redux/reducers/ui/sessions'
import { toastMessages, toastOptions } from '~/constants/toast-status-text'
import { routesPaths } from '~/constants/routesPaths'
import { apiClient } from '~/api/ApiClient'

export const login = (user, callback) => async dispatch => {
  dispatch(sessionsUiSlice.actions.updateLoginPageLoading(true))
  try {
    const response = await apiClient.users.signIn({
      uid: user.uid.toLowerCase(),
      password: user.password,
    })
    const userAccessId = response?.userAccess?.id

    await sessionService.saveUser(response)

    callback && callback()
    await pushRegisterService.registerPushNotification({
      ...user,
      email: user.uid.toLowerCase(),
    })

    if (!isMobileWebApp()) {
      const pusherService = new PusherService()
      pusherService
        .registerPushNotificationToSpecificUser(userAccessId)
        .catch(async error => {
          console.log('error', error.message)
          if (error.message === 'Changing the `userId` is not allowed.') {
            console.log('error login', error.message)
            await pusherService.stopBeamsService()

            pusherService.registerPushNotificationToSpecificUser(userAccessId)
          } else {
            toast.error(
              'An unknown error has caused push notifications to fail. Please log out and sign in again.',
            )
          }
        })
    }

    if (isMobileApp()) {
      dispatch(sessionsUiSlice.actions.updateIsSigningIn(true))
    }
  } catch (error) {
    console.log('error', error)
    if (isMobileApp()) {
      alert(toastMessages.apiService.unauthorized)
    } else {
      toast.error(toastMessages.apiService.unauthorized, toastOptions)
    }
  } finally {
    dispatch(sessionsUiSlice.actions.updateLoginPageLoading(false))
  }
}

export const logout =
  (payload = {}) =>
  async dispatch => {
    dispatch(sessionsUiSlice.actions.updateLoginPageLoading(true))
    try {
      const { message, router } = payload
      if (!isMobileWebApp()) {
        const pusherService = new PusherService()
        pusherService.stopBeamsService()
      }

      await apiClient.users.signOut()
      localStorage.clear()
      if (message) {
        sessionStorage.setItem('toastMessage', message)
      }
      await Promise.all([
        sessionService.deleteSession(),
        sessionService.deleteUser(),
      ])
      router?.push(routesPaths.login)
    } catch (err) {
      console.log('err logout', err)
    } finally {
      dispatch(sessionsUiSlice.actions.updateLoginPageLoading(false))
    }
  }

export const logoutSignUp = async () => {
  try {
    await apiClient.users.signOut()
    sessionService.deleteSession()
    sessionService.deleteUser()
    window.location = routesPaths.login
  } catch (error) {
    // throw err
    console.log('error', error)
  }
}

export const updateUserInfo = user => () => {
  sessionService.saveUser(user)
}

export const updateUserAccessInfo = async () => {
  const response = await apiClient.users.getAccountInfo()
  sessionService.saveUser(response)
}

export const updateUserAccess = (userAccess, company) => async () => {
  try {
    sessionService
      .loadUser()
      .then(user => {
        const userWithAccess = {
          ...user,
          userAccess: userAccess,
          company: company,
        }
        sessionService.saveUser(userWithAccess)
      })
      .catch(err => err)
  } catch (err) {
    console.log('err', err)
    toast.error(toastMessages.serverError)
  }
}

/**
 *
 * @param {boolean} checkForSplit
 */
export const updateCompanyCheckForSplit = checkForSplit => async () => {
  try {
    const currentUser = await sessionService.loadUser()
    const newCurrentUser = produce(currentUser, draft => {
      draft.company.checkForSplit = checkForSplit
    })
    sessionService.saveUser(newCurrentUser)
  } catch (error) {
    console.log('error', error)
    toast.error("An error has occured while updating your company's details.")
  }
}
