import { DEFAULT_ICON_SIZE } from './constants'

import type { ISvgIconProps } from './type'

const InternetIcon = (props: ISvgIconProps) => {
  const { size = DEFAULT_ICON_SIZE, color = '#000', ...svgProps } = props

  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      fill='none'
      viewBox='0 0 24 24'
      {...svgProps}
    >
      <path
        stroke={color}
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth={2}
        d='M4 15h16M4 9h16'
      />
      <circle
        cx={12}
        cy={12}
        r={9}
        stroke={color}
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth={2}
      />
      <path
        fill={color}
        d='m12 20.818-.714.7a1 1 0 0 0 1.429 0l-.715-.7Zm0-17.636.715-.7a1 1 0 0 0-1.429 0l.714.7ZM14.6 12a11.56 11.56 0 0 1-3.314 8.118l1.429 1.4A13.559 13.559 0 0 0 16.6 12h-2Zm-3.314-8.118A11.56 11.56 0 0 1 14.6 12h2a13.56 13.56 0 0 0-3.885-9.518l-1.429 1.4ZM9.4 12a11.56 11.56 0 0 1 3.315-8.118l-1.429-1.4A13.56 13.56 0 0 0 7.4 12h2Zm3.315 8.118A11.559 11.559 0 0 1 9.4 12h-2c0 3.705 1.483 7.066 3.886 9.518l1.429-1.4Z'
      />
    </svg>
  )
}

export default InternetIcon
