import { useMemo } from 'react'
import { useQuery, UseQueryOptions } from 'react-query'
import { useSelector } from 'react-redux'

import { apiClient } from '~/api/ApiClient'
import { selectSessionUser } from '~/redux/selectors'
import { buildGetUrl } from '~/utils/buildUrl'

import type { IUser } from '~/types/models/IUser'
import type {
  IAllowedHierarchy,
  IGetAllowedHierarchiesParams,
} from '~/types/models/IAllowedHierarchy'
import useModifyAllowedHierarchy from './useModifyAllowedHierarchy'
import _ from 'lodash'
import { DEFAULT_QUERY_OPTIONS } from '../constants'

const useQueryAllowedHierarchies = (
  params: Partial<IGetAllowedHierarchiesParams> = {},
  options?: Partial<UseQueryOptions<IAllowedHierarchy[]>>,
) => {
  const sessionUser: IUser | null = useSelector(selectSessionUser)

  const { data, isLoading } = useQuery({
    queryKey: [
      'allowedHierarchies',
      sessionUser?.id,
      buildGetUrl(apiClient.allowedHierarchies.endpoint, params),
    ],
    async queryFn() {
      const response = await apiClient.allowedHierarchies.get(params)
      return response.allowedHierarchies
    },
    enabled: Boolean(sessionUser?.id),
    ...DEFAULT_QUERY_OPTIONS,
    ...options,
  })

  const {
    addAllowedHierarchy,
    updateAllowedHierarchy,
    removeAllowedHierarchy,
    renewAllAllowedHierarchies,
  } = useModifyAllowedHierarchy(params)

  const allowedHierarchiesData = useMemo(() => data || [], [data])

  const allowedHierarchyOptions = useMemo(
    () =>
      allowedHierarchiesData.map(item => {
        const { sellerSideType, buyerSideType, id } = item
        return {
          value: id,
          label: `${_.startCase(sellerSideType)} - ${_.startCase(
            buyerSideType,
          )}`,
          sellerSideType,
          buyerSideType,
          item,
        }
      }),
    [allowedHierarchiesData],
  )

  return {
    allowedHierarchiesData,
    isLoadingAllowedHierarchies: isLoading,
    allowedHierarchyOptions,
    addAllowedHierarchy,
    updateAllowedHierarchy,
    removeAllowedHierarchy,
    renewAllAllowedHierarchies,
  }
}

export default useQueryAllowedHierarchies
