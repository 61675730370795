import React, { ReactElement, useEffect } from 'react'
import { useReusableTable } from '../hooks/useReusableTable'
import { RTPaper } from './table/RTPaper'
import type {
  IReusableTableRowData,
  ReusableTableInstance,
  RTTableOptions,
} from '../types'

export type IReusableTableProps<TData extends IReusableTableRowData> =
  RTTableOptions<TData>

export const OriginalReusableTable = <TData extends IReusableTableRowData>(
  props: IReusableTableProps<TData>,
  ref: any,
) => {
  const table = useReusableTable(props)

  useEffect(() => {
    if (ref) {
      ref.current = table
    }
  }, [ref, table])

  return <RTPaper table={table} />
}

export const ReusableTable = React.forwardRef(OriginalReusableTable) as <
  TData extends IReusableTableRowData,
>(
  p: IReusableTableProps<TData> & {
    ref?: React.MutableRefObject<ReusableTableInstance<TData> | undefined>
  },
) => ReactElement
