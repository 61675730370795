import { useCallback, useMemo, useState } from 'react'
import { useUpdateEffect } from 'react-use'

import _ from 'lodash'

import { ITruckInfoBoxProps } from './type'
import { useQueryTruckFleets, useQueryUsers } from '~/hooks/useQueryData'
import moment from 'moment'
import { ToolTipOverlay } from '~/components/shared/ToolTipOverlay'

const useTruckInfoBox = (props: ITruckInfoBoxProps) => {
  const { truck, location, onClick } = props

  const [isOpenInfoBox, setIsOpenInfoBox] = useState(
    props.isOpenInfoBox || false,
  )
  const [isShowDriverInfo, setIsShowDriverInfo] = useState(false)
  const [isOpenGpsJson, setIsOpenGpsJson] = useState(false)

  const { findTruckFleetByTruckId } = useQueryTruckFleets()
  const { driverFleetUsers } = useQueryUsers()

  const truckLocation = useMemo(() => {
    if (location) {
      return new google.maps.LatLng(Number(location.lat), Number(location.lng))
    }

    return null
  }, [location])

  const truckDetails = useMemo(() => {
    const details = [truck?.year, truck?.make, truck?.model, truck?.bed]

    return details.filter(value => value).join(' ')
  }, [truck?.bed, truck?.make, truck?.model, truck?.year])

  const driverFleetOfTruck = useMemo(() => {
    const truckFleet = findTruckFleetByTruckId(truck?.id)
    const user = driverFleetUsers.find(
      u =>
        u.driverFleet?.currentTruckFleetId === truckFleet?.id ||
        u.driverFleet?.defaultTruckFleetId === truckFleet?.id,
    )

    return user?.driverFleet
  }, [driverFleetUsers, findTruckFleetByTruckId, truck?.id])

  const fields = useMemo(
    () => [
      {
        label: 'Name',
        name: 'name',
      },
      {
        label: 'Address',
        name: 'location.fullAddress',
      },
      {
        label: 'Axle weight',
        name: 'axleWeight',
        render: () => Number(truck?.axleWeight),
      },
      {
        label: 'Bed type',
        name: 'bedType',
      },
      {
        label: 'Empty weight',
        name: 'emptyWeight',
      },
      {
        label: 'GPS interval',
        name: 'gpsInterval',
      },
      {
        label: 'Height',
        name: 'height',
      },
      {
        label: 'License plate',
        name: 'licensePlate',
      },
      {
        label: 'Max qty',
        name: 'maxQty',
        render: () => Number(truck?.axleWeight),
      },
      {
        label: 'Total weight',
        name: 'totalWeight',
        render: () => Number(truck?.axleWeight),
      },
      {
        label: 'Vehicle uid',
        name: 'vehicleUid',
      },
      {
        label: 'Vin',
        name: 'vin',
      },
      {
        label: 'Weight allowance',
        name: 'weightAllowance',
      },
      {
        label: 'Last updated at',
        name: 'updatedAt',
        render({ value }: any) {
          return moment(value).fromNow()
        },
        tooltipContent: moment(truck.updatedAt).format('ddd, MMM-DD H:m:s'),
      },
      {
        label: 'Speed',
        name: 'speed',
      },
    ],
    [truck?.axleWeight, truck.updatedAt],
  )

  const renderTruckInfo = useMemo(
    () =>
      fields
        .filter(({ name }) => _.get(truck, name))
        .map(({ label, name, render, tooltipContent }) => (
          <div key={name} style={{ marginTop: 4 }}>
            <span style={{ fontWeight: 600 }}>{label}: </span>
            <ToolTipOverlay content={tooltipContent} placement='top'>
              <span>
                {render
                  ? render({ value: _.get(truck, name) })
                  : _.get(truck, name)}
              </span>
            </ToolTipOverlay>
          </div>
        )),
    [fields, truck],
  )

  const onOpenTruckInfo = useCallback(() => {
    setIsOpenInfoBox(prev => !prev)
  }, [])

  const onCloseTruckInfo = useCallback(() => {
    setIsOpenInfoBox(false)
  }, [])

  const onToggleDriverInfo = useCallback(() => {
    setIsShowDriverInfo(prev => !prev)
  }, [])

  const onToggleGpsJson = () => {
    setIsOpenGpsJson(prev => !prev)
  }

  useUpdateEffect(() => {
    if (isOpenInfoBox) {
      onClick &&
        onClick({
          driverFleet: driverFleetOfTruck,
          truck,
          isOpenInfoBox: true,
        })
    } else {
      onClick &&
        onClick({
          driverFleet: null,
          truck: null,
          isOpenInfoBox: false,
        })
    }
  }, [isOpenInfoBox])

  return {
    truck,
    truckLocation,
    isOpenInfoBox,
    renderTruckInfo,
    truckDetails,
    driverFleetOfTruck,
    isShowDriverInfo,
    location,
    isOpenGpsJson,
    onOpenTruckInfo,
    onCloseTruckInfo,
    onToggleDriverInfo,
    onToggleGpsJson,
  }
}

export default useTruckInfoBox
