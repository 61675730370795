import Pusher from 'pusher-js'
import { createContext } from 'react'

const PusherContext = createContext<{
  pusherInstance: Pusher
}>({
  pusherInstance: new Pusher(process.env.REACT_APP_PUSHER_API_KEY as string, {
    cluster: process.env.REACT_APP_PUSHER_CLUSTER,
  }),
})

export default PusherContext
