type ILatLng = string | number | null | undefined

const getGoogleLocation = (lat: ILatLng, lng: ILatLng) => {
  if (lat && lng) {
    return new google.maps.LatLng(Number(lat), Number(lng))
  }

  return undefined
}

export default getGoogleLocation
