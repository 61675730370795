import { useMemo } from 'react'

import { CommonDialogV2 } from '~/components/shared'
import HierarchyRowForm from './HierarchyRowForm'

import type { IDialogHierarchyRowFormProps } from './type'

function DialogHierarchyRowForm(props: IDialogHierarchyRowFormProps) {
  const {
    formData,
    afterCreate,
    afterUpdate,
    onClose,
    hierarchyData,
    billLineData,
    invoiceData,
    ...modalProps
  } = props

  const isUpdating = useMemo(() => Boolean(formData?.id), [formData?.id])

  return (
    <CommonDialogV2
      {...modalProps}
      okText={isUpdating ? 'Update' : 'Create'}
      title='Pricing'
      isHiddenOkButton
      onClose={onClose}
    >
      <HierarchyRowForm
        billLineData={billLineData}
        invoiceData={invoiceData}
        formData={formData}
        hierarchyData={hierarchyData}
        afterCreate={formData => {
          afterCreate && afterCreate(formData)
          onClose && onClose()
        }}
        afterUpdate={formData => {
          afterUpdate && afterUpdate(formData)
          onClose && onClose()
        }}
      />
    </CommonDialogV2>
  )
}

export default DialogHierarchyRowForm
