import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Chart } from 'react-google-charts'
import { formatBarGraphCard } from './helpers'
import '~/styles/dashboard.scss'
import { useQueryTerminals } from '~/hooks/useQueryData'

const BarGraphCard = ({ options }) => {
  const dashboardLoadData = useSelector(state => state.dashboard.load)

  const { buyerTerminalsData } = useQueryTerminals()

  const [barGraphData, setBarGraphData] = useState([])

  useEffect(() => {
    if (dashboardLoadData && buyerTerminalsData) {
      const formattedData = formatBarGraphCard(
        dashboardLoadData[options.dashboardKey],
        buyerTerminalsData,
      )

      setBarGraphData(formattedData)
    }
  }, [dashboardLoadData, options, buyerTerminalsData])

  return (
    <div className='GraphCard__container'>
      <div className='graph-card'>
        <div style={{ height: 8 }}></div>
        <Chart
          chartType='ColumnChart'
          loader={<div>Loading Chart</div>}
          data={barGraphData}
          options={options}
          chartEvents={[
            {
              eventName: 'ready',
              callback: () => {},
            },
            {
              eventName: 'select',
              callback: () => {},
            },
          ]}
        />
      </div>
    </div>
  )
}

export default BarGraphCard
