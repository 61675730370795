import { useEffect } from 'react'

import { pdfjs } from 'react-pdf'
import { Viewer, Worker } from '@react-pdf-viewer/core'
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout'

import clsx from 'clsx'

import './ViewPDF.scss'
import '@react-pdf-viewer/core/lib/styles/index.css'
import '@react-pdf-viewer/default-layout/lib/styles/index.css'
import 'react-pdf/dist/esm/Page/AnnotationLayer.css'
import '@react-pdf-viewer/page-navigation/lib/styles/index.css'

function ViewPDF(props) {
  const {
    link,
    className,
    hideHeader,
    fileName = 'concord_document',
    ...restProps
  } = props

  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    toolbarPlugin: {
      getFilePlugin: {
        fileNameGenerator() {
          return `${fileName}`
        },
      },
    },
  })

  useEffect(() => {
    pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`
  }, [])

  return (
    <div className={clsx('ViewPDF__root', className)} {...restProps}>
      <Worker
        workerUrl={`https://unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.js`}
      >
        <Viewer fileUrl={link} plugins={[defaultLayoutPluginInstance]} />
      </Worker>
    </div>
  )
}

export default ViewPDF
