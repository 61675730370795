import useLoadStatusModal from './useLoadStatusModal'

import { Alert, ListGroup } from 'react-bootstrap'
import { CommonDialogV2 } from '~/components/shared'

import type { ILoadStatusModalProps } from './type'

import './styles.scss'

function LoadStatusModal(props: ILoadStatusModalProps) {
  const { isOpen, onClose, renderStatuses, isUpdating, backendError } =
    useLoadStatusModal(props)

  return (
    <CommonDialogV2
      isOpen={isOpen}
      onClose={onClose}
      title='Load Status'
      isHiddenOkButton
      isLoading={isUpdating}
    >
      {backendError && (
        <Alert variant='danger' style={{ fontSize: 13 }}>
          {backendError}
        </Alert>
      )}
      <ListGroup>{renderStatuses}</ListGroup>
    </CommonDialogV2>
  )
}
export default LoadStatusModal
